import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { notify_warning, notify_valido, notify_error } from '../../../components/utils';
import { AButton } from '../../../components/Button';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import './DESC.css'
import $ from 'jquery'
import Swal from 'sweetalert2';
import AsyncSelect from 'react-select/async';
import Cookies from 'universal-cookie';
import { ModalDetallesB, ModalDetallesBoni, ModalDetallesBoniPar, ModalBEPorE } from '../../../components/Modals/ModalsDetVen';

const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id');
const moment = require('moment')
// const styles = {
//   control: (provided, state) => ({
//     ...provided,
//     background: `#fff`,
//     borderColor: `#D2D5DC`,
//     minHeight: `29px`,
//     height: `29px`,
//     boxShadow: state.isFocused ? null : null,
//     border: '1px solid #D2D5DC',

//   }),

//   valueContainer: (provided, state) => ({
//     ...provided,
//     height: `29px`,
//     padding: `0 6px`,
//     color: `#697a8d`,

//   }),

//   input: (provided, state) => ({
//     ...provided,
//     color: `#697a8d`,
//     fontSize: `0.75rem`,

//   }),
//   indicatorSeparator: state => ({
//     display: `none`,

//   }),
//   indicatorsContainer: (provided, state) => ({
//     ...provided,
//     height: `29px`,

//   }),
// };

const styles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: `#D2D5DC`,
    border: '1px solid #D2D5DC',
    boxShadow: state.isFocused ? null : null,
    backgroundColor: window.templateCustomizer.settings.style === 'light'
      ? '#FEFFF4'
      : '#2B2C40',
    color: window.templateCustomizer.settings.style === 'light'
      ? '#2B2C40'
      : '#FEFFF4',
    minHeight: `29px`,
    height: `29px`,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? (window.templateCustomizer.settings.style === 'light' ? '#FEFFF4' : '#2B2C40')
      : (window.templateCustomizer.settings.style === 'light' ? '#FEFFF4' : '#2B2C40'),
    color: state.isSelected
      ? 'white'
      : (window.templateCustomizer.settings.style === 'light' ? '#2B2C40' : '#FEFFF4'),
    '&:hover': {
      backgroundColor: '#FF3E1D',
      color: 'white',
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
    padding: `0 6px`,
    color: window
      .templateCustomizer.settings.style === 'light' ? '#2B2C40' : '#FEFFF4',
  }),
  input: (provided, state) => ({
    ...provided,
    color: window.templateCustomizer.settings.style === 'light' ? '#2B2C40' : '#FEFFF4',
    fontSize: `0.75rem`,
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: window.templateCustomizer.settings.style === 'light' ? '#2B2C40' : '#FEFFF4',
  }),
};
export const DESInicioDetalle = (prosp) => {
  const { arrCampDatos, listInitData, onDataUpdate, onHandleVPut, onValCopyDat, onValBloqueo } = prosp
  const [arrDatosInsert, setArrDatosInsert] = useState([]);
  const [filaActiva, setFilaActiva] = useState(null);
  const [slcArticulo, setSlcArticulo] = useState([""]);
  const selectRowRef = useRef(null); // puentero

  const funDArticulos = useCallback(async (inputValue) => {
    const url = '/apiArticulos?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }, [])

  const handleSelectChange = (selectedOption) => { setSlcArticulo(selectedOption); };

  const onClickInsert = async () => {
    if (arrCampDatos.txtDesc !== "" && slcArticulo.Code !== undefined) {
      const datosrepetidos = arrDatosInsert.filter(varr => varr.CodArt === slcArticulo.Code)
      if (datosrepetidos.length === 0) {
        const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
        const newId = (lastItem === undefined || lastItem === "") ? 1 : lastItem.idArt + 1;
        const newItem = { idArt: newId, CodArt: slcArticulo.Code, namArt: slcArticulo.Name, uniArt: slcArticulo.Unidad };
        notify_valido("Se registro correctamente!")
        setArrDatosInsert([...arrDatosInsert, newItem]);
        onDataUpdate('DESiniciodetallador', [...arrDatosInsert, newItem])
        setFilaActiva(newId);
        setSlcArticulo([])
        setTimeout(() => {
          selectRowRef.current.focus()
        }, 500);

      } else { notify_warning('Datos selecionados ya exiten.'); }
    } else {
      //  notify_warning('Campos vacios.');
      if (arrCampDatos.txtCod === "" && arrCampDatos.txtDesc === "") {
        notify_warning('Campo  descripción es obligatorio no deben estar vacio..');
      } else {
        //if (arrCampDatos.txtCod.trim() === "") {
        //   notify_warning('Campos codigo es obligatorio no debe estar vacio.');
        // }
        if (arrCampDatos.txtDesc.trim() === "") {
          notify_warning('Campos descripción son obligatorio no debe estar vacio.');
        }
        if (!slcArticulo.Code) {
          notify_warning('Codigo es obligatorio, no debe estar vacio.');
        }
      }
    }
  }

  useEffect(() => {
    const lastItem = listInitData[listInitData?.length - 1];
    setFilaActiva(lastItem?.idArt)
    setArrDatosInsert(listInitData)
  }, [listInitData])
  const onClickDeletefila = (code) => {
    onHandleVPut("1")
    const myList = [...arrDatosInsert];
    console.log("Se elimianr el roeducto");
    const data = arrDatosInsert.findIndex((res) => (res.CodArt === code));
    myList.splice(data, 1);
    setArrDatosInsert(myList);
    console.log(arrDatosInsert);
    onDataUpdate('DESiniciodetallador', myList)
    setSlcArticulo([])

  }
  const TRPlantilllaCD = (prosp) => {
    return (
      <tr key={prosp.datoRow.idArt} >
        <td className='text-center' >
          {/* <AButton tp_tilde="Editar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-primary" ic_class="bx bx-ms bx-edit" vl_tpbtn={1} /> */}

          {onValBloqueo ? <div className="d-none"></div> :
            <AButton tp_tilde="Eliminar" disabled={onValCopyDat ? false : prosp.datoRow.estadoComprometido} bt_funct={() => onClickDeletefila(prosp.datoRow.CodArt)} tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />

          }
        </td>
        <td style={{ position: 'relative', zIndex: '2', maxWidth: '50px' }} >{prosp.datoRow.CodArt}</td>
        <td>{prosp.datoRow.namArt}</td>
        <td>{prosp.datoRow.uniArt}</td>
        <td>
          <AButton tp_tilde="Ver Unidades" tp_posit="right" bt_class="btn btn-sm btn-icon btn-label-warning" bt_funct={() => handleOpenGeneralUni2(prosp.datoRow.CodArt)} ic_class="bx bx-ms bx-cube" vl_tpbtn={1} />
        </td>
      </tr>
    )
  }
  /** inicio:> Modal */
  const [generalModal, setGeneralModal] = useState(false);
  const handleOpenGeneral = () => {
    //if (arrCampDatos.txtCod!=="" && arrCampDatos.txtDBon !=="") { 
    if (arrCampDatos.txtDBon !== "") {
      setGeneralModal(true);
    } else {
      //if (arrCampDatos.txtCod==="" && arrCampDatos.txtDBon==="") {
      if (arrCampDatos.txtDBon === "") {
        notify_warning('Campo descripción es obligatorio, no debe estar vacio.');
      } else {
        //if (arrCampDatos.txtCod.trim()==="") { notify_warning('Campo codigo es obligatorio no debe estar vacio.'); }
        if (arrCampDatos.txtDBon.trim() === "") { notify_warning('Campo descripción son obligatorio no debe estar vacio.'); }
      }
    }
  }
  const handleCloseGeneral = () => {
    setGeneralModal(false);
  }
  const handleRetrunData = (data) => {
    // console.log("data ", data );
    const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
    let newId = (lastItem === undefined || lastItem === "") ? 0 : lastItem.idArt;
    if (listInitData.length > 0) {
      // Obtener los códigos que ya existen en array1
      const existingCodes = listInitData.map(item => item.CodArt);
      // Filtrar array2 para quedarnos sólo con elementos con códigos nuevos
      let lastId = listInitData[listInitData.length - 1].idArt;
      const newElements = data.filter(item => !existingCodes.includes(item.Code)).map(item => {
        lastId++;
        return {
          idArt: lastId,
          CodArt: item.Code,
          namArt: item.Name,
          uniArt: item.Unidad
        }
      });
      const result = [...listInitData, ...newElements];
      //console.log("result", result);
      onDataUpdate('DESiniciodetallador', [...result])
      handleCloseGeneral()
      setTimeout(() => { selectRowRef?.current?.focus() }, 1000);
    } else {
      console.log("no hay data");
      // const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
      // let newId = (lastItem === undefined || lastItem === "") ? 0 : lastItem.idArt;
      data?.map((row) => {
        newId++;
        return arrDatosInsert?.push({
          "idArt": newId,
          "CodArt": row.Code,
          "namArt": row.Name,
          "uniArt": row.Unidad
        })
      })
      onDataUpdate('DESiniciodetallador', [...arrDatosInsert])
      handleCloseGeneral()
      setTimeout(() => { selectRowRef?.current?.focus() }, 1000);
    }
  }
  let columns = [
    { header: 'Code', accessorKey: 'Code' },
    { header: 'Name', accessorKey: 'Name' },
    { header: 'Unidad', accessorKey: 'Unidad' },
    { header: 'Categoria', accessorKey: 'Categoria' },
    { header: 'CodEAN', accessorKey: 'CodEAN' }
  ]
  /** fin:> Modal */

  /** inicio:> Modal Unidades de articulos */
  const [generalModalUni, setGeneralModalUni] = useState(false);
  const [codExtrModalUni, setCodExtrModalUni] = useState('');
  const handleOpenGeneralUni2 = async (Code) => {
    setCodExtrModalUni(Code)
    setGeneralModalUni(true);
  }
  const handleCloseGeneralUni = () => {
    setGeneralModalUni(false);
  }
  let columnsUni = [
    { header: 'Code', accessorKey: 'Code' },
    { header: 'Name', accessorKey: 'Name' },
    { header: 'Unidad', accessorKey: 'UomCode' },
    { header: 'Base', accessorKey: 'BaseQty' }
  ]
  /** fin:> Modal Unidades de articulos */
  return (
    <>
      <div className="data-tables table-responsive text-nowrap " style={{ position: 'relative', zIndex: '1', width: 'auto', height: '400px', overflowY: 'scroll', overflowX: 'scroll' }}>
        <table className="table table-sm" id='tab_DInicio' style={{ fontSize: '10px', zIndex: '2', }} >
          <thead style={{ position: 'sticky', top: 0, zIndex: 10, }}>
            <tr >
              <th>Actions</th>
              <th style={{ width: '350px' }}>Code Articulo  <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger d-none" ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /></th>
              <th style={{ width: '450px' }}>Des. Articulo</th>
              <th>Unidad</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody >
            {

              (arrDatosInsert?.length) ? arrDatosInsert?.map((row) => (
                <React.Fragment key={row.idArt}>
                  <TRPlantilllaCD key={row.idArt} datoRow={row} />
                  {onValBloqueo ? <div className="d-none"></div> :
                    (!filaActiva || filaActiva === row.idArt) && (
                      <tr key={prosp?.datoRow?.idArt}>
                        <td className='td1'></td>
                        <td className='td2' style={{ position: 'relative', zIndex: '3' }} >
                          <AsyncSelect
                            ref={selectRowRef}
                            styles={styles}
                            placeholder="Busque por Cod./Desc."
                            name="form-field-name"
                            className=" from-control form-control-sm"
                            value={slcArticulo}
                            cacheOptions
                            defaultOptions
                            getOptionLabel={e => e.Code + ' - ' + e.Name}
                            getOptionValue={e => e.Code}
                            loadOptions={funDArticulos}
                            onChange={handleSelectChange}
                            maxMenuHeight={150}
                          />

                        </td>
                        <td className='td3'>{slcArticulo.Name}</td>
                        <td className='td4'>{slcArticulo.Unidad}</td>
                        <td className='td5'>
                          <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                          <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info" bt_funct={handleOpenGeneral} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />

                        </td>
                      </tr>
                    )
                  }
                </React.Fragment>
              ))
                : onValBloqueo ? <div className="d-none"></div> :
                  <tr key={prosp?.datoRow?.idArt}>
                    <td className='td1'>
                    </td>
                    <td className='td2' style={{ position: 'relative', zIndex: '3' }} >
                      <AsyncSelect
                        styles={styles}
                        placeholder="Busque por Cod./Desc."
                        name="form-field-name"
                        className=" from-control form-control-sm"
                        value={slcArticulo}
                        cacheOptions
                        defaultOptions
                        getOptionLabel={e => e.Code + ' - ' + e.Name}
                        getOptionValue={e => e.Code}
                        loadOptions={funDArticulos}
                        onChange={handleSelectChange}
                        maxMenuHeight={150}
                      />
                    </td>
                    <td className='td3'>{slcArticulo.Name}</td>
                    <td className='td4'>{slcArticulo.Unidad}</td>
                    <td className='td5'>
                      <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                      <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info" bt_funct={handleOpenGeneral} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />

                    </td>
                  </tr>
            }
          </tbody>
        </table>
      </div>
      {
        generalModal && (
          <ModalDetallesBoniPar
            hanOpenModal={generalModal}
            hanClosModal={handleCloseGeneral}
            onReturnData={handleRetrunData}
            onDataEstras={{ "columns": columns, "url": '/apiParArticulos?vcodEmpresa=' + cook_idEmp }}
          />
        )
      }
      {
        generalModalUni && (
          <ModalDetallesB
            hanOpenModal={generalModalUni}
            hanClosModal={handleCloseGeneralUni}
            onDataEstras={{ "columns": columnsUni, "url": '/apiUnidadArticulos?vcodEmpresa=' + cook_idEmp + '&CodArt=' + codExtrModalUni }}
          />
        )
      }
    </>
  )
}
export const DESClienteDetalle = (prosp) => {
  const { arrCampDatos, listInitData, onDataUpdate, onHandleVPut, onValCopyDat, onValBloqueo } = prosp
  const [arrDatosInsert, setArrDatosInsert] = useState([]);
  const [filaActiva, setFilaActiva] = useState(null);
  const [slcArticulo, setSlcArticulo] = useState([""]);
  const selectRowRef = useRef(null); // puentero

  const funDArticulos = useCallback(async (inputValue) => {
    const url = '/apiClientes?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }, [])
  const handleSelectChange = (selectedOption) => { setSlcArticulo(selectedOption); };
  const onClickInsert = async () => {
    if (arrCampDatos.txtDesc !== "" && slcArticulo.Code !== undefined) {
      const datosrepetidos = arrDatosInsert.filter(varr => varr.CodArt === slcArticulo.Code)
      if (datosrepetidos.length === 0) {
        const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
        // const newId = (lastItem === undefined || lastItem === "") ? 2 : lastItem.idArt + 1;
        const newId = (lastItem === undefined || lastItem === "") ? 1 : lastItem.idArt + 1;

        const newItem = { idArt: newId, CodArt: slcArticulo.Code, namArt: slcArticulo.Name };
        notify_valido("Se agrego correctamente!")
        setArrDatosInsert([...arrDatosInsert, newItem]);
        onDataUpdate('DESclientes', [...arrDatosInsert, newItem])
        setFilaActiva(newId);
        setSlcArticulo([])
        setTimeout(() => {
          selectRowRef.current.focus()
        }, 500);
      } else { notify_warning('Datos selecionados ya exiten.'); }
    } else {
      // notify_warning('Campos vacios.'); 
      if (arrCampDatos.txtCod === "" && arrCampDatos.txtDesc === "") {
        notify_warning('Campo  descripción es obligatorio no deben estar vacio..');
      } else {
        //if (arrCampDatos.txtCod.trim() === "") {
        //   notify_warning('Campos codigo es obligatorio no debe estar vacio.');
        // }
        if (arrCampDatos.txtDesc.trim() === "") {
          notify_warning('Campos descripción son obligatorio no debe estar vacio.');
        }
        if (!slcArticulo.Code) {
          notify_warning('Codigo es obligatorio, no debe estar vacio.');
        }
      }
    }

  }

  useEffect(() => {
    const lastItem = listInitData[listInitData?.length - 1]
    //console.log("lastItem",lastItem);
    setFilaActiva(lastItem?.idArt)
    setArrDatosInsert(listInitData)
  }, [listInitData])
  const onClickDeletefila = (code) => {
    onHandleVPut("1")
    const myList = [...arrDatosInsert];
    console.log("Se elimianr el roeducto");
    const data = arrDatosInsert.findIndex((res) => (res.CodArt === code));
    myList.splice(data, 1);
    setArrDatosInsert(myList);
    console.log(arrDatosInsert);
    onDataUpdate('DESclientes', myList)
    setSlcArticulo([])

  }
  const TRPlantilllaCD = (prosp) => {
    return (
      <tr key={prosp.datoRow.idArt} >
        <td className='text-center' >
          {onValBloqueo ? <div className="d-none"></div> :

            <AButton tp_tilde="Eliminar" disabled={onValCopyDat ? false : prosp.datoRow.estadoComprometido} bt_funct={() => onClickDeletefila(prosp.datoRow.CodArt)} tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />

          }
        </td>
        <td style={{ position: 'relative', zIndex: '2', maxWidth: '50px' }} >
          {prosp.datoRow.CodArt}</td>
        <td>{prosp.datoRow.namArt}</td>
        <td></td>
      </tr>
    )
  }
  /** Modal */
  const [generalModal, setGeneralModal] = useState(false);
  const handleOpenGeneral = () => {
    if (arrCampDatos.txtDesc !== "") {
      setGeneralModal(true);
    } else {
      if (arrCampDatos.txtCod === "" && arrCampDatos.txtDesc === "") {
        notify_warning('Campo  descripción es obligatorio no deben estar vacio..');
      } else {
        if (arrCampDatos.txtCod.trim() === "") { notify_warning('Campo codigo es obligatorio no debe estar vacio.'); }
        if (arrCampDatos.txtDesc.trim() === "") { notify_warning('Campo descripción son obligatorio no debe estar vacio.'); }
      }
    }
  }
  const handleCloseGeneral = () => { setGeneralModal(false); }

  const handleRetrunData = (data) => {
    // console.log("data ", data );
    const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
    let newId = (lastItem === undefined || lastItem === "") ? 0 : lastItem.idArt;
    console.log(listInitData);

    if (listInitData.length > 0) {
      // Obtener los códigos que ya existen en array1
      const existingCodes = listInitData.map(item => item.CodArt);
      // Filtrar array2 para quedarnos sólo con elementos con códigos nuevos
      let lastId = listInitData[listInitData.length - 1].idArt;
      const newElements = data.filter(item => !existingCodes.includes(item.Code)).map(item => {
        lastId++;
        return {
          idArt: lastId,
          CodArt: item.Code,
          namArt: item.Name
        }
      });;
      const result = [...listInitData, ...newElements];
      //console.log("result", result);
      onDataUpdate('DESclientes', [...result])
      handleCloseGeneral()
      setTimeout(() => { selectRowRef?.current?.focus() }, 1000);

    } else {
      console.log("no hay data");
      // const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
      // let newId = (lastItem === undefined || lastItem === "") ? 0 : lastItem.idArt;
      data?.map((row) => {
        newId++;
        return arrDatosInsert?.push({
          "idArt": newId,
          "CodArt": row.Code,
          "namArt": row.Name,
        })
      })
      onDataUpdate('DESclientes', [...arrDatosInsert])
      handleCloseGeneral()
      setTimeout(() => { selectRowRef?.current?.focus() }, 1000);

    }
  }

  let columns = [
    { header: 'Code', accessorKey: 'Code' },
    { header: 'Name', accessorKey: 'Name' },

  ]

  return (
    <>
      <div className="data-tables table-responsive text-nowrap " style={{ position: 'relative', zIndex: '1', width: 'auto', height: '400px', overflowY: 'scroll', overflowX: 'scroll' }}>
        <table className="table table-sm" id='tab_DCliente' style={{ fontSize: '10px', zIndex: '2', }}>
          <thead style={{ position: 'sticky', top: 0, zIndex: 10 }}>
            <tr >
              <th>Actions</th>
              <th style={{ width: '350px' }} >Code Cliente  <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger d-none" ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /></th>
              <th style={{ width: '450px' }} >Des. Cliente</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody >
            {
              (arrDatosInsert.length) ? arrDatosInsert.map((row) => (
                <React.Fragment key={row.idArt}>
                  <TRPlantilllaCD key={row.idArt} datoRow={row} />
                  {onValBloqueo ? <div className="d-none"></div> :

                    (!filaActiva || filaActiva === row.idArt) && (
                      <tr key={prosp?.datoRow?.idArt}>
                        <td className='td1'></td>
                        <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                          <AsyncSelect
                            ref={selectRowRef}
                            styles={styles}
                            placeholder="Busque por Cod./Desc."
                            name="form-field-name"
                            className="sele_pru from-control form-control-sm tbCodArticulo"
                            value={slcArticulo}
                            defaultOptions
                            getOptionLabel={e => e.Code + ' - ' + e.Name}
                            getOptionValue={e => e.Code}
                            loadOptions={funDArticulos}
                            onChange={handleSelectChange}
                            maxMenuHeight={150}
                          />
                        </td>
                        <td className='td3'>{slcArticulo.Name}</td>
                        <td className='td4'>
                          <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                          <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info d-none" bt_funct={handleOpenGeneral} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                        </td>
                      </tr>
                    )
                  }
                </React.Fragment>
              ))
                : onValBloqueo ? <div className="d-none"></div> :

                  <tr key={prosp?.datoRow?.idArt}>
                    <td className='td1'>
                    </td>
                    <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                      <AsyncSelect
                        ref={selectRowRef}
                        styles={styles}
                        placeholder="Busque por Cod./Desc."
                        name="form-field-name"
                        className="sele_pru from-control form-control-sm tbCodArticulo"
                        value={slcArticulo}
                        defaultOptions
                        getOptionLabel={e => e.Code + ' - ' + e.Name}
                        getOptionValue={e => e.Code}
                        loadOptions={funDArticulos}
                        onChange={handleSelectChange}
                        maxMenuHeight={150}
                      />
                    </td>
                    <td className='td3'>{slcArticulo.Name}</td>
                    <td className='td4'>
                      <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                      <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info d-none" bt_funct={handleOpenGeneral} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                    </td>
                  </tr>
            }
          </tbody>
        </table>
      </div>
      {
        generalModal && (
          <ModalDetallesBoni
            hanOpenModal={generalModal}
            hanClosModal={handleCloseGeneral}
            //listInitData = {listInitData}
            onReturnData={handleRetrunData}
            onDataEstras={{ "columns": columns, "url": '/apiClientes?vcodEmpresa=' + cook_idEmp }}
          />
        )
      }

    </>
  )
}
export const DESGrupoArticulosDetallados = (prosp) => {
  const { arrCampDatos, listInitData, onDataUpdate, onHandleVPut, onValCopyDat, onValBloqueo } = prosp;
  const [arrDatosInsert, setArrDatosInsert] = useState([]);
  const [filaActiva, setFilaActiva] = useState(null);
  const [slcArticulo, setSlcArticulo] = useState([""]);
  const selectRowRef = useRef(null); // puentero
  const funDArticulos = useCallback(async (inputValue) => {
    const url = '/apiGrupoArticulos?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }, [])
  const handleSelectChange = (selectedOption) => {
    setSlcArticulo(selectedOption); // Actualiza el estado si es necesario.
  };
  const onClickInsert = async () => {
    if (arrCampDatos.txtDesc !== "" && slcArticulo.Code !== undefined) {
      const datosrepetidos = arrDatosInsert.filter(varr => varr.CodArt === slcArticulo.Code)
      if (datosrepetidos.length === 0) {
        const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
        const newId = (lastItem === undefined || lastItem === "") ? 1 : lastItem.idArt + 1;
        const newItem = { idArt: newId, CodArt: slcArticulo.Code, namArt: slcArticulo.Name };
        notify_valido("Se agrego correctamente!")
        setArrDatosInsert([...arrDatosInsert, newItem]);
        onDataUpdate('DESgrupoarticulo', [...arrDatosInsert, newItem])
        setFilaActiva(newId);
        setSlcArticulo([]);
        setTimeout(() => {
          selectRowRef.current.focus()
        }, 500);

      } else { notify_warning('Datos selecionados ya exiten.'); }
    } else {
      // notify_warning('Campos vacios.'); 
      if (arrCampDatos.txtCod === "" && arrCampDatos.txtDesc === "") {
        notify_warning('Campo  descripción es obligatorio no deben estar vacio.');
      } else {
        // if (arrCampDatos.txtCod.trim() === "") {
        //   notify_warning('Campos codigo es obligatorio no debe estar vacio.');
        // }
        if (arrCampDatos.txtDesc.trim() === "") {
          notify_warning('Campos descripción son obligatorio no debe estar vacio.');
        }
        if (!slcArticulo.Code) {
          notify_warning('Codigo es obligatorio, no debe estar vacio.');
        }
      }
    }
  }
  useEffect(() => {
    const lastItem = listInitData[listInitData?.length - 1]
    //console.log("lastItem",lastItem);
    setFilaActiva(lastItem?.idArt)
    setArrDatosInsert(listInitData)
  }, [listInitData])
  const onClickDeletefila = (code) => {
    onHandleVPut("1")
    console.log("code", code);
    const myList = [...arrDatosInsert];
    console.log("Se elimianr el roeducto");
    const data = arrDatosInsert.findIndex((res) => (res.CodArt === code));
    myList.splice(data, 1);
    setArrDatosInsert(myList);
    console.log(arrDatosInsert);
    onDataUpdate('DESgrupoarticulo', myList)
    setSlcArticulo([])

  }
  const TRPlantilllaCD = (prosp) => {
    return (
      <tr key={prosp.datoRow.idArt} >
        <td className='text-center' >
          {onValBloqueo ? <div className="d-none"></div> :

            <AButton tp_tilde="Eliminar" disabled={onValCopyDat ? false : prosp.datoRow.estadoComprometido} bt_funct={() => onClickDeletefila(prosp.datoRow.CodArt)} tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />

          }
        </td>
        <td style={{ position: 'relative', zIndex: '2', maxWidth: '50px' }} >
          {prosp.datoRow.CodArt}</td>
        <td>{prosp.datoRow.namArt}</td>
        <td></td>
      </tr>
    )
  }
  return (
    <>
      {/* Hola mundo BCEInicioDetalle 
      <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger " bt_funct={probar2} ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /> */}
      <div className="data-tables table-responsive text-nowrap " style={{ position: 'relative', zIndex: '1', width: 'auto', height: '400px', overflowY: 'scroll', overflowX: 'scroll' }}>
        <table className="table table-sm" id='tab_DGrupoArticulos' style={{ fontSize: '10px', zIndex: '2', }}>
          <thead style={{ position: 'sticky', top: 0, zIndex: 10 }}>
            <tr >
              <th>Actions</th>
              <th style={{ width: '350px' }} >Code Grupo  <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger d-none" ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /></th>
              <th style={{ width: '450px' }} >Des. Grupo</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody >
            {
              (arrDatosInsert.length) ? arrDatosInsert.map((row) => (
                <React.Fragment key={row.idArt}>
                  <TRPlantilllaCD key={row.idArt} datoRow={row} />
                  {onValBloqueo ? <div className="d-none"></div> :

                    (!filaActiva || filaActiva === row.idArt) && (
                      <tr key={prosp?.datoRow?.idArt}>
                        <td className='td1'></td>
                        <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                          <AsyncSelect
                            ref={selectRowRef}
                            styles={styles}
                            placeholder="Busque por Cod./Desc."
                            name="form-field-name"
                            className="sele_pru from-control form-control-sm tbCodArticulo"
                            value={slcArticulo}
                            defaultOptions
                            getOptionLabel={e => e.Code + ' - ' + e.Name}
                            getOptionValue={e => e.Code}
                            loadOptions={funDArticulos}
                            onChange={handleSelectChange}
                            maxMenuHeight={150}
                          />
                        </td>
                        <td className='td3'>{slcArticulo.Name}</td>
                        <td className='td4'>
                          <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />

                        </td>
                      </tr>
                    )
                  }
                </React.Fragment>
              ))
                : onValBloqueo ? <div className="d-none"></div> :

                  <tr key={prosp?.datoRow?.idArt}>
                    <td className='td1'>
                    </td>
                    <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                      <AsyncSelect
                        styles={styles}
                        placeholder="Busque por Cod./Desc."
                        name="form-field-name"
                        className="sele_pru from-control form-control-sm tbCodArticulo"
                        value={slcArticulo}
                        defaultOptions
                        getOptionLabel={e => e.Code + ' - ' + e.Name}
                        getOptionValue={e => e.Code}
                        loadOptions={funDArticulos}
                        onChange={handleSelectChange}
                        maxMenuHeight={150}
                      />
                    </td>
                    <td className='td3'>{slcArticulo.Name}</td>
                    <td className='td4'>
                      <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />

                    </td>
                  </tr>
            }
          </tbody>
        </table>
      </div>


    </>
  )
}
export const DESVendedorDetallador = (prosp) => {
  const { arrCampDatos, listInitData, onDataUpdate, onHandleVPut, onValCopyDat, onValBloqueo } = prosp

  const [arrDatosInsert, setArrDatosInsert] = useState([]);
  const [filaActiva, setFilaActiva] = useState(null);
  const [slcArticulo, setSlcArticulo] = useState([""]);
  const selectRowRef = useRef(null); // puentero

  const funDArticulos = useCallback(async (inputValue) => {
    const url = '/apiCodeVendedores?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }, [])
  const handleSelectChange = (selectedOption) => {
    setSlcArticulo(selectedOption); // Actualiza el estado si es necesario.
  };
  const onClickInsert = async () => {
    if (arrCampDatos.txtDesc !== "" && slcArticulo.Code !== undefined) {
      const datosrepetidos = arrDatosInsert.filter(varr => varr.CodArt === slcArticulo.Code)
      if (datosrepetidos.length === 0) {
        const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
        // const newId = (lastItem === undefined || lastItem === "") ? 2 : lastItem.idArt + 1;
        const newId = (lastItem === undefined || lastItem === "") ? 1 : lastItem.idArt + 1;

        const newItem = { idArt: newId, CodArt: slcArticulo.Code, namArt: slcArticulo.Name };
        notify_valido("Se agrego correctamente!")
        setArrDatosInsert([...arrDatosInsert, newItem]);
        onDataUpdate('DESvendedordetall', [...arrDatosInsert, newItem])
        setFilaActiva(newId);
        setSlcArticulo([])
        setTimeout(() => {
          selectRowRef.current.focus()
        }, 500);
      } else { notify_warning('Datos selecionados ya exiten.'); }
    } else {
      // notify_warning('Campos vacios.'); 
      if (arrCampDatos.txtCod === "" && arrCampDatos.txtDesc === "") {
        notify_warning('Campo  descripción es obligatorio no deben estar vacio..');
      } else {
        // if (arrCampDatos.txtCod.trim() === "") {
        //   notify_warning('Campos codigo es obligatorio no debe estar vacio.');
        // }
        if (arrCampDatos.txtDesc.trim() === "") {
          notify_warning('Campos descripción son obligatorio no debe estar vacio.');
        }
        if (!slcArticulo.Code) {
          notify_warning('Codigo es obligatorio, no debe estar vacio.');
        }
      }
    }
  }
  useEffect(() => {
    const lastItem = listInitData[listInitData?.length - 1]
    //console.log("lastItem",lastItem);
    setFilaActiva(lastItem?.idArt)
    setArrDatosInsert(listInitData)
  }, [listInitData])
  const onClickDeletefila = (code) => {
    onHandleVPut("1")
    const myList = [...arrDatosInsert];
    console.log("Se elimianr el roeducto");
    const data = arrDatosInsert.findIndex((res) => (res.CodArt === code));
    myList.splice(data, 1);
    setArrDatosInsert(myList);
    console.log(arrDatosInsert);
    onDataUpdate('DESvendedordetall', myList)
    setSlcArticulo([])

  }
  const TRPlantilllaCD = (prosp) => {
    return (
      <tr key={prosp.datoRow.idArt} >
        <td className='text-center' >
          {onValBloqueo ? <div className="d-none"></div> :

            <AButton tp_tilde="Eliminar" disabled={onValCopyDat ? false : prosp.datoRow.estadoComprometido} bt_funct={() => onClickDeletefila(prosp.datoRow.CodArt)} tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />

          }
        </td>
        <td style={{ position: 'relative', zIndex: '2', maxWidth: '50px' }} >
          {prosp.datoRow.CodArt}</td>
        <td>{prosp.datoRow.namArt}</td>
        <td></td>
      </tr>
    )
  }

  /** Modal */
  const [generalModal, setGeneralModal] = useState(false);
  const handleOpenGeneral = () => {
    if (arrCampDatos.txtDesc !== "") {
      setGeneralModal(true);
    } else {
      if (arrCampDatos.txtCod === "" && arrCampDatos.txtDesc === "") {
        notify_warning('Campo  descripción es obligatorio no deben estar vacio..');
      } else {
        if (arrCampDatos.txtCod.trim() === "") { notify_warning('Campo codigo es obligatorio no debe estar vacio.'); }
        if (arrCampDatos.txtDesc.trim() === "") { notify_warning('Campo descripción son obligatorio no debe estar vacio.'); }
      }
    }
  }
  const handleCloseGeneral = () => { setGeneralModal(false); }

  const handleRetrunData = (data) => {
    // console.log("data ", data );
    const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
    let newId = (lastItem === undefined || lastItem === "") ? 1 : lastItem.idArt;
    //console.log(listInitData);

    if (listInitData.length > 0) {
      // Obtener los códigos que ya existen en array1
      const existingCodes = listInitData.map(item => item.CodArt);
      // Filtrar array2 para quedarnos sólo con elementos con códigos nuevos
      let lastId = listInitData[listInitData.length - 1].idArt;
      const newElements = data.filter(item => !existingCodes.includes(item.Code)).map(item => {
        lastId++;
        return {
          idArt: lastId,
          CodArt: item.Code,
          namArt: item.Name
        }
      });;
      const result = [...listInitData, ...newElements];
      //console.log("result", result);
      onDataUpdate('DESvendedordetall', [...result])
      handleCloseGeneral()
      setTimeout(() => { selectRowRef?.current?.focus() }, 1000);

    } else {
      console.log("no hay data");
      // const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
      // let newId = (lastItem === undefined || lastItem === "") ? 0 : lastItem.idArt;
      data?.map((row) => {
        newId++;
        return arrDatosInsert?.push({
          "idArt": newId,
          "CodArt": row.Code,
          "namArt": row.Name,
        })
      })
      onDataUpdate('DESvendedordetall', [...arrDatosInsert])
      handleCloseGeneral()
      setTimeout(() => { selectRowRef?.current?.focus() }, 1000);

    }
  }
  let columns = [
    { header: 'Code', accessorKey: 'Code' },
    { header: 'Name', accessorKey: 'Name' },

  ]

  return (
    <>
      {/* Hola mundo BCEInicioDetalle 
      <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger " bt_funct={probar2} ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /> */}
      <div className="data-tables table-responsive text-nowrap " style={{ position: 'relative', zIndex: '1', width: 'auto', height: '400px', overflowY: 'scroll', overflowX: 'scroll' }}>
        <table className="table table-sm" id='tab_DVendedor' style={{ fontSize: '10px', zIndex: '2', }}>
          <thead style={{ position: 'sticky', top: 0, zIndex: 10 }}>
            <tr >
              <th>Actions</th>
              <th style={{ width: '350px' }} >Code Vendedor <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger d-none" ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /></th>
              <th style={{ width: '450px' }} >Des. vendedor</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody >
            {
              (arrDatosInsert.length) ? arrDatosInsert.map((row) => (
                <React.Fragment key={row.idArt}>
                  <TRPlantilllaCD key={row.idArt} datoRow={row} />
                  {onValBloqueo ? <div className="d-none"></div> :

                    (!filaActiva || filaActiva === row.idArt) && (
                      <tr key={prosp?.datoRow?.idArt}>
                        <td className='td1'></td>
                        <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                          <AsyncSelect
                            ref={selectRowRef}
                            styles={styles}
                            placeholder="Busque por Cod./Desc."
                            name="form-field-name"
                            className="sele_pru from-control form-control-sm tbCodArticulo"
                            value={slcArticulo}
                            defaultOptions
                            getOptionLabel={e => e.Code + ' - ' + e.Name}
                            getOptionValue={e => e.Code}
                            loadOptions={funDArticulos}
                            onChange={handleSelectChange}
                            maxMenuHeight={150}
                          />
                        </td>
                        <td className='td3'>{slcArticulo.Name}</td>
                        <td className='td4'>
                          <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                          <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info" bt_funct={handleOpenGeneral} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                        </td>
                      </tr>
                    )
                  }
                </React.Fragment>
              ))
                : onValBloqueo ? <div className="d-none"></div> :

                  <tr key={prosp?.datoRow?.idArt}>
                    <td className='td1'>
                    </td>
                    <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                      <AsyncSelect
                        ref={selectRowRef}
                        styles={styles}
                        placeholder="Busque por Cod./Desc."
                        name="form-field-name"
                        className="sele_pru from-control form-control-sm tbCodArticulo"
                        value={slcArticulo}
                        defaultOptions
                        getOptionLabel={e => e.Code + ' - ' + e.Name}
                        getOptionValue={e => e.Code}
                        loadOptions={funDArticulos}
                        onChange={handleSelectChange}
                        maxMenuHeight={150}
                      />
                    </td>
                    <td className='td3'>{slcArticulo.Name}</td>
                    <td className='td4'>
                      <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                      <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info" bt_funct={handleOpenGeneral} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                    </td>
                  </tr>
            }
          </tbody>
        </table>
      </div>
      {
        generalModal && (
          <ModalDetallesBoni
            hanOpenModal={generalModal}
            hanClosModal={handleCloseGeneral}
            //listInitData = {listInitData}
            onReturnData={handleRetrunData}
            onDataEstras={{ "columns": columns, "url": '/apiListaCodeVendedores?vcodEmpresa=' + cook_idEmp }}
          />
        )
      }
    </>
  )
}
export const DESSucursalDetallado = (prosp) => {
  const { arrCampDatos, listInitData, onDataUpdate, onHandleVPut, onValCopyDat, onValBloqueo } = prosp
  const [arrDatosInsert, setArrDatosInsert] = useState([]);
  const [filaActiva, setFilaActiva] = useState(null);
  const [slcArticulo, setSlcArticulo] = useState([""]);
  const selectRowRef = useRef(null); // puentero
  const funDArticulos = useCallback(async (inputValue) => {
    const url = '/apiSucursal?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }, [])
  const handleSelectChange = (selectedOption) => {
    setSlcArticulo(selectedOption); // Actualiza el estado si es necesario.
  };
  const onClickInsert = async () => {
    if (arrCampDatos.txtDesc !== "" && slcArticulo.Code !== undefined) {
      const datosrepetidos = arrDatosInsert.filter(varr => varr.CodArt === slcArticulo.Code)
      if (datosrepetidos.length === 0) {
        const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
        const newId = (lastItem === undefined || lastItem === "") ? 1 : lastItem.idArt + 1;
        const newItem = { idArt: newId, CodArt: slcArticulo.Code, namArt: slcArticulo.Name };
        notify_valido("Se agrego correctamente!")
        setArrDatosInsert([...arrDatosInsert, newItem]);
        onDataUpdate('DESsucursaldetll', [...arrDatosInsert, newItem]);
        setFilaActiva(newId);
        setSlcArticulo([])
        setTimeout(() => {
          selectRowRef.current.focus()
        }, 500);
      } else { notify_warning('Datos selecionados ya exiten.'); }
    } else {
      // notify_warning('Campos vacios.'); 
      if (arrCampDatos.txtCod === "" && arrCampDatos.txtDesc === "") {
        notify_warning('Campo  descripción es obligatorio no deben estar vacio..');
      } else {
        if (arrCampDatos.txtCod.trim() === "") {
          notify_warning('Campos codigo es obligatorio no debe estar vacio.');
        }
        if (arrCampDatos.txtDesc.trim() === "") {
          notify_warning('Campos descripción son obligatorio no debe estar vacio.');
        }
        if (!slcArticulo.Code) {
          notify_warning('Codigo es obligatorio, no debe estar vacio.');
        }
      }
    }
  }
  useEffect(() => {
    const lastItem = listInitData[listInitData?.length - 1]
    //console.log("lastItem",lastItem);
    setFilaActiva(lastItem?.idArt)
    setArrDatosInsert(listInitData)
  }, [listInitData])
  const onClickDeletefila = (code) => {
    onHandleVPut("1")
    const myList = [...arrDatosInsert];
    console.log("Se elimianr el roeducto");
    const data = arrDatosInsert.findIndex((res) => (res.CodArt === code));
    myList.splice(data, 1);
    setArrDatosInsert(myList);
    console.log(arrDatosInsert);
    onDataUpdate('DESsucursaldetll', myList)
    setSlcArticulo([])

  }
  const TRPlantilllaCD = (prosp) => {
    return (
      <tr key={prosp.datoRow.idArt} >
        <td className='text-center' >
          {onValBloqueo ? <div className="d-none"></div> :

            <AButton tp_tilde="Eliminar" disabled={onValCopyDat ? false : prosp.datoRow.estadoComprometido} bt_funct={() => onClickDeletefila(prosp.datoRow.CodArt)} tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />

          }
        </td>
        <td style={{ position: 'relative', zIndex: '2', maxWidth: '50px' }} >
          {prosp.datoRow.CodArt}</td>
        <td>{prosp.datoRow.namArt}</td>
        <td></td>
      </tr>
    )
  }
  return (
    <>
      {/* Hola mundo BCEInicioDetalle 
      <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger " bt_funct={probar2} ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /> */}
      <div className="data-tables table-responsive text-nowrap " style={{ position: 'relative', zIndex: '1', width: 'auto', height: '400px', overflowY: 'scroll', overflowX: 'scroll' }}>
        <table className="table table-sm" id='tab_BCEInicioDetalle' style={{ fontSize: '10px', zIndex: '2', }}>
          <thead style={{ position: 'sticky', top: 0, zIndex: 10 }}>
            <tr >
              <th>Actions</th>
              <th style={{ width: '350px' }}>Code Sucursal  <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger d-none" ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /></th>
              <th style={{ width: '450px' }}>Des. Sucursal</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody >
            {
              (arrDatosInsert.length) ? arrDatosInsert.map((row) => (
                <React.Fragment key={row.idArt}>
                  <TRPlantilllaCD key={row.idArt} datoRow={row} />
                  {onValBloqueo ? <div className="d-none"></div> :

                    (!filaActiva || filaActiva === row.idArt) && (
                      <tr key={prosp?.datoRow?.idArt}>
                        <td className='td1'></td>
                        <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                          <AsyncSelect
                            ref={selectRowRef}
                            styles={styles}
                            placeholder="Busque por Cod./Desc."
                            name="form-field-name"
                            className="sele_pru from-control form-control-sm tbCodArticulo"
                            value={slcArticulo}
                            defaultOptions
                            getOptionLabel={e => e.Code + ' - ' + e.Name}
                            getOptionValue={e => e.Code}
                            loadOptions={funDArticulos}
                            onChange={handleSelectChange}
                            maxMenuHeight={150}
                          />
                        </td>
                        <td className='td3'>{slcArticulo.Name}</td>
                        <td className='td4'>
                          <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />

                        </td>
                      </tr>
                    )
                  }
                </React.Fragment>
              ))
                : onValBloqueo ? <div className="d-none"></div> :

                  <tr key={prosp?.datoRow?.idArt}>
                    <td className='td1'>
                    </td>
                    <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                      <AsyncSelect
                        styles={styles}
                        placeholder="Busque por Cod./Desc."
                        name="form-field-name"
                        className="sele_pru from-control form-control-sm tbCodArticulo"
                        value={slcArticulo}
                        defaultOptions
                        getOptionLabel={e => e.Code + ' - ' + e.Name}
                        getOptionValue={e => e.Code}
                        loadOptions={funDArticulos}
                        onChange={handleSelectChange}
                        maxMenuHeight={150}
                      />
                    </td>
                    <td className='td3'>{slcArticulo.Name}</td>
                    <td className='td4'>
                      <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />

                    </td>
                  </tr>
            }
          </tbody>
        </table>
      </div>
    </>
  )
}
export const DESMesaDetalle = (prosp) => {
  const { arrCampDatos, listInitData, onDataUpdate, onHandleVPut, onValCopyDat, onValBloqueo } = prosp;
  const [arrDatosInsert, setArrDatosInsert] = useState([]);
  const [filaActiva, setFilaActiva] = useState(null);
  const [slcArticulo, setSlcArticulo] = useState([""]);
  const selectRowRef = useRef(null); // puentero
  const funDArticulos = useCallback(async (inputValue) => {
    const url = '/apiMesa?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }, [])
  const handleSelectChange = (selectedOption) => { setSlcArticulo(selectedOption); };
  const onClickInsert = async () => {
    if (arrCampDatos.txtDesc !== "" && slcArticulo.Code !== undefined) {
      const datosrepetidos = arrDatosInsert.filter(varr => varr.CodArt === slcArticulo.Code)
      if (datosrepetidos.length === 0) {
        const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
        const newId = (lastItem === undefined || lastItem === "") ? 1 : lastItem.idArt + 1;
        const newItem = { idArt: newId, CodArt: slcArticulo.Code, namArt: slcArticulo.Name };
        notify_valido("Se agrego correctamente!")
        setArrDatosInsert([...arrDatosInsert, newItem]);
        onDataUpdate('DESmesadetall', [...arrDatosInsert, newItem])
        setFilaActiva(newId);
        setSlcArticulo([])
        setTimeout(() => {
          selectRowRef.current.focus()
        }, 500);
      } else { notify_warning('Datos selecionados ya exiten.'); }
    } else {
      //  notify_warning('Campos vacios.'); 
      if (arrCampDatos.txtCod === "" && arrCampDatos.txtDesc === "") {
        notify_warning('Campo  descripción es obligatorio no deben estar vacio..');
      } else {
        if (arrCampDatos.txtCod.trim() === "") {
          notify_warning('Campos codigo es obligatorio no debe estar vacio.');
        }
        if (arrCampDatos.txtDesc.trim() === "") {
          notify_warning('Campos descripción son obligatorio no debe estar vacio.');
        }
        if (!slcArticulo.Code) {
          notify_warning('Codigo es obligatorio, no debe estar vacio.');
        }
      }

    }
  }
  useEffect(() => {
    const lastItem = listInitData[listInitData?.length - 1]
    //console.log("lastItem",lastItem);
    setFilaActiva(lastItem?.idArt)
    setArrDatosInsert(listInitData)
  }, [listInitData])
  const onClickDeletefila = (code) => {
    onHandleVPut("1")
    const myList = [...arrDatosInsert];
    console.log("Se elimianr el roeducto");
    const data = arrDatosInsert.findIndex((res) => (res.CodArt === code));
    myList.splice(data, 1);
    setArrDatosInsert(myList);
    console.log(arrDatosInsert);
    onDataUpdate('DESmesadetall', myList)
    setSlcArticulo([])

  }
  const TRPlantilllaCD = (prosp) => {
    return (
      <tr key={prosp.datoRow.idArt} >
        <td className='text-center' >
          {onValBloqueo ? <div className="d-none"></div> :

            <AButton tp_tilde="Eliminar" disabled={onValCopyDat ? false : prosp.datoRow.estadoComprometido} bt_funct={() => onClickDeletefila(prosp.datoRow.CodArt)} tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />

          }
        </td>
        <td style={{ position: 'relative', zIndex: '2', maxWidth: '50px' }} >
          {prosp.datoRow.CodArt}</td>
        <td>{prosp.datoRow.namArt}</td>
        <td></td>
      </tr>
    )
  }
  /** Modal */
  const [generalModal, setGeneralModal] = useState(false);
  const handleOpenGeneral = () => {
    if (arrCampDatos.txtDesc !== "") {
      setGeneralModal(true);
    } else {
      if (arrCampDatos.txtCod === "" && arrCampDatos.txtDesc === "") {
        notify_warning('Campo  descripción es obligatorio no deben estar vacio..');
      } else {
        if (arrCampDatos.txtCod.trim() === "") { notify_warning('Campo codigo es obligatorio no debe estar vacio.'); }
        if (arrCampDatos.txtDesc.trim() === "") { notify_warning('Campo descripción son obligatorio no debe estar vacio.'); }
      }
    }
  }
  const handleCloseGeneral = () => { setGeneralModal(false); }

  const handleRetrunData = (data) => {
    // console.log("data ", data );
    const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
    let newId = (lastItem === undefined || lastItem === "") ? 0 : lastItem.idArt;
    console.log(listInitData);

    if (listInitData.length > 0) {
      // Obtener los códigos que ya existen en array1
      const existingCodes = listInitData.map(item => item.CodArt);
      // Filtrar array2 para quedarnos sólo con elementos con códigos nuevos
      let lastId = listInitData[listInitData.length - 1].idArt;
      const newElements = data.filter(item => !existingCodes.includes(item.Code)).map(item => {
        lastId++;
        return {
          idArt: lastId,
          CodArt: item.Code,
          namArt: item.Name
        }
      });;
      const result = [...listInitData, ...newElements];
      //console.log("result", result);
      onDataUpdate('DESmesadetall', [...result])
      handleCloseGeneral()
      setTimeout(() => { selectRowRef?.current?.focus() }, 1000);

    } else {
      console.log("no hay data");
      // const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
      // let newId = (lastItem === undefined || lastItem === "") ? 0 : lastItem.idArt;
      data?.map((row) => {
        newId++;
        arrDatosInsert?.push({
          "idArt": newId,
          "CodArt": row.Code,
          "namArt": row.Name,
        })
      })
      onDataUpdate('DESmesadetall', [...arrDatosInsert])
      handleCloseGeneral()
      setTimeout(() => { selectRowRef?.current?.focus() }, 1000);

    }
  }

  let columns = [
    { header: 'Code', accessorKey: 'Code' },
    { header: 'Name', accessorKey: 'Name' },

  ]

  return (
    <>
      {/* Hola mundo BCEInicioDetalle 
      <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger " bt_funct={probar2} ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /> */}
      <div className="data-tables table-responsive text-nowrap " style={{ position: 'relative', zIndex: '1', width: 'auto', height: '400px', overflowY: 'scroll', overflowX: 'scroll' }}>
        <table className="table table-sm" id='tab_BCEMesa' style={{ fontSize: '10px', zIndex: '2', }}>
          <thead style={{ position: 'sticky', top: 0, zIndex: 10 }}>
            <tr >
              <th>Actions</th>
              <th style={{ width: '350px' }} >Code Mesa <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger d-none" ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /></th>
              <th style={{ width: '450px' }} >Des. Mesa </th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody >
            {
              (arrDatosInsert.length) ? arrDatosInsert.map((row) => (
                <React.Fragment key={row.idArt}>
                  <TRPlantilllaCD key={row.idArt} datoRow={row} />
                  {onValBloqueo ? <div className="d-none"></div> :

                    (!filaActiva || filaActiva === row.idArt) && (
                      <tr key={prosp?.datoRow?.idArt}>
                        <td className='td1'></td>
                        <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                          <AsyncSelect
                            ref={selectRowRef}
                            styles={styles}
                            placeholder="Busque por Cod./Desc."
                            name="form-field-name"
                            className="sele_pru from-control form-control-sm tbCodArticulo"
                            value={slcArticulo}
                            defaultOptions
                            getOptionLabel={e => e.Code + ' - ' + e.Name}
                            getOptionValue={e => e.Code}
                            loadOptions={funDArticulos}
                            onChange={handleSelectChange}
                            maxMenuHeight={150}
                          />
                        </td>
                        <td className='td3'>{slcArticulo.Name}</td>
                        <td className='td4'>
                          <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                          <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info" bt_funct={handleOpenGeneral} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                        </td>
                      </tr>
                    )
                  }
                </React.Fragment>
              ))
                : onValBloqueo ? <div className="d-none"></div> :

                  <tr key={prosp?.datoRow?.idArt}>
                    <td className='td1'>
                    </td>
                    <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                      <AsyncSelect
                        ref={selectRowRef}
                        styles={styles}
                        placeholder="Busque por Cod./Desc."
                        name="form-field-name"
                        className="sele_pru from-control form-control-sm tbCodArticulo"
                        value={slcArticulo}
                        defaultOptions
                        getOptionLabel={e => e.Code + ' - ' + e.Name}
                        getOptionValue={e => e.Code}
                        loadOptions={funDArticulos}
                        onChange={handleSelectChange}
                        maxMenuHeight={150}
                      />
                    </td>
                    <td className='td3'>{slcArticulo.Name}</td>
                    <td className='td4'>
                      <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                      <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info" bt_funct={handleOpenGeneral} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                    </td>
                  </tr>
            }
          </tbody>
        </table>
      </div>
      {
        generalModal && (
          <ModalDetallesBoni
            hanOpenModal={generalModal}
            hanClosModal={handleCloseGeneral}
            //listInitData = {listInitData}
            onReturnData={handleRetrunData}
            onDataEstras={{ "columns": columns, "url": '/apiMesa?vcodEmpresa=' + cook_idEmp }}
          />
        )
      }
    </>
  )
}
export const DESCategoriacliente = (prosp) => {
  const { arrCampDatos, listInitData, onDataUpdate, onHandleVPut, onValCopyDat, onValBloqueo } = prosp;
  const [arrDatosInsert, setArrDatosInsert] = useState([]);
  const [filaActiva, setFilaActiva] = useState(null);
  const [slcArticulo, setSlcArticulo] = useState([""]);
  const selectRowRef = useRef(null); // puntero

  const funDArticulos = useCallback(async (inputValue) => {
    const url = '/apiCategoriaCliente?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }, [])
  const handleSelectChange = (selectedOption) => {
    setSlcArticulo(selectedOption); // Actualiza el estado si es necesario.
  };
  const onClickInsert = async () => {
    if (arrCampDatos.txtDesc !== "" && slcArticulo.Code !== undefined) {
      const datosrepetidos = arrDatosInsert.filter(varr => varr.CodArt === slcArticulo.Code)
      if (datosrepetidos.length === 0) {
        const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
        const newId = (lastItem === undefined || lastItem === "") ? 1 : lastItem.idArt + 1;
        const newItem = { idArt: newId, CodArt: slcArticulo.Code, namArt: slcArticulo.Name };
        notify_valido("Se agrego correctamente!")
        setArrDatosInsert([...arrDatosInsert, newItem]);
        onDataUpdate('DEScategoriacliente', [...arrDatosInsert, newItem])
        setFilaActiva(newId);
        setSlcArticulo([])
        setTimeout(() => {
          selectRowRef.current.focus()
        }, 500);
      } else { notify_warning('Datos selecionados ya exiten.'); }
    } else {
      if (arrCampDatos.txtCod === "" && arrCampDatos.txtDesc === "") {
        notify_warning('Campo  descripción es obligatorio no deben estar vacio..');
      } else {
        if (arrCampDatos.txtCod.trim() === "") { notify_warning('Campo codigo es obligatorio no debe estar vacio.'); }
        if (arrCampDatos.txtDesc.trim() === "") { notify_warning('Campo descripción son obligatorio no debe estar vacio.'); }
        if (!slcArticulo.Code) { notify_warning('Codigo es obligatorio, no debe estar vacio.'); }
      }
    }
  }
  useEffect(() => {
    const lastItem = listInitData[listInitData?.length - 1]
    //console.log("lastItem",lastItem);
    setFilaActiva(lastItem?.idArt)
    setArrDatosInsert(listInitData)
  }, [listInitData])
  const onClickDeletefila = (code) => {
    onHandleVPut("1")
    const myList = [...arrDatosInsert];
    console.log("Se elimianr el roeducto");
    const data = arrDatosInsert.findIndex((res) => (res.CodArt === code));
    myList.splice(data, 1);
    setArrDatosInsert(myList);
    console.log(arrDatosInsert);
    onDataUpdate('DEScategoriacliente', myList)
    setSlcArticulo([])

  }
  const TRPlantilllaCD = (prosp) => {
    return (
      <tr key={prosp.datoRow.idArt} >
        <td className='text-center' >
          {onValBloqueo ? <div className="d-none"></div> :

            <AButton tp_tilde="Eliminar" disabled={onValCopyDat ? false : prosp.datoRow.estadoComprometido} bt_funct={() => onClickDeletefila(prosp.datoRow.CodArt)} tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />

          }
        </td>
        <td style={{ position: 'relative', zIndex: '2', maxWidth: '50px' }} >
          {prosp.datoRow.CodArt}</td>
        <td>{prosp.datoRow.namArt}</td>
        <td></td>
      </tr>
    )
  }
  return (
    <>
      {/* Hola mundo BCEInicioDetalle 
      <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger " bt_funct={probar2} ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /> */}
      <div className="data-tables table-responsive text-nowrap " style={{ position: 'relative', zIndex: '1', width: 'auto', height: '400px', overflowY: 'scroll', overflowX: 'scroll' }}>
        <table className="table table-sm" id='tab_BCEMesa' style={{ fontSize: '10px', zIndex: '2', }}>
          <thead style={{ position: 'sticky', top: 0, zIndex: 10, }}>
            <tr >
              <th>Actions</th>
              <th style={{ width: '350px' }} >Code Cat. Cliente <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger d-none" ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /></th>
              <th style={{ width: '450px' }} >Des. Cat. Cliente </th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody >
            {
              (arrDatosInsert.length) ? arrDatosInsert.map((row) => (
                <React.Fragment key={row.idArt}>
                  <TRPlantilllaCD key={row.idArt} datoRow={row} />
                  {onValBloqueo ? <div className="d-none"></div> :

                    (!filaActiva || filaActiva === row.idArt) && (
                      <tr key={prosp?.datoRow?.idArt}>
                        <td className='td1'>
                        </td>
                        <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                          <AsyncSelect
                            ref={selectRowRef}
                            styles={styles}
                            placeholder="Busque por Cod./Desc."
                            name="form-field-name"
                            className="sele_pru from-control form-control-sm tbCodArticulo"
                            value={slcArticulo}
                            defaultOptions
                            getOptionLabel={e => e.Code + ' - ' + e.Name}
                            getOptionValue={e => e.Code}
                            loadOptions={funDArticulos}
                            onChange={handleSelectChange}
                            maxMenuHeight={150}
                          />
                        </td>
                        <td className='td3'>{slcArticulo.Name}</td>
                        <td className='td4'>
                          <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />

                        </td>
                      </tr>
                    )
                  }
                </React.Fragment>
              ))
                : onValBloqueo ? <div className="d-none"></div> :

                  <tr key={prosp?.datoRow?.idArt}>
                    <td className='td1'></td>
                    <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                      <AsyncSelect
                        styles={styles}
                        placeholder="Busque por Cod./Desc."
                        name="form-field-name"
                        className="sele_pru from-control form-control-sm tbCodArticulo"
                        value={slcArticulo}
                        defaultOptions
                        getOptionLabel={e => e.Code + ' - ' + e.Name}
                        getOptionValue={e => e.Code}
                        loadOptions={funDArticulos}
                        onChange={handleSelectChange}
                        maxMenuHeight={150}
                      />
                    </td>
                    <td className='td3'>{slcArticulo.Name}</td>
                    <td className='td4'>
                      <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />

                    </td>
                  </tr>
            }
          </tbody>
        </table>
      </div>
    </>
  )
}
export const DESgrupoventas = (prosp) => {
  const { arrCampDatos, listInitData, onDataUpdate, onHandleVPut, onValCopyDat, onValBloqueo } = prosp;
  const [arrDatosInsert, setArrDatosInsert] = useState([]);
  const [filaActiva, setFilaActiva] = useState(null);
  const [slcArticulo, setSlcArticulo] = useState([""]);
  const selectRowRef = useRef(null); // puntero
  const funDArticulos = useCallback(async (inputValue) => {
    const url = '/apiGrupoVenta?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }, [])
  const handleSelectChange = (selectedOption) => {
    setSlcArticulo(selectedOption); // Actualiza el estado si es necesario.
  };
  const onClickInsert = async () => {
    if (arrCampDatos.txtDesc !== "" && slcArticulo.Code !== undefined) {
      const datosrepetidos = arrDatosInsert.filter(varr => varr.CodArt === slcArticulo.Code)
      if (datosrepetidos.length === 0) {
        const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
        const newId = (lastItem === undefined || lastItem === "") ? 1 : lastItem.idArt + 1;
        const newItem = { idArt: newId, CodArt: slcArticulo.Code, namArt: slcArticulo.Name };
        notify_valido("Se agrego correctamente!")
        setArrDatosInsert([...arrDatosInsert, newItem]);
        onDataUpdate('DESgrupoventas', [...arrDatosInsert, newItem])
        setFilaActiva(newId);
        setSlcArticulo([])
        setTimeout(() => { selectRowRef.current.focus() }, 500);

      } else { notify_warning('Datos selecionados ya exiten.'); }
    } else {
      if (arrCampDatos.txtCod === "" && arrCampDatos.txtDesc === "") {
        notify_warning('Campo  descripción es obligatorio no deben estar vacio..');
      } else {
        if (arrCampDatos.txtCod.trim() === "") { notify_warning('Campo codigo es obligatorio no debe estar vacio.'); }
        if (arrCampDatos.txtDesc.trim() === "") { notify_warning('Campo descripción son obligatorio no debe estar vacio.'); }
        if (!slcArticulo.Code) { notify_warning('Codigo es obligatorio, no debe estar vacio.'); }
      }
    }
  }
  useEffect(() => {
    const lastItem = listInitData[listInitData?.length - 1]
    //console.log("lastItem",lastItem);
    setFilaActiva(lastItem?.idArt)
    setArrDatosInsert(listInitData)
  }, [listInitData])
  const onClickDeletefila = (code) => {
    onHandleVPut("1")
    const myList = [...arrDatosInsert];
    console.log("Se elimianr el roeducto");
    const data = arrDatosInsert.findIndex((res) => (res.CodArt === code));
    myList.splice(data, 1);
    setArrDatosInsert(myList);
    console.log(arrDatosInsert);
    onDataUpdate('DESgrupoventas', myList)
    setSlcArticulo([])

  }
  const TRPlantilllaCD = (prosp) => {
    return (
      <tr key={prosp.datoRow.idArt} >
        <td className='text-center' >
          {onValBloqueo ? <div className="d-none"></div> :

            <AButton tp_tilde="Eliminar" disabled={onValCopyDat ? false : prosp.datoRow.estadoComprometido} bt_funct={() => onClickDeletefila(prosp.datoRow.CodArt)} tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />

          }
        </td>
        <td style={{ position: 'relative', zIndex: '2', maxWidth: '50px' }} >
          {prosp.datoRow.CodArt}</td>
        <td>{prosp.datoRow.namArt}</td>
        <td></td>
      </tr>
    )
  }

  /** Modal */
  const [generalModal, setGeneralModal] = useState(false);
  const handleOpenGeneral = () => {
    if (arrCampDatos.txtDesc !== "") {
      setGeneralModal(true);
    } else {
      if (arrCampDatos.txtCod === "" && arrCampDatos.txtDesc === "") {
        notify_warning('Campo  descripción es obligatorio no deben estar vacio..');
      } else {
        if (arrCampDatos.txtCod.trim() === "") { notify_warning('Campo codigo es obligatorio no debe estar vacio.'); }
        if (arrCampDatos.txtDesc.trim() === "") { notify_warning('Campo descripción son obligatorio no debe estar vacio.'); }
      }
    }
  }
  const handleCloseGeneral = () => { setGeneralModal(false); }

  const handleRetrunData = (data) => {
    // console.log("data ", data );
    const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
    let newId = (lastItem === undefined || lastItem === "") ? 0 : lastItem.idArt;
    console.log(listInitData);

    if (listInitData.length > 0) {
      // Obtener los códigos que ya existen en array1
      const existingCodes = listInitData.map(item => item.CodArt);
      // Filtrar array2 para quedarnos sólo con elementos con códigos nuevos
      let lastId = listInitData[listInitData.length - 1].idArt;
      const newElements = data.filter(item => !existingCodes.includes(item.Code)).map(item => {
        lastId++;
        return {
          idArt: lastId,
          CodArt: item.Code,
          namArt: item.Name
        }
      });;
      const result = [...listInitData, ...newElements];
      //console.log("result", result);
      onDataUpdate('DESgrupoventas', [...result])
      handleCloseGeneral()
      setTimeout(() => { selectRowRef?.current?.focus() }, 1000);

    } else {
      console.log("no hay data");
      // const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
      // let newId = (lastItem === undefined || lastItem === "") ? 0 : lastItem.idArt;
      data?.map((row) => {
        newId++;
        return arrDatosInsert?.push({
          "idArt": newId,
          "CodArt": row.Code,
          "namArt": row.Name,
        })
      })
      onDataUpdate('DESgrupoventas', [...arrDatosInsert])
      handleCloseGeneral()
      setTimeout(() => { selectRowRef?.current?.focus() }, 1000);

    }
  }

  let columns = [
    { header: 'Code', accessorKey: 'Code' },
    { header: 'Name', accessorKey: 'Name' },

  ]
  return (
    <>
      {/* Hola mundo BCEInicioDetalle 
      <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger " bt_funct={probar2} ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /> */}
      <div className="data-tables table-responsive text-nowrap " style={{ position: 'relative', zIndex: '1', width: 'auto', height: '400px', overflowY: 'scroll', overflowX: 'scroll' }}>
        <table className="table table-sm" id='tab_BCEMesa' style={{ fontSize: '10px', zIndex: '2', }}>
          <thead style={{ position: 'sticky', top: 0, zIndex: 10, }}>
            <tr >
              <th>Actions</th>
              <th style={{ width: '350px' }} >Code Grupo Ventas <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger d-none" ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /></th>
              <th style={{ width: '450px' }} >Des. Grupo Ventas</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody >
            {
              (arrDatosInsert.length) ? arrDatosInsert.map((row) => (
                <React.Fragment key={row.idArt}>
                  <TRPlantilllaCD key={row.idArt} datoRow={row} />
                  {onValBloqueo ? <div className="d-none"></div> :

                    (!filaActiva || filaActiva === row.idArt) && (
                      <tr key={prosp?.datoRow?.idArt}>
                        <td className='td1'>
                        </td>
                        <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                          <AsyncSelect
                            ref={selectRowRef}
                            styles={styles}
                            placeholder="Busque por Cod./Desc."
                            name="form-field-name"
                            className="sele_pru from-control form-control-sm tbCodArticulo"
                            value={slcArticulo}
                            defaultOptions
                            getOptionLabel={e => e.Code + ' - ' + e.Name}
                            getOptionValue={e => e.Code}
                            loadOptions={funDArticulos}
                            onChange={handleSelectChange}
                            maxMenuHeight={150}
                          />
                        </td>
                        <td className='td3'>{slcArticulo.Name}</td>
                        <td className='td4'>
                          <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                          <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info" bt_funct={handleOpenGeneral} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                        </td>
                      </tr>
                    )
                  }
                </React.Fragment>
              ))
                : onValBloqueo ? <div className="d-none"></div> :

                  <tr key={prosp?.datoRow?.idArt}>
                    <td className='td1'></td>
                    <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                      <AsyncSelect
                        ref={selectRowRef}
                        styles={styles}
                        placeholder="Busque por Cod./Desc."
                        name="form-field-name"
                        className="sele_pru from-control form-control-sm tbCodArticulo"
                        value={slcArticulo}
                        defaultOptions
                        getOptionLabel={e => e.Code + ' - ' + e.Name}
                        getOptionValue={e => e.Code}
                        loadOptions={funDArticulos}
                        onChange={handleSelectChange}
                        maxMenuHeight={150}
                      />
                    </td>
                    <td className='td3'>{slcArticulo.Name}</td>
                    <td className='td4'>
                      <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                      <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info" bt_funct={handleOpenGeneral} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                    </td>
                  </tr>
            }
          </tbody>
        </table>
      </div>
      {
        generalModal && (
          <ModalDetallesBoni
            hanOpenModal={generalModal}
            hanClosModal={handleCloseGeneral}
            //listInitData = {listInitData}
            onReturnData={handleRetrunData}
            onDataEstras={{ "columns": columns, "url": '/apiGrupoVenta?vcodEmpresa=' + cook_idEmp }}
          />
        )
      }
    </>
  )
}
export const DEScondicionespago = (prosp) => {
  const { arrCampDatos, listInitData, onDataUpdate, onHandleVPut, onValCopyDat, onValBloqueo } = prosp;
  const [arrDatosInsert, setArrDatosInsert] = useState([]);
  const [filaActiva, setFilaActiva] = useState(null);
  const [slcArticulo, setSlcArticulo] = useState([""]);
  const selectRowRef = useRef(null); // puntero
  const funDArticulos = useCallback(async (inputValue) => {
    const url = '/apiCondicionPagos?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }, [])
  const handleSelectChange = (selectedOption) => {
    setSlcArticulo(selectedOption); // Actualiza el estado si es necesario.
  };
  const onClickInsert = async () => {
    if (arrCampDatos.txtDesc !== "" && slcArticulo.Code !== undefined) {
      const datosrepetidos = arrDatosInsert.filter(varr => varr.CodArt === slcArticulo.Code)
      if (datosrepetidos.length === 0) {
        const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
        const newId = (lastItem === undefined || lastItem === "") ? 1 : lastItem.idArt + 1;
        const newItem = { idArt: newId, CodArt: slcArticulo.Code, namArt: slcArticulo.Name };
        notify_valido("Se agrego correctamente!")
        setArrDatosInsert([...arrDatosInsert, newItem]);
        onDataUpdate('DEScondicionespago', [...arrDatosInsert, newItem])
        setFilaActiva(newId);
        setSlcArticulo([])
        setTimeout(() => {
          selectRowRef.current.focus()
        }, 500);

      } else { notify_warning('Datos selecionados ya exiten.'); }
    } else {
      if (arrCampDatos.txtCod === "" && arrCampDatos.txtDesc === "") {
        notify_warning('Campo  descripción es obligatorio no deben estar vacio..');
      } else {
        if (arrCampDatos.txtCod.trim() === "") { notify_warning('Campo codigo es obligatorio no debe estar vacio.'); }
        if (arrCampDatos.txtDesc.trim() === "") { notify_warning('Campo descripción son obligatorio no debe estar vacio.'); }
        if (!slcArticulo.Code) { notify_warning('Codigo es obligatorio, no debe estar vacio.'); }
      }
    }
  }
  useEffect(() => {
    const lastItem = listInitData[listInitData?.length - 1]
    //console.log("lastItem",lastItem);
    setFilaActiva(lastItem?.idArt)
    setArrDatosInsert(listInitData)
  }, [listInitData])
  const onClickDeletefila = (code) => {
    onHandleVPut("1")
    const myList = [...arrDatosInsert];
    console.log("Se elimianr el roeducto");
    const data = arrDatosInsert.findIndex((res) => (res.CodArt === code));
    myList.splice(data, 1);
    setArrDatosInsert(myList);
    console.log(arrDatosInsert);
    onDataUpdate('DEScondicionespago', myList)
    setSlcArticulo([])

  }
  const TRPlantilllaCD = (prosp) => {
    return (
      <tr key={prosp.datoRow.idArt} >
        <td className='text-center' >
          {onValBloqueo ? <div className="d-none"></div> :

            <AButton tp_tilde="Eliminar" disabled={onValCopyDat ? false : prosp.datoRow.estadoComprometido} bt_funct={() => onClickDeletefila(prosp.datoRow.CodArt)} tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />

          }
        </td>
        <td style={{ position: 'relative', zIndex: '2', maxWidth: '50px' }} >
          {prosp.datoRow.CodArt}</td>
        <td>{prosp.datoRow.namArt}</td>
        <td></td>
      </tr>
    )
  }
  return (
    <>
      {/* Hola mundo BCEInicioDetalle 
      <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger " bt_funct={probar2} ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /> */}
      <div className="data-tables table-responsive text-nowrap " style={{ position: 'relative', zIndex: '1', width: 'auto', height: '400px', overflowY: 'scroll', overflowX: 'scroll' }}>
        <table className="table table-sm" id='tab_BCEMesa' style={{ fontSize: '10px', zIndex: '2', }}>
          <thead style={{ position: 'sticky', top: 0, zIndex: 10, }}>
            <tr >
              <th>Actions</th>
              <th style={{ width: '350px' }} >Code Mesa <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger d-none" ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /></th>
              <th style={{ width: '450px' }} >Des. Mesa </th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody >
            {
              (arrDatosInsert.length) ? arrDatosInsert.map((row) => (
                <React.Fragment key={row.idArt}>
                  <TRPlantilllaCD key={row.idArt} datoRow={row} />
                  {onValBloqueo ? <div className="d-none"></div> :

                    (!filaActiva || filaActiva === row.idArt) && (
                      <tr key={prosp?.datoRow?.idArt}>
                        <td className='td1'>
                        </td>
                        <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                          <AsyncSelect
                            ref={selectRowRef}
                            styles={styles}
                            placeholder="Busque por Cod./Desc."
                            name="form-field-name"
                            className="sele_pru from-control form-control-sm tbCodArticulo"
                            value={slcArticulo}
                            defaultOptions
                            getOptionLabel={e => e.Code + ' - ' + e.Name}
                            getOptionValue={e => e.Code}
                            loadOptions={funDArticulos}
                            onChange={handleSelectChange}
                            maxMenuHeight={150}
                          />
                        </td>
                        <td className='td3'>{slcArticulo.Name}</td>
                        <td className='td4'>
                          <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />

                        </td>
                      </tr>
                    )
                  }
                </React.Fragment>
              ))
                : onValBloqueo ? <div className="d-none"></div> :

                  <tr key={prosp?.datoRow?.idArt}>
                    <td className='td1'></td>
                    <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                      <AsyncSelect
                        styles={styles}
                        placeholder="Busque por Cod./Desc."
                        name="form-field-name"
                        className="sele_pru from-control form-control-sm tbCodArticulo"
                        value={slcArticulo}
                        defaultOptions
                        getOptionLabel={e => e.Code + ' - ' + e.Name}
                        getOptionValue={e => e.Code}
                        loadOptions={funDArticulos}
                        onChange={handleSelectChange}
                        maxMenuHeight={150}
                      />
                    </td>
                    <td className='td3'>{slcArticulo.Name}</td>
                    <td className='td4'>
                      <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />

                    </td>
                  </tr>
            }
          </tbody>
        </table>
      </div>
    </>
  )
}
export const DESVendedoresExcluidos = (prosp) => {
  const { arrCampDatos, listInitData, onDataUpdate, onHandleVPut, onValCopyDat, onValBloqueo } = prosp
  const [arrDatosInsert, setArrDatosInsert] = useState([]);
  const [filaActiva, setFilaActiva] = useState(null);
  const [slcArticulo, setSlcArticulo] = useState([""]);
  const selectRowRef = useRef(null); // puentero

  const funDArticulos = useCallback(async (inputValue) => {
    const url = '/apiCodeVendedores?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }, [])
  const handleSelectChange = (selectedOption) => { setSlcArticulo(selectedOption); };
  const onClickInsert = async () => {
    if (arrCampDatos.txtDesc !== "" && slcArticulo.Code !== undefined) {
      const datosrepetidos = arrDatosInsert.filter(varr => varr.CodArt === slcArticulo.Code)
      if (datosrepetidos.length === 0) {
        const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
        const newId = (lastItem === undefined || lastItem === "") ? 1 : lastItem.idArt + 1;
        const newItem = { idArt: newId, CodArt: slcArticulo.Code, namArt: slcArticulo.Name };
        notify_valido("Se agrego correctamente!")
        setArrDatosInsert([...arrDatosInsert, newItem]);
        onDataUpdate('DESvendedoresexcluidos', [...arrDatosInsert, newItem])
        setFilaActiva(newId);
        setSlcArticulo([])
        setTimeout(() => {
          selectRowRef.current.focus()
        }, 500);
      } else { notify_warning('Datos selecionados ya exiten.'); }
    } else {
      if (arrCampDatos.txtCod === "" && arrCampDatos.txtDesc === "") {
        notify_warning('Campo descripción es obligatorio no debe estar vacio.');
      } else {
        // if (arrCampDatos.txtCod.trim()==="") { notify_warning('Campo codigo es obligatorio no debe estar vacio.'); }
        if (arrCampDatos.txtDesc.trim() === "") { notify_warning('Campo descripción ses obligatorio no debe estar vacio.'); }
        if (!slcArticulo.Code) { notify_warning('Codigo es obligatorio, no debe estar vacio.'); }
      }
    }
  }
  useEffect(() => {
    const lastItem = listInitData[listInitData?.length - 1]
    //console.log("lastItem",lastItem);
    setFilaActiva(lastItem?.idArt)
    setArrDatosInsert(listInitData)
  }, [listInitData])
  const onClickDeletefila = (code) => {
    onHandleVPut("1")
    const myList = [...arrDatosInsert];
    const data = arrDatosInsert.findIndex((res) => (res.CodArt === code));
    myList.splice(data, 1);
    setArrDatosInsert(myList);
    onDataUpdate('DESvendedoresexcluidos', myList)
    setSlcArticulo([])

  }
  const TRPlantilllaCD = (prosp) => {
    return (
      <tr key={prosp.datoRow.idArt} >
        <td className='text-center' >
          {onValBloqueo ? <div className="d-none"></div> :

            <AButton tp_tilde="Eliminar" disabled={onValCopyDat ? false : prosp.datoRow.estadoComprometido} bt_funct={() => onClickDeletefila(prosp.datoRow.CodArt)} tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />

          }
        </td>
        <td style={{ position: 'relative', zIndex: '2', maxWidth: '50px' }} >
          {prosp.datoRow.CodArt}</td>
        <td>{prosp.datoRow.namArt}</td>
        <td></td>
      </tr>
    )
  }

  /** Modal */
  const [generalModal, setGeneralModal] = useState(false);
  const handleOpenGeneral = () => {
    // if (arrCampDatos.txtCod!=="" && arrCampDatos.txtDesc !=="") {
    if (arrCampDatos.txtDesc !== "") {
      setGeneralModal(true);
    } else {
      if (arrCampDatos.txtCod === "" && arrCampDatos.txtDesc === "") {
        notify_warning('Campo descripción es obligatorio no debe estar vacio.');
      } else {
        // if (arrCampDatos.txtCod.trim()==="") { notify_warning('Campo codigo es obligatorio no debe estar vacio.'); }
        if (arrCampDatos.txtDesc.trim() === "") { notify_warning('Campo descripción ses obligatorio no debe estar vacio.'); }
      }
    }
  }
  const handleCloseGeneral = () => { setGeneralModal(false); }

  const handleRetrunData = (data) => {
    // console.log("data ", data );
    const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
    let newId = (lastItem === undefined || lastItem === "") ? 0 : lastItem.idArt;
    console.log(listInitData);

    if (listInitData.length > 0) {
      // Obtener los códigos que ya existen en array1
      const existingCodes = listInitData.map(item => item.CodArt);
      // Filtrar array2 para quedarnos sólo con elementos con códigos nuevos
      let lastId = listInitData[listInitData.length - 1].idArt;
      const newElements = data.filter(item => !existingCodes.includes(item.Code)).map(item => {
        lastId++;
        return {
          idArt: lastId,
          CodArt: item.Code,
          namArt: item.Name
        }
      });;
      const result = [...listInitData, ...newElements];
      //console.log("result", result);
      onDataUpdate('DESvendedoresexcluidos', [...result])
      handleCloseGeneral()
      setTimeout(() => { selectRowRef?.current?.focus() }, 1000);
    } else {
      console.log("no hay data");
      // const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
      // let newId = (lastItem === undefined || lastItem === "") ? 0 : lastItem.idArt;
      data?.map((row) => {
        newId++;
        return arrDatosInsert?.push({
          "idArt": newId,
          "CodArt": row.Code,
          "namArt": row.Name,
        })
      })
      onDataUpdate('DESvendedoresexcluidos', [...arrDatosInsert])
      handleCloseGeneral()
      setTimeout(() => { selectRowRef?.current?.focus() }, 1000);
    }
  }



  let columns = [
    { header: 'Code', accessorKey: 'Code' },
    { header: 'Name', accessorKey: 'Name' },

  ]
  return (
    <>
      {/* Hola mundo BCEInicioDetalle 
      <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger " bt_funct={probar2} ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /> */}
      <div className="data-tables table-responsive text-nowrap " style={{ position: 'relative', zIndex: '1', width: 'auto', height: '400px', overflowY: 'scroll', overflowX: 'scroll' }}>
        <table className="table table-sm" id='tab_DVendedor' style={{ fontSize: '10px', zIndex: '2', }}>
          <thead style={{ position: 'sticky', top: 0, zIndex: 10, }}>
            <tr >
              <th>Actions</th>
              <th style={{ width: '350px' }} >Code Vendedor <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger d-none" ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /></th>
              <th style={{ width: '450px' }} >Des. vendedor</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody >
            {
              (arrDatosInsert.length) ? arrDatosInsert.map((row) => (
                <React.Fragment key={row.idArt}>
                  <TRPlantilllaCD key={row.idArt} datoRow={row} />
                  {onValBloqueo ? <div className="d-none"></div> :

                    (!filaActiva || filaActiva === row.idArt) && (
                      <tr key={prosp?.datoRow?.idArt}>
                        <td className='td1'>
                        </td>
                        <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                          <AsyncSelect
                            ref={selectRowRef}
                            styles={styles}
                            placeholder="Busque por Cod./Desc."
                            name="form-field-name"
                            className="sele_pru from-control form-control-sm tbCodArticulo"
                            value={slcArticulo}
                            defaultOptions
                            getOptionLabel={e => e.Code + ' - ' + e.Name}
                            getOptionValue={e => e.Code}
                            loadOptions={funDArticulos}
                            onChange={handleSelectChange}
                            maxMenuHeight={150}
                          />
                        </td>
                        <td className='td3'>{slcArticulo.Name}</td>
                        <td className='td4'>
                          <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                          <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info" bt_funct={handleOpenGeneral} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                        </td>
                      </tr>
                    )
                  }
                </React.Fragment>
              ))
                : onValBloqueo ? <div className="d-none"></div> :

                  <tr key={prosp?.datoRow?.idArt}>
                    <td className='td1'></td>
                    <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                      <AsyncSelect
                        ref={selectRowRef}
                        styles={styles}
                        placeholder="Busque por Cod./Desc."
                        name="form-field-name"
                        className="sele_pru from-control form-control-sm tbCodArticulo"
                        value={slcArticulo}
                        defaultOptions
                        getOptionLabel={e => e.Code + ' - ' + e.Name}
                        getOptionValue={e => e.Code}
                        loadOptions={funDArticulos}
                        onChange={handleSelectChange}
                        maxMenuHeight={150}
                      />
                    </td>
                    <td className='td3'>{slcArticulo.Name}</td>
                    <td className='td4'>
                      <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                      <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info" bt_funct={handleOpenGeneral} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                    </td>
                  </tr>
            }
          </tbody>
        </table>
      </div>
      {
        generalModal && (
          <ModalDetallesBoni
            hanOpenModal={generalModal}
            hanClosModal={handleCloseGeneral}
            //listInitData = {listInitData}
            onReturnData={handleRetrunData}
            onDataEstras={{ "columns": columns, "url": '/apiListaCodeVendedores?vcodEmpresa=' + cook_idEmp }}
          />
        )
      }
    </>
  )
}

const DESTicketCompra = (prosp) => {
  const { dataid, onDataUpdate2  } = prosp
  /** Inicio :> Datos para validar */
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const cod = searchParams.get('cod')
  const tab = searchParams.get('tab');
  const tip = searchParams.get('tip');
  /** Fin :> Datos para validar */
  const [listTabs, setListTabs] = useState({
    id1: '',id2: '',id3: '',id4: '',id5: '', id6: '', id7: '', id8: '', id9: '', id10: '', id11: '', id12: '', id13: ''
  })
  const [listDatos, setListDatos] = useState({
    txtCod: '',
    txtDesc: '',
    txtActi: '',
    txtFIni: new Date(),
    txtFFin: new Date(),
    txtBode: '',
    txtMayo: '',
    txtMerc: '',
    txtMinimar: '', 
    txtTdes: '0.00',
    txtVdes: '0.00',
    txtVhas: '0.00',
    txtPres: '9999999',
    txttipi: 'N'
  })
  // *  nombre de vec 
  const [listDataUstMemori, setlistDataUstMemori] = useState({
    DESiniciodetallador: [],
    DESclientes: [],
    DESgrupoarticulo: [],
    DESvendedordetall: [],
    DESsucursaldetll: [],
    DESmesadetall: [],
    DEScategoriacliente: [],
    DESgrupoventas: [],
    DEScondicionespago: [],
    DESvendedoresexcluidos: []
  })
  const [valCopy, setValCopy] = useState(false);
  const [valBloq, setValBloq] = useState(false);
  const [disabledb1, setdisabledb1] = useState(false);
  const [disabledb2, setdisabledb2] = useState(false);
  const [btnVisible1, setBtnVisible1] = useState(false);
  const [btnVisible2, setBtnVisible2] = useState(false);
  const [validarPut, setValidarPut] = useState('0');
  const [vlExtPresu, setvlExtPresu] = useState(false);
  const handleFieldChange = (fieldName) => (event) => {
    const value = event.target.value;
    setListDatos({
      ...listDatos,
      [fieldName]: value,
    });
  };
  const handleDateChange1 = (date) => {
    setListDatos({
      ...listDatos,
      txtFIni: date, // Actualiza el valor de la fecha seleccionada
    });
  };
  const handleDateChange2 = (date) => {
    setListDatos({
      ...listDatos,
      txtFFin: date, // Actualiza el valor de la fecha seleccionada
    });
  };
  const handleKeyDown = async (e, cod) => {
    if ((e && e.key === 'Enter') || cod) {
      if (e) { e.preventDefault(); }

      Swal.fire({
        title: 'Procesando...',
        html: 'Se esta consultado datos.',
        didOpen: () => { Swal.showLoading() },
        customClass: { container: 'my-swal' },
        allowOutsideClick: false
      })
      const url = '/apiDtc'
      const body = { vcodEmpresa:cook_idEmp, vlCodigo: (cod) ? cod : listDatos.txtCod };
      const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
      const response = await fetch(url, opPe);
      const resdatos = await response.json();
      //console.log("resdatos", resdatos);
      if (resdatos.length) {
        setBtnVisible1(true)
        setListDatos({
          ...listDatos,
          //txtCod: resdatos[0].txtCod,
          txtCod: (tip === "copy") ? '' : resdatos[0].txtCod,
          txtDesc: resdatos[0].txtDesc,
          txtActi: resdatos[0].txtActi,
          txtFIni: new Date(moment(resdatos[0].txtFIni, 'Y-M-D').format('YYYY-MM-DD HH:mm:ss')),
          txtFFin: new Date(moment(resdatos[0].txtFFin, 'Y-M-D').format('YYYY-MM-DD HH:mm:ss')),
          txtBode: resdatos[0].txtBode,
          txtMayo: resdatos[0].txtMayo,
          txtMerc: resdatos[0].txtMerc,
          txtMinimar: resdatos[0].txtMinimar, 
          txtTdes: resdatos[0].txtTdes,
          txtVdes: resdatos[0].txtVdes,
          txtVhas: resdatos[0].txtVhas,
          txtPres: resdatos[0].txtPres,
          txttipi: 'N'
        });
        setlistDataUstMemori({
          ...listDataUstMemori,
          DESiniciodetallador: resdatos[0]?.arrDetalle1,
          DESclientes: resdatos[0]?.arrDetalle2,
          DESgrupoarticulo: resdatos[0]?.arrDetalle3,
          DESvendedordetall: resdatos[0]?.arrDetalle4,
          DESsucursaldetll: resdatos[0]?.arrDetalle5,
          DESmesadetall: resdatos[0]?.arrDetalle6,
          DEScategoriacliente: resdatos[0]?.arrDetalle7,
          DESgrupoventas: resdatos[0]?.arrDetalle8,
          DEScondicionespago: resdatos[0]?.arrDetalle9,
          DESvendedoresexcluidos: resdatos[0]?.arrDetalle10
        })
        setBtnVisible2(true)
        if(tip === "edit") { onClickExtenderPresupuesto(resdatos[0].txtCod) }
      } else {
        notify_warning('Codigo no existe en nuestra base de datos.');
        onClickLimpiar()
      }
      Swal.close();
    }
  };
  const handleDataFromChild = (id, data) => {
    setlistDataUstMemori({
      ...listDataUstMemori,
      [id]: data
    });
  }
  const onClickActualizar = async () => {
    setdisabledb2(true)
    const url = '/apiDtcCabecera'
    const body = { vcodEmpresa:cook_idEmp, arrCabecera: listDatos };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url, opPe);
    const resdatos = await response.json();
    if (resdatos.vl_api === 1) {
      notify_warning("Error al actualizar, verificar datos!")
    }
    if (resdatos.vl_existe === 1) {
      notify_warning("Ya esta actualizado!")
    }
    if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1) {
      notify_valido("Se actualizo correctamente!")
    }
    setdisabledb2(false)
  }
  const onclickCrearG = async () => {
    try {
      setdisabledb1(true)
      if (
          listDatos.txtDesc !== "" && listDatos.txtActi !=="" && 
          listDatos.txtBode !== "" && listDatos.txtMayo !=="" && 
          listDatos.txtMerc !== "" && listDatos.txtMinimar !== ""  &&
          (listDatos.txtTdes !== '0.00' && listDatos.txtTdes !== '0') && 
          (listDatos.txtVdes !== '0.00' && listDatos.txtVdes !== '0') && 
          (listDatos.txtVhas !== '0.00' && listDatos.txtVhas !== '0') 
        ) {
          //(listDataUstMemori.DEScategoriacliente.length >= 1) ||
        if (
          (listDataUstMemori.DESiniciodetallador.length >= 1) ||
          (listDataUstMemori.DESclientes.length >= 1) ||
          (listDataUstMemori.DESgrupoarticulo.length >= 1) ||
          (listDataUstMemori.DESvendedordetall.length >= 1) ||
          (listDataUstMemori.DESsucursaldetll.length >= 1) ||
          (listDataUstMemori.DESmesadetall.length >= 1) ||
          (listDataUstMemori.DESgrupoventas.length >= 1) ||
          (listDataUstMemori.DEScondicionespago.length >= 1)||
          (listDataUstMemori.DESvendedoresexcluidos.length >= 1)
        ) {
          //&&listDataUstMemori.DEScategoriacliente.length >= 1
          if(
              listDataUstMemori.DESsucursaldetll.length >= 1 
            ){
            const url = '/apiDtcInsert'
            const body = { vcodEmpresa: cook_idEmp, arrCabecera: listDatos, arrDetalle: listDataUstMemori, validarPut: validarPut };
            const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
            const response = await fetch(url, opPe);
            const resdatos = await response.json();
            if (resdatos.vl_api === 1) {
              notify_warning("Error al registrar, verificar datos!")
            }
            if (resdatos.vl_existe === 1) {
              notify_warning("Ya esta registrado!")
            }
            if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1) {
              notify_valido("Se registro correctamente!")
              setBtnVisible1(true)
              setBtnVisible2(true)
              if (tip === "copy") {
                setValCopy(false)
                updateParUrl('edit', tab, resdatos.vl_codres)
              } else {
                setListDatos(() => ({
                  ...listDatos,
                  txtCod: resdatos.vl_codres
                }));
              }
            }
          }else{ 
            //notify_warning('Se requiere Sucursal.'); 
            if(listDataUstMemori.DESsucursaldetll.length === 0) {notify_warning('Se requiere Sucursal.'); } 
            //if(listDataUstMemori.DEScategoriacliente.length === 0) {notify_warning('Se requiere Categoria Cliente.'); } 
          }
        } else {
          notify_warning('En niguno de los tabs hay filas agregadas, por favor agregar minimo 1 fila en un tab.');
        }
      } else {
        
        if(listDatos.txtDesc==="") { notify_warning('Campo descripción es obligatorio, no debe estar vacio.');}
        if(listDatos.txtActi==="") { notify_warning('Seleccione Activo');}
        if(listDatos.txtBode==="") { notify_warning('Seleccione Bodegas');}
        if(listDatos.txtMayo==="") { notify_warning('Seleccione Mayoritas');}
        if(listDatos.txtMerc==="") { notify_warning('Seleccione Mercados');}
        if(listDatos.txtMinimar==="") { notify_warning('Seleccione minimarket');}
        if(listDatos.txtTdes === '0.00' || listDatos.txtTdes === '0'){ notify_warning('Se requiere Tasa de Descuento.'); }
        if(listDatos.txtVdes === '0.00' || listDatos.txtVdes === '0'){ notify_warning('Se requiere Desde.'); }
        if(listDatos.txtVhas === '0.00' || listDatos.txtVhas === '0'){ notify_warning('Se requiere Hasta.'); }
        
      }
      setdisabledb1(false)
    } catch(e){
      console.log("error", e);
      notify_error('Error, comunicarse con la area de TI');
      setdisabledb1(false)
    }
  }
  const onClickLimpiar = async () => {
    setListDatos({
      txtCod: '',
      txtDesc: '',
      txtActi: '',
      txtFIni: new Date(),
      txtFFin: new Date(),
      txtBode: '',
      txtMayo: '',
      txtMerc: '',
      txtMinimar: '', 
      txtTdes: '0.00',
      txtVdes: '0.00',
      txtVhas: '0.00',
      txtPres: '9999999',
      txttipi: 'N'
    })
    setlistDataUstMemori({
      DESiniciodetallador: [],
      DESclientes: [],
      DESgrupoarticulo: [],
      DESvendedordetall: [],
      DESsucursaldetll: [],
      DESmesadetall: [],
      DEScategoriacliente: [],
      DESgrupoventas: [],
      DEScondicionespago: [],
      DESvendedoresexcluidos: []
    })

    setBtnVisible1(false)
    setBtnVisible2(false)
    notify_valido("Campos de cabecera y tablas de tab se limpiaron correctamente!")
  }
  const onClickLimpiar1 = async () => {
    setListDatos({
      txtCod: '',
      txtDesc: '',
      txtActi: '',
      txtFIni: new Date(),
      txtFFin: new Date(),
      txtBode: '',
      txtMayo: '',
      txtMerc: '',
      txtMinimar: '', 
      txtTdes: '0.00',
      txtVdes: '0.00',
      txtVhas: '0.00',
      txtPres: '9999999',
      txttipi: 'N'
    })
    setlistDataUstMemori({
      DESiniciodetallador: [],
      DESclientes: [],
      DESgrupoarticulo: [],
      DESvendedordetall: [],
      DESsucursaldetll: [],
      DESmesadetall: [],
      DEScategoriacliente: [],
      DESgrupoventas: [],
      DEScondicionespago: [],
      DESvendedoresexcluidos: []
    })

    setBtnVisible1(false)
    setBtnVisible2(false)
  }
  const updateParUrl = (etip, etab, ecod) => {
    const newParams = { tip: etip, tab: etab, cod: ecod };
    navigate({
      pathname: window.location.pathname,
      search: '?' + new URLSearchParams(newParams).toString()
    });
  }
  const onClickEnviarDatos = async() => {
    onDataUpdate2('DESdatosReglas',{'rtxtCod':listDatos?.txtCod,'rtxtDesc':listDatos?.txtDesc})
    setTimeout(()=>{
      onClickLimpiar1()
      $(".closeModal").click()
    },200)
  }
  const onClickExtenderPresupuesto = async (Cod) => {
    const url = '/apiVEPresupuesto?vcodEmpresa=' + cook_idEmp + '&tpudo=AB_DTBO&codigo=' + Cod
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const resdatos = await response.json();
    if(resdatos.Registros === "Y"){
      setValBloq(true)
      $("input").attr("disabled", true)
      $("select").attr("disabled", true)
      $("input[name=txtPres]").attr("disabled", false)
      setvlExtPresu(true)
    }
  }
  const [disabledEdit, setDisabledEdit] = useState(false)
  useEffect(() => {
    if (cod && tab === 'desticketcompra') {
      const cook_valEstado = cookies.get('boniValidarEstado');
      setTimeout(() => {
        setListDatos(() => ({
          ...listDatos,
          txtCod: cod
        }));
      }, 1000)

      if (tip === "edit") { setDisabledEdit(true) }
      if ((tip === "ver" && cook_valEstado  === "ver") || cook_valEstado  === "ver") {
        setValBloq(true)
        $("input").attr("disabled", true)
        $("select").attr("disabled", true)
      } else {
        setValBloq(false)
      }

      setTimeout(() => {
        (tip === "copy") ? setValCopy(true) : setValCopy(false)
        handleKeyDown(null, cod, tip);
      }, 2000)
    }else{ cookies.remove('boniValidarEstado'); }


    /** Mostrar datos en modal */
    if (dataid) {
      onClickLimpiar1()

      if (dataid.codigo!=="") {
        setTimeout(() => {
          setListDatos(() => ({
            ...listDatos,
            txtCod: dataid.codigo,
            txtDesc: ''
          }));
        }, 1000)
        setTimeout(() => {
          handleKeyDown(null,dataid.codigo,'');
        }, 2000)
        
      }else{
        onClickLimpiar1()
        setBtnVisible1(false)
      }
      setListTabs(dataid)
    }

  }, [cod, tab, tip, dataid])
  const [listSubTabsVisu, setListSubTabsVisu] = useState({ id1: false, id2: false, id3: false, id4: false, id5: false, id6: false, id7: false, id8: false, id9: false, id10: false })
  const clickSubTab = (valor) => {
    setListSubTabsVisu({
      id1: (valor === '1') ? true : false,
      id2: (valor === '2') ? true : false,
      id3: (valor === '3') ? true : false,
      id4: (valor === '4') ? true : false,
      id5: (valor === '5') ? true : false,
      id6: (valor === '6') ? true : false,
      id7: (valor === '7') ? true : false,
      id8: (valor === '8') ? true : false,
      id9: (valor === '9') ? true : false,
      id10: (valor === '10') ? true : false
    })
  }
  const onHandleVPut = (dato) => {
    setValidarPut(dato)
  }

  /** Inicio :> Modal de consulta */
  const [dtEPorE, setDtEPorE] = useState([]);
  const onclickMostrarEntePorEntre = async() => {
    const url = '/apiEntrePorEntre?vcodEmpresa=' + cook_idEmp + '&tpudo=BUU&codigo=' + listDatos.txtCod
    const opPe9 = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const resp = await fetch(url, opPe9);
    const data = await resp.json();
    setDtEPorE(data)
  }
  const [generalModalUni, setGeneralModalUni] = useState(false);
  const handleOpen = async () => {
    onclickMostrarEntePorEntre()
    setGeneralModalUni(true);
  }
  const handleClose = () => {
    setGeneralModalUni(false);
  }
  /** Fin :> Modal de consulta */
  return (
<>
      <div className="row mb">
        <div className="col-md-1">Codigo:</div>
        <div className="col-md-3">
          <input type="text" id="txtCod" name="txtCod" className="form-control form-control-sm" autoComplete='off' value={listDatos.txtCod} onChange={handleFieldChange('txtCod')} onKeyDown={handleKeyDown} disabled/>
        </div>
        <div className="col-md-1">D.Desc:</div>
        <div className="col-md-7">
          <input type="text" id="txtDesc" name="txtDesc" className="form-control form-control-sm" autoComplete='off' value={listDatos.txtDesc} onChange={handleFieldChange('txtDesc')} />
        </div>
      </div>
      <div className="row mb">
        <div className="col-md-1">Inicio:</div>
        <div className="col-md-3" style={{ position: 'relative', zIndex: '2' }}>
          <DatePicker
            id="txtFIni"
            locale="es"
            wrapperClassName='input_date'
            className="form-control form-control-sm text-center"
            selected={listDatos.txtFIni}
            onChange={handleDateChange1}
            dateFormat="yyyy-MM-dd"
          />  
          {/* <input type="text" id="txtFIni" name="txtFIni" className="form-control form-control-sm"  autoComplete='off' value={listDatos.txtFIni} onChange={handleFieldChange('txtFIni')}/> */}
        </div>
        <div className="col-md-1">Fin:</div>
        <div className="col-md-3" style={{ position: 'relative', zIndex: '2' }}>
          <DatePicker
            id="txtFFin"
            locale="es"
            wrapperClassName='input_date'
            className="form-control form-control-sm text-center"
            selected={listDatos.txtFFin}
            onChange={handleDateChange2}
            dateFormat="yyyy-MM-dd"
          />
           {/* <input type="text" id="txtFFin" name="txtFFin" className="form-control form-control-sm"  autoComplete='off' value={listDatos.txtFFin} onChange={handleFieldChange('txtFFin')}/> */}
        </div>
        <div className="col-md-1">Activo:</div>
        <div className="col-md-3">
          <select id="txtActi" name="txtActi" className="form-select form-select-sm" value={listDatos.txtActi} onChange={handleFieldChange('txtActi')} disabled={disabledEdit}>
            <option value="">{"<<Selecciona>>"}</option>
            <option value="Y">Si</option>
            <option value="N">No</option>
          </select>
        </div>
      </div>
      <div className="row mb">
        <div className="col-md-1">Bodega:</div>
        <div className="col-md-3">
          <select id="txtBode" name="txtBode" className="form-select form-select-sm" value={listDatos.txtBode} onChange={handleFieldChange('txtBode')}>
            <option value="">{"<<Selecciona>>"}</option>
            <option value="Y">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-md-1">Mayorista:</div>
        <div className="col-md-3">
          <select id="txtMayo" name="txtMayo" className="form-select form-select-sm" value={listDatos.txtMayo} onChange={handleFieldChange('txtMayo')}>
            <option value="">{"<<Selecciona>>"}</option>
            <option value="Y">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-md-1">Mercados:</div>
        <div className="col-md-3">
          <select id="txtMerc" name="txtMerc" className="form-select form-select-sm" value={listDatos.txtMerc} onChange={handleFieldChange('txtMerc')}>
            <option value="">{"<<Selecciona>>"}</option>
            <option value="Y">Si</option>
            <option value="N">No</option>
          </select>
        </div>
      </div>
      <div className="row mb">
        <div className="col-md-1">Minimarket:</div>
        <div className="col-md-3">
          <select id="txtMinimar" name="txtMinimar" className="form-select form-select-sm" value={listDatos.txtMinimar} onChange={handleFieldChange('txtMinimar')}>
            <option value="">{"<<Selecciona>>"}</option>
            <option value="Y">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-md-1">TasaDe.:</div>
        <div className="col-md-3">
          <input type="number" id="txtTdes" name="txtTdes" className="form-control form-control-sm" autoComplete='off' value={listDatos.txtTdes} onChange={handleFieldChange('txtTdes')} />
        </div>
        <div className="col-md-1">Presup.:</div>
        <div className="col-md-3">
          <input type="number" id="txtPres" name="txtPres" className="form-control form-control-sm" autoComplete='off' value={listDatos.txtPres} onChange={handleFieldChange('txtPres')} />
        </div>
      </div>
      <div className="row mb-1">
        <div className="col-md-1">V.Desde:</div>
        <div className="col-md-3">
          <input type="number" id="txtVdes" name="txtVdes" className="form-control form-control-sm" autoComplete='off' value={listDatos.txtVdes} onChange={handleFieldChange('txtVdes')} />
        </div>
        <div className="col-md-1">V.Hasta:</div>
        <div className="col-md-3">
          <input type="number" id="txtVhas" name="txtVhas" className="form-control form-control-sm" autoComplete='off' value={listDatos.txtVhas} onChange={handleFieldChange('txtVhas')} />
        </div>
      </div>
      <div className="row mb">
        <div className="col-md-8 offset-md-4 text-end">
          {valBloq ? 
            vlExtPresu ?
              <AButton tp_tilde="Aumentar Presupuesto" bt_descr="Aumentar Presupuesto" tp_posit="top" bt_class="btn btn-sm btn-label-success" bt_funct={onClickActualizar} ic_class="bx bx-ms bx-send" vl_tpbtn={2} disabled={disabledb2} />
            : <div className="d-none"></div>
          :
            <>
              {btnVisible2 && (
                <AButton id="btn_actualizar" tp_tilde="Actualizar Cabecera" bt_descr="Actualizar Cabecera" tp_posit="top" bt_class="btn btn-sm btn-label-success" bt_funct={onClickActualizar} ic_class="bx bx-ms bx-send" vl_tpbtn={2} disabled={disabledb2} />
              )}
              <AButton id="btn_LimpiarTod" tp_tilde="Limpiar campos y tablas" bt_descr="Limpiar" tp_posit="top" bt_class="btn btn-sm btn-label-secondary" bt_funct={onClickLimpiar} ic_class="bx bx-ms bx-eraser" vl_tpbtn={1} />
              {btnVisible2 && (
                <AButton id="btn_ModalEPorE" tp_tilde="Consultar entregado y por entregar" tp_posit="top" bt_class="btn btn-sm btn-label-info" bt_funct={handleOpen} ic_class="bx bx-ms bx-bong" vl_tpbtn={1} />
              )}
            </>
          }
        </div>
      </div>

      {
        generalModalUni && (
          <ModalBEPorE
            hanOpenModal={generalModalUni}
            hanClosModal={handleClose}
            onDataEstras={dtEPorE}
          />)
      }
      <div className="row ">
        <div className="nav-align-top">
          <ul className="nav nav-pills " role="tablist">
            <li className="nav-item me-1">
              <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4 active" role="tab" data-bs-toggle="tab" data-bs-target={"#DTCiniciodetallador"+listTabs.id1} aria-controls="DTCiniciodetallador" aria-selected="true"><i className="tf-icons bx bx-run bx-xs" /> DTC Inicio </button>
            </li>
            <li className="nav-item me-1">
              <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4" role="tab" data-bs-toggle="tab" data-bs-target={"#DTCclientedetalle"+listTabs.id2} aria-controls="DTCclientedetalle" aria-selected="false" onClick={() => { clickSubTab('2') }}><i className="tf-icons bx bx-face bx-xs" /> DTC Cliente </button>
            </li>
            <li className="nav-item me-1">
              <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4" role="tab" data-bs-toggle="tab" data-bs-target={"#DTCgrupoarticulo"+listTabs.id3} aria-controls="DTCgrupoarticulo" aria-selected="false" onClick={() => { clickSubTab('3') }}><i className="tf-icons bx bx-category bx-xs" /> DTC Grupo de Articulos </button>
            </li>
            <li className="nav-item me-1">
              <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4" role="tab" data-bs-toggle="tab" data-bs-target={"#DTCvendedordetall"+listTabs.id4} aria-controls="DTCvendedordetall" aria-selected="false" onClick={() => { clickSubTab('4') }}><i className="tf-icons bx bx-user bx-xs" /> DTC Vendedor </button>
            </li>
            <li className="nav-item me-1">
              <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4" role="tab" data-bs-toggle="tab" data-bs-target={"#DTCsucursaldetll"+listTabs.id5} aria-controls="DTCsucursaldetll" aria-selected="false" onClick={() => { clickSubTab('5') }}><i className="tf-icons bx bx-store-alt bx-xs" /> DTC Sucursal </button>
            </li>
            <li className="nav-item me-1">
              <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4" role="tab" data-bs-toggle="tab" data-bs-target={"#DTCmesadetall"+listTabs.id6} aria-controls="DTCmesadetall" aria-selected="false" onClick={() => { clickSubTab('6') }}><i className="tf-icons bx bxs-rename bx-xs" /> DTC Mesa </button>
            </li>
            {/* <li className="nav-item me-1">
              <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4" role="tab" data-bs-toggle="tab" data-bs-target={"#DTCcategoriacliente"+listTabs.id7} aria-controls="DTCcategoriacliente" aria-selected="false" onClick={() => { clickSubTab('7') }}><i className="tf-icons bx bxs-rename bx-xs" /> DTC Cat. Cliente </button>
            </li> */}
            <li className="nav-item me-1">
              <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4" role="tab" data-bs-toggle="tab" data-bs-target={"#DTCgrupoventas"+listTabs.id8} aria-controls="DTCgrupoventas" aria-selected="false" onClick={() => { clickSubTab('8') }}><i className="tf-icons bx bxs-rename bx-xs" /> DTC Gru. Ventas </button>
            </li>
            <li className="nav-item me-1">
              <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4" role="tab" data-bs-toggle="tab" data-bs-target={"#DTCcondicionespago"+listTabs.id9} aria-controls="DTCcondicionespago" aria-selected="false" onClick={() => { clickSubTab('9') }}><i className="tf-icons bx bxs-rename bx-xs" /> DTC Cond. Pago </button>
            </li>
            <li className="nav-item me-1">
              <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4" role="tab" data-bs-toggle="tab" data-bs-target={"#DTCvendedoresexcluidos"+listTabs.id10} aria-controls="DESvendedoresexcluidos" aria-selected="false" onClick={() => { clickSubTab('10') }}><i className="tf-icons bx bxs-rename bx-xs" /> DTC Vendedores Ex. </button>
            </li>
          </ul>
          <div className="tab-content">

            <div className="tab-pane fade show active" id={"DTCiniciodetallador"+listTabs.id1} role="tabpanel">
              <DESInicioDetalle
                arrCampDatos={listDatos}
                onDataUpdate={handleDataFromChild}
                listInitData={listDataUstMemori.DESiniciodetallador}
                onHandleVPut={onHandleVPut}
                onValCopyDat={valCopy}
                onValBloqueo={valBloq}
              />
            </div>
            <div className="tab-pane fade" id={"DTCclientedetalle"+listTabs.id2} role="tabpanel">
              {
                (listSubTabsVisu.id2) && (
                  <DESClienteDetalle
                    arrCampDatos={listDatos}
                    onDataUpdate={handleDataFromChild}
                    listInitData={listDataUstMemori.DESclientes}
                    onHandleVPut={onHandleVPut}
                    onValCopyDat={valCopy}
                    onValBloqueo={valBloq}
                  />
                )
              }
            </div>
            <div className="tab-pane fade" id={"DTCgrupoarticulo"+listTabs.id3} role="tabpanel">
              {
                (listSubTabsVisu.id3) && (
                  <DESGrupoArticulosDetallados
                    arrCampDatos={listDatos}
                    onDataUpdate={handleDataFromChild}
                    listInitData={listDataUstMemori.DESgrupoarticulo}
                    onHandleVPut={onHandleVPut}
                    onValCopyDat={valCopy}
                    onValBloqueo={valBloq}
                  />
                )
              }
            </div>
            <div className="tab-pane fade" id={"DTCvendedordetall"+listTabs.id4} role="tabpanel">
              {
                (listSubTabsVisu.id4) && (
                  <DESVendedorDetallador
                    arrCampDatos={listDatos}
                    onDataUpdate={handleDataFromChild}
                    listInitData={listDataUstMemori.DESvendedordetall}
                    onHandleVPut={onHandleVPut}
                    onValCopyDat={valCopy}
                    onValBloqueo={valBloq}
                  />
                )
              }
            </div>
            <div className="tab-pane fade" id={"DTCsucursaldetll"+listTabs.id5} role="tabpanel">
              {
                (listSubTabsVisu.id5) && (
                  <DESSucursalDetallado
                    arrCampDatos={listDatos}
                    onDataUpdate={handleDataFromChild}
                    listInitData={listDataUstMemori.DESsucursaldetll}
                    onHandleVPut={onHandleVPut}
                    onValCopyDat={valCopy}
                    onValBloqueo={valBloq}
                  />
                )
              }
            </div>
            <div className="tab-pane fade" id={"DTCmesadetall"+listTabs.id6} role="tabpanel">
              {
                (listSubTabsVisu.id6) && (
                  <DESMesaDetalle
                    arrCampDatos={listDatos}
                    onDataUpdate={handleDataFromChild}
                    listInitData={listDataUstMemori.DESmesadetall}
                    onHandleVPut={onHandleVPut}
                    onValCopyDat={valCopy}
                    onValBloqueo={valBloq}
                  />
                )
              }
            </div>
            {/* <div className="tab-pane fade" id={"DTCcategoriacliente"+listTabs.id7} role="tabpanel">
              {
                (listSubTabsVisu.id7) && (
                  <DESCategoriacliente
                    arrCampDatos={listDatos}
                    onDataUpdate={handleDataFromChild}
                    listInitData={listDataUstMemori.DEScategoriacliente}
                    onHandleVPut={onHandleVPut}
                    onValCopyDat={valCopy}
                    onValBloqueo={valBloq}
                  />
                )
              }
            </div> */}
            <div className="tab-pane fade" id={"DTCgrupoventas"+listTabs.id8} role="tabpanel">
              {
                (listSubTabsVisu.id8) && (
                  <DESgrupoventas
                    arrCampDatos={listDatos}
                    onDataUpdate={handleDataFromChild}
                    listInitData={listDataUstMemori.DESgrupoventas}
                    onHandleVPut={onHandleVPut}
                    onValCopyDat={valCopy}
                    onValBloqueo={valBloq}
                  />
                )
              }
            </div>
            <div className="tab-pane fade" id={"DTCcondicionespago"+listTabs.id9} role="tabpanel">
              {
                (listSubTabsVisu.id9) && (
                  <DEScondicionespago
                    arrCampDatos={listDatos}
                    onDataUpdate={handleDataFromChild}
                    listInitData={listDataUstMemori.DEScondicionespago}
                    onHandleVPut={onHandleVPut}
                    onValCopyDat={valCopy}
                    onValBloqueo={valBloq}
                  />
                )
              }
            </div>
            <div className="tab-pane fade" id={"DTCvendedoresexcluidos"+listTabs.id10} role="tabpanel">
              {
                (listSubTabsVisu.id10) && (
                  <DESVendedoresExcluidos
                    arrCampDatos={listDatos}
                    onDataUpdate={handleDataFromChild}
                    listInitData={listDataUstMemori.DESvendedoresexcluidos}
                    onHandleVPut={onHandleVPut}
                    onValCopyDat={valCopy}
                    onValBloqueo={valBloq}
                  />
                )
              }
            </div>
          </div>
        </div>
      </div>
      <div className="row ">
        <div className="col-lg-4"></div>
        <div className="col-lg-8 text-end">
        { valBloq ? <div className="d-none"></div> : 
            btnVisible1 ?
            valCopy ?
              <AButton tp_tilde="Copy" bt_descr="Copy" tp_posit="top" bt_class="btn btn-sm btn-label-warning " ic_class="bx bx-ms bx-copy" vl_tpbtn={2} bt_funct={onclickCrearG} disabled={disabledb1} />
              :
              <>
              <AButton tp_tilde="Guardar nuevos registros" bt_descr="Guardar" tp_posit="top" bt_class="btn btn-sm btn-label-primary " ic_class="bx bx-ms bx-send" vl_tpbtn={2} bt_funct={onclickCrearG} disabled={disabledb1} />
              { dataid?.codigo === "" && (
                  <AButton tp_tilde="Agregar a fila" bt_descr="Agregar a fila" tp_posit="top" bt_class="btn btn-sm btn-label-warning " ic_class="bx bx-ms bx-send" vl_tpbtn={2} bt_funct={onClickEnviarDatos} />
                )
              }
              </>
            :
            <AButton tp_tilde="Crear" bt_descr="Crear" tp_posit="top" bt_class="btn btn-sm btn-label-primary " ic_class="bx bx-ms bx-send" vl_tpbtn={2} bt_funct={onclickCrearG} disabled={disabledb1} />
          }
        </div>
      </div>
    </>
  )
}

export default DESTicketCompra