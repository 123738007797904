import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { notify_warning, notify_valido, notify_error } from '../../../components/utils';
import { AButton } from '../../../components/Button';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import './BON.css'
import Swal from 'sweetalert2';
import AsyncSelect from 'react-select/async';
import $ from 'jquery'
import Cookies from 'universal-cookie';
import { ModalDetallesB, ModalDetallesBoni, ModalDetallesBoniPar,ModalDetallesBoniParStock, ModalBEPorE } from '../../../components/Modals/ModalsDetVen';
import * as XLSX from 'xlsx'
import Paginate  from 'react-paginate';
const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id')
const moment = require('moment')
const globalTheme = window.templateCustomizer.settings.style;

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.default',
//   border: '1px #000',
//   boxShadow: 24,
//   pt: 2,
//   px: 4,
//   pb: 3,
// };
// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '1px #000',
//   boxShadow: 24,
//   pt: 2,
//   px: 4,
//   pb: 3,
// };
const styles = {

  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? null : null,
    backgroundColor: globalTheme === 'light'
      ? '#FEFFF4'
      : '#2B2C40',
    color: globalTheme === 'light'
      ? '#2B2C40'
      : '#FEFFF4',
    minHeight: `29px`,
    height: `29px`,
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40')
      : (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40'),
    color: state.isSelected
      ? '#FF3E1D'
      : (globalTheme === 'light' ? '#2B2C40' : '#FEFFF4'),
    '&:hover': {
      backgroundColor: '#FF3E1D',
      color: 'white',
    },
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    backgroundColor: globalTheme === 'light' ? '#fefff4' : '#2B2C40',
    color: globalTheme === 'light' ? '#2B2C40' : '#fefff4',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
    padding: `0 6px`,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
  }),
  input: (provided, state) => ({
    ...provided,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
    fontSize: `0.75rem`,
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
  }),
};


export const BTCInicioDetalle = (prosp) => {
  const { arrCampDatos, listInitData, onDataUpdate, onHandleVPut, onValCopyDat, onValBloqueo } = prosp
  const [arrDatosInsert, setArrDatosInsert] = useState([]);
  const [filaActiva, setFilaActiva] = useState(null);
  const [slcArticulo, setSlcArticulo] = useState([""]);
  const selectRowRef = useRef(null);

  const funDArticulos = useCallback(async (inputValue) => {
    const url = '/apiArticulos?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }, [])
  const handleSelectChange = (selectedOption) => { setSlcArticulo(selectedOption); };
  const onClickInsert = async () => {
    // if (arrCampDatos.txtCod!=="" && arrCampDatos.txtDBon !=="" && slcArticulo.Code !== undefined) {      
    if (arrCampDatos.txtDBon !== "" && slcArticulo.Code !== undefined) {
      const datosrepetidos = arrDatosInsert.filter(varr => varr.CodArt === slcArticulo.Code)
      if (datosrepetidos.length === 0) {
        const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
        const newId = (lastItem === undefined || lastItem === "") ? 1 : lastItem.idArt + 1;
        const newItem = { idArt: newId, CodArt: slcArticulo.Code, namArt: slcArticulo.Name, uniArt: slcArticulo.Unidad };
        notify_valido("Se registro correctamente!")
        setArrDatosInsert([...arrDatosInsert, newItem]);
        onDataUpdate('bceiniciodetallador', [...arrDatosInsert, newItem])
        setFilaActiva(newId);
        setSlcArticulo([])
        setTimeout(() => { selectRowRef.current.focus() }, 500);
      } else { notify_warning('Datos selecionados ya exiten.'); }
    } else {
      // if (arrCampDatos.txtCod==="" && arrCampDatos.txtDBon==="") {
      if (arrCampDatos.txtDBon === "") {
        notify_warning('Campo descripción es obligatorio, no debe estar vacio.');
      } else {
        // if (arrCampDatos.txtCod.trim()==="") { notify_warning('Campo codigo es obligatorio no debe estar vacio.'); }
        if (arrCampDatos.txtDBon.trim() === "") { notify_warning('Campo descripción son obligatorio no debe estar vacio.'); }
        if (!slcArticulo.Code) { notify_warning('Codigo es obligatorio, no debe estar vacio.'); }
      }
    }
  }
  useEffect(() => {
    const lastItem = listInitData[listInitData?.length - 1];
    setFilaActiva(lastItem?.idArt)
    setArrDatosInsert(listInitData)
  }, [listInitData])

  const onClickDeletefila = (code) => {
    onHandleVPut("1")
    const myList = [...arrDatosInsert];
    const data = arrDatosInsert.findIndex((res) => (res.CodArt === code));
    myList.splice(data, 1);
    setArrDatosInsert(myList);
    console.log(arrDatosInsert);
    onDataUpdate('bceiniciodetallador', myList)
    setSlcArticulo([])

  }

  const TRPlantilllaCD = (prosp) => {
    return (
      <tr key={prosp.datoRow.idArt} >
        <td className='text-center' >
          {/* <AButton tp_tilde="Editar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-primary" ic_class="bx bx-ms bx-edit" vl_tpbtn={1} /> */}
          {onValBloqueo ? <div className="d-none"></div> :
            <AButton tp_tilde="Eliminar" disabled={onValCopyDat ? false : prosp.datoRow.estadoComprometido} bt_funct={() => onClickDeletefila(prosp.datoRow.CodArt)} tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger " ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />
          }

        </td>
        <td style={{ position: 'relative', zIndex: '2', maxWidth: '50px' }} >{prosp.datoRow.CodArt}</td>
        <td>{prosp.datoRow.namArt}</td>
        <td>{prosp.datoRow.uniArt}</td>
        <td>
          <AButton tp_tilde="Ver Unidades" tp_posit="right" bt_class="btn btn-sm btn-icon btn-label-warning" bt_funct={() => handleOpenGeneralUni2(prosp.datoRow.CodArt)} ic_class="bx bx-ms bx-cube" vl_tpbtn={1} />
        </td>
      </tr>
    )
  }

  /** inicio:> Modal */
  const [generalModal, setGeneralModal] = useState(false);
  const handleOpenGeneral = () => {
    //if (arrCampDatos.txtCod!=="" && arrCampDatos.txtDBon !=="") { 
    if (arrCampDatos.txtDBon !== "") {
      setGeneralModal(true);
    } else {
      //if (arrCampDatos.txtCod==="" && arrCampDatos.txtDBon==="") {
      if (arrCampDatos.txtDBon === "") {
        notify_warning('Campo descripción es obligatorio, no debe estar vacio.');
      } else {
        //if (arrCampDatos.txtCod.trim()==="") { notify_warning('Campo codigo es obligatorio no debe estar vacio.'); }
        if (arrCampDatos.txtDBon.trim() === "") { notify_warning('Campo descripción son obligatorio no debe estar vacio.'); }
      }
    }
  }
  const handleCloseGeneral = () => {
    setGeneralModal(false);
  }
  const handleRetrunData = (data) => {
    // console.log("data ", data );
    const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
    let newId = (lastItem === undefined || lastItem === "") ? 0 : lastItem.idArt;
    if (listInitData.length > 0) {
      // Obtener los códigos que ya existen en array1
      const existingCodes = listInitData.map(item => item.CodArt);
      // Filtrar array2 para quedarnos sólo con elementos con códigos nuevos
      let lastId = listInitData[listInitData.length - 1].idArt;
      const newElements = data.filter(item => !existingCodes.includes(item.Code)).map(item => {
        lastId++;
        return {
          idArt: lastId,
          CodArt: item.Code,
          namArt: item.Name,
          uniArt: item.Unidad
        }
      });
      const result = [...listInitData, ...newElements];
      //console.log("result", result);
      onDataUpdate('bceiniciodetallador', [...result])
      handleCloseGeneral()
      setTimeout(() => { selectRowRef?.current?.focus() }, 1000);
    } else {
      console.log("no hay data");
      // const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
      // let newId = (lastItem === undefined || lastItem === "") ? 0 : lastItem.idArt;
      data?.map((row) => {
        newId++;
        return arrDatosInsert?.push({
          "idArt": newId,
          "CodArt": row.Code,
          "namArt": row.Name,
          "uniArt": row.Unidad
        })
      })
      onDataUpdate('bceiniciodetallador', [...arrDatosInsert])
      handleCloseGeneral()
      setTimeout(() => { selectRowRef?.current?.focus() }, 1000);
    }
  }
  let columns = [
    { header: 'Code', accessorKey: 'Code' },
    { header: 'Name', accessorKey: 'Name' },
    { header: 'Unidad', accessorKey: 'Unidad' },
    { header: 'Categoria', accessorKey: 'Categoria' },
    { header: 'CodEAN', accessorKey: 'CodEAN' }
  ]
  /** fin:> Modal */
  /** inicio:> Modal Unidades de articulos */
  const [generalModalUni, setGeneralModalUni] = useState(false);
  const [codExtrModalUni, setCodExtrModalUni] = useState('');
  const handleOpenGeneralUni = () => {
    if (slcArticulo.Code && arrCampDatos.txtDBon !== "") {
      setGeneralModalUni(true);
    } else {
      //if (arrCampDatos.txtCod==="" && arrCampDatos.txtDBon==="") {
      if (arrCampDatos.txtDBon === "") {
        notify_warning('Campo descripción es obligatorio, no debe estar vacio.');
      } else {
        //if (arrCampDatos.txtCod.trim()==="") { notify_warning('Campo codigo es obligatorio no debe estar vacio.'); }
        if (arrCampDatos.txtDBon.trim() === "") { notify_warning('Campo descripción son obligatorio no debe estar vacio.'); }
        if (!slcArticulo.Code) { notify_warning('Campo codigo articulo no debe estar vacio.'); }
      }
    }
  }
  const handleOpenGeneralUni2 = async (Code) => {
    setCodExtrModalUni(Code)
    setGeneralModalUni(true);
  }
  const handleCloseGeneralUni = () => {
    setGeneralModalUni(false);
  }
  let columnsUni = [
    { header: 'Code', accessorKey: 'Code' },
    { header: 'Name', accessorKey: 'Name' },
    { header: 'Unidad', accessorKey: 'UomCode' },
    { header: 'Base', accessorKey: 'BaseQty' }
  ]
  /** fin:> Modal Unidades de articulos */

  return (
    <>
      <div className="data-tables table-responsive text-nowrap " style={{ position: 'relative', zIndex: '1', width: 'auto', height: '400px', overflowY: 'scroll', overflowX: 'scroll' }}>
        <table className="table table-sm" id='tab_BCEInicioDetalleT' style={{ fontSize: '10px', zIndex: '2', }}>
          <thead style={{ position: 'sticky', top: 0, zIndex: 10 }}>
            <tr >
              <th>Actions</th>
              <th style={{ width: '350px' }} >Code Articulo  <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger d-none" ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /></th>
              <th style={{ width: '450px' }} >Des. Articulo</th>
              <th>Unidad</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            {
              (arrDatosInsert?.length) ? arrDatosInsert?.map((row) => (
                <React.Fragment key={row.idArt}>
                  <TRPlantilllaCD key={row.idArt} datoRow={row} />
                  {onValBloqueo ? <div className="d-none"></div> :
                    (!filaActiva || filaActiva === row.idArt) && (
                      <tr key={prosp?.datoRow?.idArt}>
                        <td className='td1'></td>
                        <td className='td2' style={{ position: 'relative', zIndex: '3' }} >
                          <AsyncSelect
                            ref={selectRowRef}
                            styles={styles}
                            placeholder="Busque por Cod./Desc."
                            name="form-field-name"
                            className=" from-control form-control-sm"
                            value={slcArticulo}
                            cacheOptions
                            defaultOptions
                            getOptionLabel={e => e.Code + ' - ' + e.Name}
                            getOptionValue={e => e.Code}
                            loadOptions={funDArticulos}
                            onChange={handleSelectChange}
                            maxMenuHeight={150}
                          />

                        </td>
                        <td className='td3'>{slcArticulo.Name}</td>
                        <td className='td4'>{slcArticulo.Unidad}</td>
                        <td className='td5'>
                          <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                          <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info" bt_funct={handleOpenGeneral} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                        </td>
                      </tr>
                    )
                  }
                </React.Fragment>
              ))
                : onValBloqueo ? <div className="d-none"></div> :
                  <tr key={prosp?.datoRow?.idArt}>
                    <td className='td1'></td>
                    <td className='td2' style={{ position: 'relative', zIndex: '3' }} >
                      <AsyncSelect
                        styles={styles}
                        placeholder="Busque por Cod./Desc."
                        name="form-field-name"
                        className=" from-control form-control-sm"
                        value={slcArticulo}
                        cacheOptions
                        defaultOptions
                        getOptionLabel={e => e.Code + ' - ' + e.Name}
                        getOptionValue={e => e.Code}
                        loadOptions={funDArticulos}
                        onChange={handleSelectChange}
                        maxMenuHeight={150}
                      />

                    </td>
                    <td className='td3'>{slcArticulo.Name}</td>
                    <td className='td4'>{slcArticulo.Unidad}</td>
                    <td className='td5'>
                      <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                      <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info" bt_funct={handleOpenGeneral} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                    </td>
                  </tr>
            }
          </tbody>
        </table>
      </div>
      {
        generalModal && (
          <ModalDetallesBoniPar
            hanOpenModal={generalModal}
            hanClosModal={handleCloseGeneral}
            onReturnData={handleRetrunData}
            onDataEstras={{ "columns": columns, "url": '/apiParArticulos?vcodEmpresa=' + cook_idEmp }}
          />
        )
      }
      {
        generalModalUni && (
          <ModalDetallesB
            hanOpenModal={generalModalUni}
            hanClosModal={handleCloseGeneralUni}
            onDataEstras={{ "columns": columnsUni, "url": '/apiUnidadArticulos?vcodEmpresa=' + cook_idEmp + '&CodArt=' + codExtrModalUni }}
          />
        )
      }
    </>
  )
}
export const BTCClienteDetalle = (prosp) => {
  const { arrCampDatos, listInitData, onDataUpdate, onHandleVPut, onValCopyDat, onValBloqueo, validarLData } = prosp
  const [arrDatosInsert, setArrDatosInsert] = useState([]);
  const [filaActiva, setFilaActiva] = useState(null);
  const [slcArticulo, setSlcArticulo] = useState([""]);
  const funDArticulos = useCallback(async (inputValue) => {
    const url = '/apiClientes?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }, [])
  const handleSelectChange = (selectedOption) => { setSlcArticulo(selectedOption); };
  const onClickInsert = async () => {
    // if (arrCampDatos.txtCod!=="" && arrCampDatos.txtDBon !=="" && slcArticulo.Code !== undefined) {      
    if (arrCampDatos.txtDBon !== "" && slcArticulo.Code !== undefined) {
      const datosrepetidos = arrDatosInsert.filter(varr => varr.CodArt === slcArticulo.Code)
      if (datosrepetidos.length === 0) {
        const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
        const newId = (lastItem === undefined || lastItem === "") ? 1 : lastItem.idArt + 1;
        // const newId = (lastItem === undefined || lastItem === "") ? 2 : lastItem.idArt + 1;
        const newItem = { idArt: newId, CodArt: slcArticulo.Code, namArt: slcArticulo.Name };
        notify_valido("Se agrego correctamente!")
        setArrDatosInsert([...arrDatosInsert, newItem]);
        onDataUpdate('bcecliente', [...arrDatosInsert, newItem])
        setFilaActiva(newId);
        setSlcArticulo([])
      } else { notify_warning('Datos selecionados ya exiten.'); }
    } else {
      // if (arrCampDatos.txtCod==="" && arrCampDatos.txtDBon==="") {
      if (arrCampDatos.txtDBon === "") {
        notify_warning('Campo descripción es obligatorio, no debe estar vacio.');
      } else {
        // if (arrCampDatos.txtCod.trim()==="") { notify_warning('Campo codigo es obligatorio no debe estar vacio.'); }
        if (arrCampDatos.txtDBon.trim() === "") { notify_warning('Campo descripción son obligatorio no debe estar vacio.'); }
        if (!slcArticulo.Code) { notify_warning('Codigo es obligatorio, no debe estar vacio.'); }
      }
    }
  }
  useEffect(() => {
    const lastItem = listInitData[listInitData?.length - 1];
    setFilaActiva(lastItem?.idArt)
    setArrDatosInsert(listInitData)
  }, [listInitData])

  const onClickDeletefila = (code) => {
    onHandleVPut("1")
    const myList = [...arrDatosInsert];
    const data = arrDatosInsert.findIndex((res) => (res.CodArt === code));
    myList.splice(data, 1);
    setArrDatosInsert(myList);
    console.log(arrDatosInsert);
    onDataUpdate('bcecliente', myList)
    setSlcArticulo([])

  }

  const TRPlantilllaCD = (prosp) => {
    return (
      <tr key={prosp.datoRow.idArt} >
        <td className='text-center' >
          {onValBloqueo ? <div className="d-none"></div> :
            <AButton tp_tilde="Eliminar" disabled={onValCopyDat ? false : prosp.datoRow.estadoComprometido} bt_funct={() => onClickDeletefila(prosp.datoRow.CodArt)} tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />

          }
        </td>
        <td style={{ position: 'relative', zIndex: '2', maxWidth: '50px' }} >
          {prosp.datoRow.CodArt}</td>
        <td>{prosp.datoRow.namArt}</td>
        <td></td>
      </tr>
    )
  }

  /** Snaiper */
  const [interBloq, setInterBloq] = useState(false);
  const [interCoun, setInterCoun] = useState(0);
  const onClickSubirArchivo = () => {
    if ( arrCampDatos.txtDBon !=="" ) {
      if (validarLData.length >= 1){
        setInterBloq(true)
        cookies.set('interBloq', 'true', { path: '/' });
        const input = document.createElement('input');
        input.type = 'file';
        input.onchange =  async(e) => {
          const file = e.target.files[0];
          const reader = new FileReader();
          reader.onload = async(evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, {type:'binary'});
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            let data = XLSX.utils.sheet_to_json(ws, {header:1});
            let newId = 0;
          

            if (listInitData.length > 0 ) {
              console.log("entra si hay datos");
              let lastId = listInitData[listInitData.length - 1].idArt;
              data?.map(async(row) => {
                  lastId++;
                  return arrDatosInsert?.push({
                    idArt: lastId, 
                    CodArt: row[0], 
                    namArt: '-'
                  })
                  
                })
                onDataUpdate('bcecliente',[...arrDatosInsert])
            }else{
              console.log("entra no hay datos");
              data?.map(async(row) => {
                //for (const row of data) {
                  newId++;
                  // let descrip = await funDArticulos(row[0]);
                  // var dtalled = descrip[0]? descrip[0].Name : 'No existe'
            
                  return arrDatosInsert?.push({
                    idArt: newId, 
                    CodArt: row[0], 
                    namArt: '-'
                  })
                  
                })
                
                onDataUpdate('bcecliente',[...arrDatosInsert])
            }
            
            setTimeout(() => {
              
              setInterCoun(listInitData.length)
            }, 1000);
            
          };
          reader.readAsArrayBuffer(file);
        //reader.readAsBinaryString(file);
        }    
        input.click();
      } else { notify_warning('Se requiere obsequio.'); }
    } else {
      notify_warning('Campo descripción es obligatorio, no debe estar vacio.');
    }
  }
  /** Table empaginado */
  const [page, setPage] = useState(0);
  const perPage = 100;
  const pagesVisited = page * perPage;
  const displayData = listInitData.slice(pagesVisited, pagesVisited + perPage).map(item => {
    return <tr key={item.idArt}>
        <td className='text-center' >
          { onValBloqueo ? <div className="d-none"></div> :
            <AButton tp_tilde="Eliminar" disabled={onValCopyDat ? false : item.estadoComprometido} bt_funct={()=> onClickDeletefila(item.CodArt)} tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />
          }
        </td> 
        <td style={{ position: 'relative', zIndex: '2',maxWidth:'50px' }} >{item.CodArt}</td>
        <td>{item.namArt}</td>
        <td></td>
    </tr> 
  });
  const pageCount = Math.ceil(listInitData.length / perPage);
  const changePage = ({ selected }) => {
    setPage(selected);
  };
  useEffect(() => {
    const cook_interBloq = cookies.get('interBloq');
    setInterBloq(cook_interBloq)
    if (listInitData.length=== 0) {
      setInterBloq(false)
    }
  },[setInterBloq, listInitData])
  return (
    <>
      <div className="data-tables table-responsive text-nowrap " style={{ position: 'relative', zIndex: '1', width: 'auto', height: '400px', overflowY: 'scroll', overflowX: 'scroll' }}>
      {
        (interBloq) ?
        <>
          <table className="table table-sm" style={{fontSize:'10px', zIndex: '2'}}>
            <thead style={{ position: 'sticky', top: 0, zIndex: 10 }}>
              <tr >
                <th>Actions</th>
                <th style={{width:'350px'}} >
                  <div className="row">
                  <div className="col-6">
                    Code Cliente
                  </div>
                  <div className="col-6 text-end">
                    <AButton tp_tilde="Archivo excel" tp_posit="top" bt_class="btn btn-xs btn-outline-success" ic_class="bx bx-ms bx-upload" vl_tpbtn={1} bt_funct={onClickSubirArchivo} bt_descr="Subir Archivo Sniper"/>
                  </div>

                  </div>
                    
                  
                </th>
                <th style={{width:'450px'}} >Des. Cliente</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody >
            {displayData}
            </tbody>
            <tfoot style={{ position: 'sticky', top: 0, zIndex: 11 }}>
              <tr >
                <td colSpan="3">
                  <div className="pagination " >
                    <Paginate  
                      pageCount={pageCount}
                      onPageChange={changePage}
                      previousLabel={<button className="btn btn-sm btn-danger">{'<'}</button>}
                      nextLabel={<button className="btn btn-sm btn-danger">{'>'}</button>}
                      breakLabel={'...'}
                      pageClassName={'page-item'}
                      pageLinkClassName="page-link btn btn-sm btn-outline-danger"
                      activeClassName={' active'}
                    />
                  </div>
                </td>
                <td> Total de Filas: {interCoun} </td>
              </tr> 
            </tfoot>
          </table>
          
        </>
        :
        <table className="table table-sm" id='tab_BCEInicioDetalleT' style={{ fontSize: '10px', zIndex: '2', }}>
          <thead style={{ position: 'sticky', top: 0, zIndex: 10 }}>
            <tr >
              <th>Actions</th>
              <th style={{ width: '350px' }} >
                <div className="row">
                  <div className="col-6">
                    Code Cliente
                  </div>
                  <div className="col-6 text-end">
                    <AButton tp_tilde="Archivo excel" tp_posit="top" bt_class="btn btn-xs btn-outline-success" ic_class="bx bx-ms bx-upload" vl_tpbtn={1} bt_funct={onClickSubirArchivo} bt_descr="Subir Archivo Sniper"/>
                  </div>
                </div>
              </th>
              <th style={{ width: '450px' }} >Des. Cliente</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody >
            {
              (arrDatosInsert.length) ? arrDatosInsert.map((row) => (
                <React.Fragment key={row.idArt}>
                  <TRPlantilllaCD key={row.idArt} datoRow={row} />
                  {onValBloqueo ? <div className="d-none"></div> :
                    (!filaActiva || filaActiva === row.idArt) && (
                      <tr key={prosp?.datoRow?.idArt}>
                        <td className='td1'></td>
                        <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                          <AsyncSelect
                            styles={styles}
                            placeholder="Busque por Cod./Desc."
                            name="form-field-name"
                            className="sele_pru from-control form-control-sm tbCodArticulo"
                            value={slcArticulo}
                            defaultOptions
                            getOptionLabel={e => e.Code + ' - ' + e.Name}
                            getOptionValue={e => e.Code}
                            loadOptions={funDArticulos}
                            onChange={handleSelectChange}
                            maxMenuHeight={150}
                          />
                        </td>
                        <td className='td3'>{slcArticulo.Name}</td>
                        <td className='td4'>
                          <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                        </td>
                      </tr>
                    )
                  }
                </React.Fragment>
              ))
                : onValBloqueo ? <div className="d-none"></div> :
                  <tr key={prosp?.datoRow?.idArt}>
                    <td className='td1'></td>
                    <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                      <AsyncSelect
                        styles={styles}
                        placeholder="Busque por Cod./Desc."
                        name="form-field-name"
                        className="sele_pru from-control form-control-sm tbCodArticulo"
                        value={slcArticulo}
                        defaultOptions
                        getOptionLabel={e => e.Code + ' - ' + e.Name}
                        getOptionValue={e => e.Code}
                        loadOptions={funDArticulos}
                        onChange={handleSelectChange}
                        maxMenuHeight={150}
                      />
                    </td>
                    <td className='td3'>{slcArticulo.Name}</td>
                    <td className='td4'>
                      <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                    </td>
                  </tr>
            }
          </tbody>
        </table>
      }
      </div>
    </>
  )
}
export const BTCGrupoArticulosDetallados = (prosp) => {
  const { arrCampDatos, listInitData, onDataUpdate, onHandleVPut, onValCopyDat, onValBloqueo } = prosp
  const [arrDatosInsert, setArrDatosInsert] = useState([]);
  const [filaActiva, setFilaActiva] = useState(null);
  const [slcArticulo, setSlcArticulo] = useState([""]);
  const funDArticulos = useCallback(async (inputValue) => {
    const url = '/apiGrupoArticulos?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }, [])
  const handleSelectChange = (selectedOption) => { setSlcArticulo(selectedOption); };
  const onClickInsert = async () => {
    // if (arrCampDatos.txtCod!=="" && arrCampDatos.txtDBon !=="" && slcArticulo.Code !== undefined) {      
    if (arrCampDatos.txtDBon !== "" && slcArticulo.Code !== undefined) {
      const datosrepetidos = arrDatosInsert.filter(varr => varr.CodArt === slcArticulo.Code)
      if (datosrepetidos.length === 0) {
        const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
        const newId = (lastItem === undefined || lastItem === "") ? 1 : lastItem.idArt + 1;
        const newItem = { idArt: newId, CodArt: slcArticulo.Code, namArt: slcArticulo.Name };
        notify_valido("Se agrego correctamente!")
        setArrDatosInsert([...arrDatosInsert, newItem]);
        onDataUpdate('bcegrupoarticulo', [...arrDatosInsert, newItem])
        setFilaActiva(newId);
        setSlcArticulo([])
      } else { notify_warning('Datos selecionados ya exiten.'); }
    } else {
      // if (arrCampDatos.txtCod==="" && arrCampDatos.txtDBon==="") {
      if (arrCampDatos.txtDBon === "") {
        notify_warning('Campo descripción es obligatorio no debe estar vacio.');
      } else {
        // if (arrCampDatos.txtCod.trim()==="") { notify_warning('Campo codigo es obligatorio no debe estar vacio.'); }
        if (arrCampDatos.txtDBon.trim() === "") { notify_warning('Campo descripción son obligatorio no debe estar vacio.'); }
        if (!slcArticulo.Code) { notify_warning('Codigo es obligatorio, no debe estar vacio.'); }
      }
    }
  }
  useEffect(() => {
    const lastItem = listInitData[listInitData?.length - 1];
    setFilaActiva(lastItem?.idArt)
    setArrDatosInsert(listInitData)
  }, [listInitData])

  const onClickDeletefila = (code) => {
    onHandleVPut("1")
    const myList = [...arrDatosInsert];
    const data = arrDatosInsert.findIndex((res) => (res.CodArt === code));
    myList.splice(data, 1);
    setArrDatosInsert(myList);
    console.log(arrDatosInsert);
    onDataUpdate('bcegrupoarticulo', myList)
    setSlcArticulo([])

  }

  const TRPlantilllaCD = (prosp) => {
    return (
      <tr key={prosp.datoRow.idArt} >
        <td className='text-center' >
          {onValBloqueo ? <div className="d-none"></div> :
            <AButton tp_tilde="Eliminar" disabled={onValCopyDat ? false : prosp.datoRow.estadoComprometido} bt_funct={() => onClickDeletefila(prosp.datoRow.CodArt)} tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />
          }
        </td>
        <td style={{ position: 'relative', zIndex: '2', maxWidth: '50px' }} >
          {prosp.datoRow.CodArt}</td>
        <td>{prosp.datoRow.namArt}</td>
        <td></td>
      </tr>
    )
  }
  /** Modal */
  const [generalModal, setGeneralModal] = useState(false);
  const handleOpenGeneral = () => { setGeneralModal(true); }
  const handleCloseGeneral = () => { setGeneralModal(false); }
  const handleRetrunData = (data) => {
    // console.log("data ", data );
    const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
    let newId = (lastItem === undefined || lastItem === "") ? 0 : lastItem.idArt;
    if (listInitData.length > 0) {
      // Obtener los códigos que ya existen en array1
      const existingCodes = listInitData.map(item => item.CodArt);
      // Filtrar array2 para quedarnos sólo con elementos con códigos nuevos
      let lastId = listInitData[listInitData.length - 1].idArt;
      const newElements = data.filter(item => !existingCodes.includes(item.Code)).map(item => {
        lastId++;
        return {
          idArt: lastId,
          CodArt: item.Code,
          namArt: item.Name
        }
      });
      const result = [...listInitData, ...newElements];
      //console.log("result", result);
      onDataUpdate('bcegrupoarticulo', [...result])
      handleCloseGeneral()

    } else {
      console.log("no hay data");
      // const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
      // let newId = (lastItem === undefined || lastItem === "") ? 0 : lastItem.idArt;
      data?.map((row) => {
        newId++;
        return arrDatosInsert?.push({
          "idArt": newId,
          "CodArt": row.Code,
          "namArt": row.Name,
        })
      })
      onDataUpdate('bcegrupoarticulo', [...arrDatosInsert])
      handleCloseGeneral()

    }
  }
  let columns = [
    { header: 'Code', accessorKey: 'Code' },
    { header: 'Name', accessorKey: 'Name' }
  ]

  return (
    <>
      <div className="data-tables table-responsive text-nowrap " style={{ position: 'relative', zIndex: '1', width: 'auto', height: '400px', overflowY: 'scroll', overflowX: 'scroll' }}>
        <table className="table table-sm" id='tab_BCEAgruparArticulosT' style={{ fontSize: '10px', zIndex: '2', }}>
          <thead style={{ position: 'sticky', top: 0, zIndex: 10 }}>
            <tr >
              <th>Actions</th>
              <th style={{ width: '350px' }} >Code Grupo  <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger d-none" ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /></th>
              <th style={{ width: '450px' }} >Des. Grupo</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody >
            {
              (arrDatosInsert.length) ? arrDatosInsert.map((row) => (
                <React.Fragment key={row.idArt}>
                  <TRPlantilllaCD key={row.idArt} datoRow={row} />
                  {onValBloqueo ? <div className="d-none"></div> :
                    (!filaActiva || filaActiva === row.idArt) && (
                      <tr key={prosp?.datoRow?.idArt}>
                        <td className='td1'></td>
                        <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                          <AsyncSelect
                            styles={styles}
                            placeholder="Busque por Cod./Desc."
                            name="form-field-name"
                            className="sele_pru from-control form-control-sm tbCodArticulo"
                            value={slcArticulo}
                            defaultOptions
                            getOptionLabel={e => e.Code + ' - ' + e.Name}
                            getOptionValue={e => e.Code}
                            loadOptions={funDArticulos}
                            onChange={handleSelectChange}
                            maxMenuHeight={150}
                          />
                        </td>
                        <td className='td3'>{slcArticulo.Name}</td>
                        <td className='td4'>
                          <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                          <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info" bt_funct={handleOpenGeneral} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                        </td>
                      </tr>
                    )
                  }
                </React.Fragment>
              ))
                : onValBloqueo ? <div className="d-none"></div> :
                  <tr key={prosp?.datoRow?.idArt}>
                    <td className='td1'></td>
                    <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                      <AsyncSelect
                        styles={styles}
                        placeholder="Busque por Cod./Desc."
                        name="form-field-name"
                        className="sele_pru from-control form-control-sm tbCodArticulo"
                        value={slcArticulo}
                        defaultOptions
                        getOptionLabel={e => e.Code + ' - ' + e.Name}
                        getOptionValue={e => e.Code}
                        loadOptions={funDArticulos}
                        onChange={handleSelectChange}
                        maxMenuHeight={150}
                      />
                    </td>
                    <td className='td3'>{slcArticulo.Name}</td>
                    <td className='td4'>
                      <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                      <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info" bt_funct={handleOpenGeneral} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                    </td>
                  </tr>
            }
          </tbody>
        </table>
      </div>
      {
        generalModal && (
          <ModalDetallesBoni
            hanOpenModal={generalModal}
            hanClosModal={handleCloseGeneral}
            onReturnData={handleRetrunData}
            onDataEstras={{ "columns": columns, "url": '/apiGrupoArticulos?vcodEmpresa=' + cook_idEmp }}
          />
        )
      }

    </>
  )
}
export const BTCVendedorDetallador = (prosp) => {
  const { arrCampDatos, listInitData, onDataUpdate, onHandleVPut, onValCopyDat, onValBloqueo } = prosp
  const [arrDatosInsert, setArrDatosInsert] = useState([]);
  const [filaActiva, setFilaActiva] = useState(null);
  const [slcArticulo, setSlcArticulo] = useState([""]);
  const funDArticulos = useCallback(async (inputValue) => {
    const url = '/apiCodeVendedores?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }, [])
  const handleSelectChange = (selectedOption) => { setSlcArticulo(selectedOption); };
  const onClickInsert = async () => {
    // if (arrCampDatos.txtCod!=="" && arrCampDatos.txtDBon !=="" && slcArticulo.Code !== undefined) {      
    if (arrCampDatos.txtDBon !== "" && slcArticulo.Code !== undefined) {
      const datosrepetidos = arrDatosInsert.filter(varr => varr.CodArt === slcArticulo.Code)
      if (datosrepetidos.length === 0) {
        const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
        // const newId = (lastItem === undefined || lastItem === "") ? 2 : lastItem.idArt + 1;
        const newId = (lastItem === undefined || lastItem === "") ? 1 : lastItem.idArt + 1;
        const newItem = { idArt: newId, CodArt: slcArticulo.Code, namArt: slcArticulo.Name };
        notify_valido("Se agrego correctamente!")
        setArrDatosInsert([...arrDatosInsert, newItem]);
        onDataUpdate('bcevendedordetall', [...arrDatosInsert, newItem])
        setFilaActiva(newId);
        setSlcArticulo([])
      } else { notify_warning('Datos selecionados ya exiten.'); }
    } else {
      // if (arrCampDatos.txtCod==="" && arrCampDatos.txtDBon==="") {
      if (arrCampDatos.txtDBon === "") {
        notify_warning('Campo descripción es obligatorio, no debe estar vacio.');
      } else {
        // if (arrCampDatos.txtCod.trim()==="") { notify_warning('Campo codigo es obligatorio no debe estar vacio.'); }
        if (arrCampDatos.txtDBon.trim() === "") { notify_warning('Campo descripción son obligatorio no debe estar vacio.'); }
        if (!slcArticulo.Code) { notify_warning('Codigo es obligatorio, no debe estar vacio.'); }
      }
    }
  }

  useEffect(() => {
    const lastItem = listInitData[listInitData?.length - 1];
    setFilaActiva(lastItem?.idArt)
    setArrDatosInsert(listInitData)
  }, [listInitData])

  const onClickDeletefila = (code) => {
    onHandleVPut("1")
    const myList = [...arrDatosInsert];
    const data = arrDatosInsert.findIndex((res) => (res.CodArt === code));
    myList.splice(data, 1);
    setArrDatosInsert(myList);
    console.log(arrDatosInsert);
    onDataUpdate('bcevendedordetall', myList)
    setSlcArticulo([])

  }

  const TRPlantilllaCD = (prosp) => {
    return (
      <tr key={prosp.datoRow.idArt} >
        <td className='text-center' >
          {onValBloqueo ? <div className="d-none"></div> :
            <AButton tp_tilde="Eliminar" disabled={onValCopyDat ? false : prosp.datoRow.estadoComprometido} bt_funct={() => onClickDeletefila(prosp.datoRow.CodArt)} tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />
          }
        </td>
        <td style={{ position: 'relative', zIndex: '2', maxWidth: '50px' }} >
          {prosp.datoRow.CodArt}</td>
        <td>{prosp.datoRow.namArt}</td>
        <td></td>
      </tr>
    )
  }
  /** Modal */
  const [generalModal, setGeneralModal] = useState(false);
  const handleOpenGeneral = () => { setGeneralModal(true); }
  const handleCloseGeneral = () => { setGeneralModal(false); }
  const handleRetrunData = (data) => {
    // console.log("data ", data );
    const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
    //let newId = (lastItem === undefined || lastItem === "") ? 0 : lastItem.idArt;
    let newId = (lastItem === undefined || lastItem === "") ? 1 : lastItem.idArt;
    if (listInitData.length > 0) {
      // Obtener los códigos que ya existen en array1
      const existingCodes = listInitData.map(item => item.CodArt);
      // Filtrar array2 para quedarnos sólo con elementos con códigos nuevos
      let lastId = listInitData[listInitData.length - 1].idArt;
      const newElements = data.filter(item => !existingCodes.includes(item.Code)).map(item => {
        lastId++;
        return {
          idArt: lastId,
          CodArt: item.Code,
          namArt: item.Name
        }
      });
      const result = [...listInitData, ...newElements];
      //console.log("result", result);
      onDataUpdate('bcevendedordetall', [...result])
      handleCloseGeneral()

    } else {
      console.log("no hay data");
      // const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
      // let newId = (lastItem === undefined || lastItem === "") ? 0 : lastItem.idArt;
      data?.map((row) => {
        newId++;
        return arrDatosInsert?.push({
          "idArt": newId,
          "CodArt": row.Code,
          "namArt": row.Name,
        })
      })
      onDataUpdate('bcevendedordetall', [...arrDatosInsert])
      handleCloseGeneral()

    }
  }
  let columns = [
    { header: 'Code', accessorKey: 'Code' },
    { header: 'Name', accessorKey: 'Name' }
  ]

  return (
    <>
      <div className="data-tables table-responsive text-nowrap " style={{ position: 'relative', zIndex: '1', width: 'auto', height: '400px', overflowY: 'scroll', overflowX: 'scroll' }}>
        <table className="table table-sm" id='tab_VendedoresT' style={{ fontSize: '10px', zIndex: '2', }}>
          <thead style={{ position: 'sticky', top: 0, zIndex: 10 }}>
            <tr >
              <th>Actions</th>
              <th style={{ width: '350px' }} >Code Vendedor <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger d-none" ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /></th>
              <th style={{ width: '450px' }} >Des. vendedor</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody >
            {
              (arrDatosInsert.length) ? arrDatosInsert.map((row) => (
                <React.Fragment key={row.idArt}>
                  <TRPlantilllaCD key={row.idArt} datoRow={row} />
                  {onValBloqueo ? <div className="d-none"></div> :
                    (!filaActiva || filaActiva === row.idArt) && (
                      <tr key={prosp?.datoRow?.idArt}>
                        <td className='td1'>
                        </td>
                        <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                          <AsyncSelect
                            styles={styles}
                            placeholder="Busque por Cod./Desc."
                            name="form-field-name"
                            className="sele_pru from-control form-control-sm tbCodArticulo"
                            value={slcArticulo}
                            defaultOptions
                            getOptionLabel={e => e.Code + ' - ' + e.Name}
                            getOptionValue={e => e.Code}
                            loadOptions={funDArticulos}
                            onChange={handleSelectChange}
                            maxMenuHeight={150}
                          />
                        </td>
                        <td className='td3'>{slcArticulo.Name}</td>
                        <td className='td4'>
                          <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                          <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info" bt_funct={handleOpenGeneral} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                        </td>
                      </tr>
                    )
                  }
                </React.Fragment>
              ))
                : onValBloqueo ? <div className="d-none"></div> :
                  <tr key={prosp?.datoRow?.idArt}>
                    <td className='td1'></td>
                    <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                      <AsyncSelect
                        styles={styles}
                        placeholder="Busque por Cod./Desc."
                        name="form-field-name"
                        className="sele_pru from-control form-control-sm tbCodArticulo"
                        value={slcArticulo}
                        defaultOptions
                        getOptionLabel={e => e.Code + ' - ' + e.Name}
                        getOptionValue={e => e.Code}
                        loadOptions={funDArticulos}
                        onChange={handleSelectChange}
                        maxMenuHeight={150}
                      />
                    </td>
                    <td className='td3'>{slcArticulo.Name}</td>
                    <td className='td4'>
                      <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                      <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info" bt_funct={handleOpenGeneral} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                    </td>
                  </tr>
            }
          </tbody>
        </table>
      </div>
      {
        generalModal && (
          <ModalDetallesBoni
            hanOpenModal={generalModal}
            hanClosModal={handleCloseGeneral}
            onReturnData={handleRetrunData}
            onDataEstras={{ "columns": columns, "url": '/apiListaCodeVendedores?vcodEmpresa=' + cook_idEmp }}
          />
        )
      }

    </>
  )
}
export const BTCSucursalDetallado = (prosp) => {
  const { arrCampDatos, listInitData, onDataUpdate, onHandleVPut, onValCopyDat, onValBloqueo } = prosp
  const [arrDatosInsert, setArrDatosInsert] = useState([]);
  const [filaActiva, setFilaActiva] = useState(null);
  const [slcArticulo, setSlcArticulo] = useState([""]);
  const funDArticulos = useCallback(async (inputValue) => {
    const url = '/apiSucursal?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }, [])
  const handleSelectChange = (selectedOption) => { setSlcArticulo(selectedOption); };
  const onClickInsert = async () => {
    // if (arrCampDatos.txtCod!=="" && arrCampDatos.txtDBon !=="" && slcArticulo.Code !== undefined) {      
    if (arrCampDatos.txtDBon !== "" && slcArticulo.Code !== undefined) {
      const datosrepetidos = arrDatosInsert.filter(varr => varr.CodArt === slcArticulo.Code)
      if (datosrepetidos.length === 0) {
        const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
        const newId = (lastItem === undefined || lastItem === "") ? 1 : lastItem.idArt + 1;
        const newItem = { idArt: newId, CodArt: slcArticulo.Code, namArt: slcArticulo.Name };
        notify_valido("Se agrego correctamente!")
        setArrDatosInsert([...arrDatosInsert, newItem]);
        onDataUpdate('bcesucursaldetll', [...arrDatosInsert, newItem]);
        setFilaActiva(newId);
        setSlcArticulo([])
      } else { notify_warning('Datos selecionados ya exiten.'); }
    } else {
      // if (arrCampDatos.txtCod==="" && arrCampDatos.txtDBon==="") {
      if (arrCampDatos.txtDBon === "") {
        notify_warning('Campo descripción es obligatorio no debe estar vacio.');
      } else {
        // if (arrCampDatos.txtCod.trim()==="") { notify_warning('Campo codigo es obligatorio no debe estar vacio.'); }
        if (arrCampDatos.txtDBon.trim() === "") { notify_warning('Campo descripción son obligatorio no debe estar vacio.'); }
        if (!slcArticulo.Code) { notify_warning('Codigo es obligatorio, no debe estar vacio.'); }
      }
    }
  }
  useEffect(() => {
    const lastItem = listInitData[listInitData?.length - 1]
    setFilaActiva(lastItem?.idArt)
    setArrDatosInsert(listInitData)
  }, [listInitData])

  const onClickDeletefila = (code) => {
    onHandleVPut("1")
    const myList = [...arrDatosInsert];
    const data = arrDatosInsert.findIndex((res) => (res.CodArt === code));
    myList.splice(data, 1);
    setArrDatosInsert(myList);
    console.log(arrDatosInsert);
    onDataUpdate('bcesucursaldetll', myList)
    setSlcArticulo([])

  }

  const TRPlantilllaCD = (prosp) => {
    return (
      <tr key={prosp.datoRow.idArt} >
        <td className='text-center' >
          {onValBloqueo ? <div className="d-none"></div> :
            <AButton tp_tilde="Eliminar" disabled={onValCopyDat ? false : prosp.datoRow.estadoComprometido} bt_funct={() => onClickDeletefila(prosp.datoRow.CodArt)} tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />
          }
        </td>
        <td style={{ position: 'relative', zIndex: '2', maxWidth: '50px' }} >
          {prosp.datoRow.CodArt}</td>
        <td>{prosp.datoRow.namArt}</td>
        <td></td>
      </tr>
    )
  }

  return (
    <>
      <div className="data-tables table-responsive text-nowrap " style={{ position: 'relative', zIndex: '1', width: 'auto', height: '400px', overflowY: 'scroll', overflowX: 'scroll' }}>
        <table className="table table-sm" id='tab_sucursalT' style={{ fontSize: '10px', zIndex: '2', }}>
          <thead style={{ position: 'sticky', top: 0, zIndex: 10 }}>
            <tr >
              <th>Actions</th>
              <th style={{ width: '350px' }}>Code Sucursal  <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger d-none" ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /></th>
              <th style={{ width: '450px' }}>Des. Sucursal</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody >
            {
              (arrDatosInsert.length) ? arrDatosInsert.map((row) => (
                <React.Fragment key={row.idArt}>
                  <TRPlantilllaCD key={row.idArt} datoRow={row} />
                  {onValBloqueo ? <div className="d-none"></div> :
                    (!filaActiva || filaActiva === row.idArt) && (
                      <tr key={prosp?.datoRow?.idArt}>
                        <td className='td1'>
                        </td>
                        <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                          <AsyncSelect
                            styles={styles}
                            placeholder="Busque por Cod./Desc."
                            name="form-field-name"
                            className="sele_pru from-control form-control-sm tbCodArticulo"
                            value={slcArticulo}
                            defaultOptions
                            getOptionLabel={e => e.Code + ' - ' + e.Name}
                            getOptionValue={e => e.Code}
                            loadOptions={funDArticulos}
                            onChange={handleSelectChange}
                            maxMenuHeight={150}
                          />
                        </td>
                        <td className='td3'>{slcArticulo.Name}</td>
                        <td className='td4'>
                          <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                        </td>
                      </tr>
                    )
                  }
                </React.Fragment>
              ))
                : onValBloqueo ? <div className="d-none"></div> :
                  <tr key={prosp?.datoRow?.idArt}>
                    <td className='td1'></td>
                    <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                      <AsyncSelect
                        styles={styles}
                        placeholder="Busque por Cod./Desc."
                        name="form-field-name"
                        className="sele_pru from-control form-control-sm tbCodArticulo"
                        value={slcArticulo}
                        defaultOptions
                        getOptionLabel={e => e.Code + ' - ' + e.Name}
                        getOptionValue={e => e.Code}
                        loadOptions={funDArticulos}
                        onChange={handleSelectChange}
                        maxMenuHeight={150}
                      />
                    </td>
                    <td className='td3'>{slcArticulo.Name}</td>
                    <td className='td4'>
                      <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                    </td>
                  </tr>
            }
          </tbody>
        </table>
      </div>


    </>
  )
}
export const BTCMesaDetalle = (prosp) => {
  const { arrCampDatos, listInitData, onDataUpdate, onHandleVPut, onValCopyDat, onValBloqueo } = prosp
  const [arrDatosInsert, setArrDatosInsert] = useState([]);
  const [filaActiva, setFilaActiva] = useState(null);
  const [slcArticulo, setSlcArticulo] = useState([""]);
  const funDArticulos = useCallback(async (inputValue) => {
    const url = '/apiMesa?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }, [])
  const handleSelectChange = (selectedOption) => { setSlcArticulo(selectedOption); };
  const onClickInsert = async () => {
    // if (arrCampDatos.txtCod!=="" && arrCampDatos.txtDBon !=="" && slcArticulo.Code !== undefined) {      
    if (arrCampDatos.txtDBon !== "" && slcArticulo.Code !== undefined) {
      const datosrepetidos = arrDatosInsert.filter(varr => varr.CodArt === slcArticulo.Code)
      if (datosrepetidos.length === 0) {
        const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
        const newId = (lastItem === undefined || lastItem === "") ? 1 : lastItem.idArt + 1;
        const newItem = { idArt: newId, CodArt: slcArticulo.Code, namArt: slcArticulo.Name };
        notify_valido("Se agrego correctamente!")
        setArrDatosInsert([...arrDatosInsert, newItem]);
        onDataUpdate('bcemesadetall', [...arrDatosInsert, newItem])
        setFilaActiva(newId);
        setSlcArticulo([])
      } else { notify_warning('Datos selecionados ya exiten.'); }
    } else {
      // if (arrCampDatos.txtCod==="" && arrCampDatos.txtDBon==="") {
      if (arrCampDatos.txtDBon === "") {
        notify_warning('Campo descripción es obligatorio no debe estar vacio.');
      } else {
        // if (arrCampDatos.txtCod.trim()==="") { notify_warning('Campo codigo es obligatorio no debe estar vacio.'); }
        if (arrCampDatos.txtDBon.trim() === "") { notify_warning('Campo descripción son obligatorio no debe estar vacio.'); }
        if (!slcArticulo.Code) { notify_warning('Codigo es obligatorio, no debe estar vacio.'); }
      }
    }
  }
  useEffect(() => {
    const lastItem = listInitData[listInitData?.length - 1];
    setFilaActiva(lastItem?.idArt)
    setArrDatosInsert(listInitData)
  }, [listInitData])
  const TRPlantilllaCD = (prosp) => {
    return (
      <tr key={prosp.datoRow.idArt} >
        <td className='text-center' >
          {onValBloqueo ? <div className="d-none"></div> :
            <AButton tp_tilde="Eliminar" disabled={onValCopyDat ? false : prosp.datoRow.estadoComprometido} bt_funct={() => onClickDeletefila(prosp.datoRow.CodArt)} tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />
          }
        </td>
        <td style={{ position: 'relative', zIndex: '2', maxWidth: '50px' }} >
          {prosp.datoRow.CodArt}</td>
        <td>{prosp.datoRow.namArt}</td>
        <td></td>
      </tr>
    )
  }
  const onClickDeletefila = (code) => {
    onHandleVPut("1")
    const myList = [...arrDatosInsert];
    const data = arrDatosInsert.findIndex((res) => (res.CodArt === code));
    myList.splice(data, 1);
    setArrDatosInsert(myList);
    console.log(arrDatosInsert);
    onDataUpdate('bcemesadetall', myList)
    setSlcArticulo([])

  }


  /** Modal */
  const [generalModal, setGeneralModal] = useState(false);
  const handleOpenGeneral = () => { setGeneralModal(true); }
  const handleCloseGeneral = () => { setGeneralModal(false); }
  const handleRetrunData = (data) => {
    // console.log("data ", data );
    const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
    let newId = (lastItem === undefined || lastItem === "") ? 0 : lastItem.idArt;
    if (listInitData.length > 0) {
      // Obtener los códigos que ya existen en array1
      const existingCodes = listInitData.map(item => item.CodArt);
      // Filtrar array2 para quedarnos sólo con elementos con códigos nuevos
      let lastId = listInitData[listInitData.length - 1].idArt;
      const newElements = data.filter(item => !existingCodes.includes(item.Code)).map(item => {
        lastId++;
        return {
          idArt: lastId,
          CodArt: item.Code,
          namArt: item.Name
        }
      });
      const result = [...listInitData, ...newElements];
      //console.log("result", result);
      onDataUpdate('bcemesadetall', [...result])
      handleCloseGeneral()

    } else {
      console.log("no hay data");
      // const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
      // let newId = (lastItem === undefined || lastItem === "") ? 0 : lastItem.idArt;
      data?.map((row) => {
        newId++;
        return arrDatosInsert?.push({
          "idArt": newId,
          "CodArt": row.Code,
          "namArt": row.Name,
        })
      })
      onDataUpdate('bcemesadetall', [...arrDatosInsert])
      handleCloseGeneral()

    }
  }
  let columns = [
    { header: 'Code', accessorKey: 'Code' },
    { header: 'Name', accessorKey: 'Name' }
  ]
  return (
    <>
      <div className="data-tables table-responsive text-nowrap " style={{ position: 'relative', zIndex: '1', width: 'auto', height: '400px', overflowY: 'scroll', overflowX: 'scroll' }}>
        <table className="table table-sm" id='tab_mesaT' style={{ fontSize: '10px', zIndex: '2', }}>
          <thead style={{ position: 'sticky', top: 0, zIndex: 10 }}>
            <tr >
              <th>Actions</th>
              <th style={{ width: '350px' }} >Code Mesa <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger d-none" ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /></th>
              <th style={{ width: '450px' }} >Des. Mesa </th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody >
            {
              (arrDatosInsert.length) ? arrDatosInsert.map((row) => (
                <React.Fragment key={row.idArt}>
                  <TRPlantilllaCD key={row.idArt} datoRow={row} />
                  {onValBloqueo ? <div className="d-none"></div> :
                    (!filaActiva || filaActiva === row.idArt) && (
                      <tr key={prosp?.datoRow?.idArt}>
                        <td className='td1'>
                        </td>
                        <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                          <AsyncSelect
                            styles={styles}
                            placeholder="Busque por Cod./Desc."
                            name="form-field-name"
                            className="sele_pru from-control form-control-sm tbCodArticulo"
                            value={slcArticulo}
                            defaultOptions
                            getOptionLabel={e => e.Code + ' - ' + e.Name}
                            getOptionValue={e => e.Code}
                            loadOptions={funDArticulos}
                            onChange={handleSelectChange}
                            maxMenuHeight={150}
                          />
                        </td>
                        <td className='td3'>{slcArticulo.Name}</td>
                        <td className='td4'>
                          <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                          <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info" bt_funct={handleOpenGeneral} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                        </td>
                      </tr>
                    )
                  }
                </React.Fragment>
              ))
                : onValBloqueo ? <div className="d-none"></div> :
                  <tr key={prosp?.datoRow?.idArt}>
                    <td className='td1'></td>
                    <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                      <AsyncSelect
                        styles={styles}
                        placeholder="Busque por Cod./Desc."
                        name="form-field-name"
                        className="sele_pru from-control form-control-sm tbCodArticulo"
                        value={slcArticulo}
                        defaultOptions
                        getOptionLabel={e => e.Code + ' - ' + e.Name}
                        getOptionValue={e => e.Code}
                        loadOptions={funDArticulos}
                        onChange={handleSelectChange}
                        maxMenuHeight={150}
                      />
                    </td>
                    <td className='td3'>{slcArticulo.Name}</td>
                    <td className='td4'>
                      <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                      <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info" bt_funct={handleOpenGeneral} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                    </td>
                  </tr>
            }
          </tbody>
        </table>
      </div>
      {
        generalModal && (
          <ModalDetallesBoni
            hanOpenModal={generalModal}
            hanClosModal={handleCloseGeneral}
            onReturnData={handleRetrunData}
            onDataEstras={{ "columns": columns, "url": '/apiMesa?vcodEmpresa=' + cook_idEmp }}
          />
        )
      }


    </>
  )
}
export const BTCObsequioDetalle = (prosp) => {

  const { arrCampDatos, listInitData, onDataUpdate, onHandleVPut, onValCopyDat, onValBloqueo } = prosp
  const [arrDatosInsert, setArrDatosInsert] = useState([]);
  const [filaActiva, setFilaActiva] = useState(null);
  const [slcArticulo, setSlcArticulo] = useState([""]);
  const  valorDefault = '999999999';
  const [arFileUltim, setArFileUltim] = useState({
    txtDet1: '0.00',
    txtDet2: valorDefault,
    txtDet3: valorDefault,
    txtDet4: '0.00',
    txtDet5: valorDefault,
    txtDet6: '0.00',
    txtDet7: valorDefault,
    txtDet8: '0.00',
    txtDet9: '0.00'
  });

  const funDArticulos = useCallback(async (inputValue) => {
    const url = '/apiArticulos?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }, [])

  const handleSelectChange = async (selectedOption) => {
    setSlcArticulo(selectedOption);

    // /** Inicio */
    // /** Entregado */
    // const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    // const response = await fetch('/apiEntregado?vcodEmpresa=' + cook_idEmp + '&CodArt=' + selectedOption.Code, opPe);
    // const dataEntr = await response.json();
    // /** Entregado */
    // const opPe2 = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    // const respons2 = await fetch('/apiPorEntregar?vcodEmpresa=' + cook_idEmp + '&CodArt=' + selectedOption.Code, opPe2);
    // const dataPorE = await respons2.json();

    // setArFileUltim({
    //   ...arFileUltim,
    //   'txtDet8': (dataEntr.vl_rsp) ? dataEntr.vl_rsp : '0.00',
    //   'txtDet9': (dataPorE.vl_rsp) ? dataPorE.vl_rsp : '0.00'
    // });
    // /** Fin */

  };

  const handleFieldTabChange = (fieldName) => (event) => {
    const value = event.target.value;
    setArFileUltim({
      ...arFileUltim,
      [fieldName]: value,
    });
  };

  const onClickInsert = async () => {

    // if (arrCampDatos.txtCod!=="" && arrCampDatos.txtDBon !=="" && slcArticulo.Code !== undefined) {      
    if (
      arrCampDatos.txtDBon !== "" && slcArticulo.Code !== undefined&&
      (arFileUltim.txtDet1 !== '0.00' && arFileUltim.txtDet1 !== '0' && arFileUltim.txtDet1.trim() !== '') && 
      (arFileUltim.txtDet4 !== '0.00' && arFileUltim.txtDet4 !== '0' && arFileUltim.txtDet4.trim() !== '') && 
      (arFileUltim.txtDet5 !== '0.00' && arFileUltim.txtDet5 !== '0' && arFileUltim.txtDet5.trim() !== '') && 
      (arFileUltim.txtDet6 !== '0.00' && arFileUltim.txtDet6 !== '0' && arFileUltim.txtDet6.trim() !== '')
    ) {
      const datosrepetidos = arrDatosInsert.filter(varr => varr.CodArt === slcArticulo.Code)
      if (datosrepetidos.length === 0) {
        const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
        const newId = (lastItem === undefined || lastItem === "") ? 1 : lastItem.idArt + 1;
        const newItem = {
          idArt: newId, CodArt: slcArticulo.Code, namArt: slcArticulo.Name, uniArt: slcArticulo.Unidad,
          txtDet1: arFileUltim.txtDet1,
          txtDet2: arFileUltim.txtDet2,
          txtDet3: arFileUltim.txtDet3,
          txtDet4: arFileUltim.txtDet4,
          txtDet5: arFileUltim.txtDet5,
          txtDet6: arFileUltim.txtDet6,
          txtDet7: arFileUltim.txtDet7,
          txtDet8: arFileUltim.txtDet8,
          txtDet9: arFileUltim.txtDet9
        };
        notify_valido("Se agrego correctamente!")
        setArrDatosInsert([...arrDatosInsert, newItem]);
        onDataUpdate('bceobsequiodetll', [...arrDatosInsert, newItem])
        setFilaActiva(newId);
        setSlcArticulo([])
        setArFileUltim({
          txtDet1: '0.00',
          txtDet2: valorDefault,
          txtDet3: valorDefault,
          txtDet4: '0.00',
          txtDet5: valorDefault,
          txtDet6: '0.00',
          txtDet7: valorDefault,
          txtDet8: '0.00',
          txtDet9: '0.00'
        })
        //}        
      } else {
        notify_warning('Datos selecionados ya exiten.');
      }
    } else {
      if (arrCampDatos.txtDBon.trim()===""){ notify_warning('Campo descripción es obligatorio, no debe estar vacio.'); }
      if (!slcArticulo.Code){ notify_warning('Codigo es obligatorio, no debe estar vacio.'); }
      if (arFileUltim.txtDet1 === '0.00' || arFileUltim.txtDet1 === '0' || arFileUltim.txtDet1.trim() === ''){ notify_warning('Cantidad AB es obligatorio.'); }
      if (arFileUltim.txtDet4 === '0.00' || arFileUltim.txtDet4 === '0' || arFileUltim.txtDet4.trim() === ''){ notify_warning('Desde es obligatorio.'); }
      if (arFileUltim.txtDet5 === '0.00' || arFileUltim.txtDet5 === '0' || arFileUltim.txtDet5.trim() === ''){ notify_warning('Hasta es obligatorio.'); }
      if (arFileUltim.txtDet6 === '0.00' || arFileUltim.txtDet6 === '0' || arFileUltim.txtDet6.trim() === ''){ notify_warning('Por cada es obligatorio.'); }
    }

  }

  useEffect(() => {
    const lastItem = listInitData[listInitData?.length - 1];
    setFilaActiva(lastItem?.idArt)
    setArrDatosInsert(listInitData)
  }, [listInitData,])

  const onClickDeletefila = (code) => {
    onHandleVPut("1")
    const myList = [...arrDatosInsert];
    const data = arrDatosInsert.findIndex((res) => (res.CodArt === code));
    myList.splice(data, 1);
    setArrDatosInsert(myList);
    console.log(arrDatosInsert);
    onDataUpdate('bceobsequiodetll', myList)
    setSlcArticulo([])
  }

   /** nueva funcionalidad */
   const updateInput = (index, fieldName) => (event) => {
    const value = event.target.value;
    const newData = [...arrDatosInsert];
    const elementIndex = newData.findIndex((obj => obj.idArt === index));
    newData[elementIndex][fieldName] = value
    setArrDatosInsert(newData)
  }

  //componente de estado local 
    const StockInput = ({ rowId, initialValue, onUpdate }) => {
      const [value, setValue] = useState(initialValue);
      
      const handleChange = (e) => {
        setValue(e.target.value);
      };
  
      const handleBlur = () => {
        if (value !== initialValue) {
          onUpdate(rowId, value);
        }
      };
  
      // Agregamos useEffect para sincronizar con initialValue
      useEffect(() => {
        setValue(initialValue);
      }, [initialValue]);
  
      return (
        <input 
          type="number"
          className="form-control form-control-sm"
          autoComplete='off'
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      );
    };

  const TRPlantilllaCD = (prosp) => {
    return (
      <tr key={prosp.datoRow.idArt} >
        <td className='td1'>
          {onValBloqueo ? <div className="d-none"></div> :

            <AButton tp_tilde="Eliminar" disabled={onValCopyDat ? false : prosp.datoRow.estadoComprometido} bt_funct={() => onClickDeletefila(prosp.datoRow.CodArt)} tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />

          }          </td>
        <td className='td2' style={{ position: 'relative', zIndex: '2', maxWidth: '50px' }} >
          {prosp.datoRow.CodArt}
        </td>
        <td className='td3'>
          {prosp.datoRow.namArt}
        </td>
        <td className='td4'>{prosp.datoRow.uniArt}</td>
        <td className='td5'>{prosp.datoRow.txtDet1}</td>
        <td className='td6'>{prosp.datoRow.txtDet2}</td>
        <td className='td7'>{prosp.datoRow.txtDet3}</td>
        <td className='td8'>{prosp.datoRow.txtDet4}</td>
        <td className='td9'>{prosp.datoRow.txtDet5}</td>
        <td className='td10'>{prosp.datoRow.txtDet6}</td>
        <td className='td11'>
          { 
            arrCampDatos.txtActi === 'Y' ? 
            prosp.datoRow.txtDet7 
            :
            <StockInput 
              rowId={prosp.datoRow.idArt}
              initialValue={prosp.datoRow.txtDet7}
              onUpdate={(id, value) => {
                const newData = arrDatosInsert.map(row =>
                  row.idArt === id ? {...row, txtDet7: value} : row
                );
                setArrDatosInsert(newData);
                onDataUpdate('bceobsequiodetll', newData);
              }}
            />
          }
        </td>
        <td className='td12'>{prosp.datoRow.txtDet8}</td>
        <td className='td13'>{prosp.datoRow.txtDet9}</td>
        <td></td>
      </tr>
    )
  }

  // Modal stock
  const [generalModal, setGeneralModal] = useState(false);
  const handleCloseGeneral= ()=> {
    setGeneralModal(false);
  }
  const handleOpenGeneral = () => {
    setGeneralModal(true);
  }
  const handleRetrunData= (data) => {}
  let columns  = [
    { header: 'Code', accessorKey: 'Code'},
    { header: 'Name', accessorKey: 'Name' },
    { header: 'Unidad', accessorKey: 'Unidad' },
    { header: 'Categoria', accessorKey: 'Categoria' },
    { header: 'CodEAN', accessorKey: 'CodEAN' },
    {header: 'Stock total', accessorKey:'Total'}
  ]

  return (
    <>
      <div className="data-tables table-responsive text-nowrap " style={{ position: 'relative', zIndex: '1', width: 'auto', height: '400px', overflowY: 'scroll', overflowX: 'scroll' }}>
        <table className="table table-sm" id='tab_obsequio' style={{ fontSize: '10px', zIndex: '2', width: '100%' }}>
          <thead style={{ position: 'sticky', top: 0, zIndex: 10 }}>
            <tr >
              <th>Actions</th>
              <th style={{ width: '250px' }}>CodeB.<AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger d-none" ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              <th>Des.B.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              <th>UnidadB.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              <th>CantidadB.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              <th>Cant.Max&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              <th>Cant.MaxCliente&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              <th>Desde&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              <th>Hasta&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              <th>PorCada&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              <th>Stock&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              <th>Entregado&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              <th>PorEntregar&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody >
            {
              (arrDatosInsert.length) ? arrDatosInsert.map((row) => (
                <React.Fragment key={row.idArt}>
                  <TRPlantilllaCD key={row.idArt} datoRow={row} />
                  {onValBloqueo ? <div className="d-none"></div> :
                    (!filaActiva || filaActiva === row.idArt) && (
                      <tr key={prosp?.datoRow?.idArt}>
                        <td className='td1'>
                          <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info"  bt_funct={handleOpenGeneral}  ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                        </td>
                        <td className='td2' style={{ position: 'relative', zIndex: '2' }} >
                          <AsyncSelect
                            styles={styles}
                            placeholder="Busque por Cod./Desc."
                            id="selectprinr"
                            name="form-field-name"
                            className="from-control form-control-sm "
                            value={slcArticulo}
                            defaultOptions
                            getOptionLabel={e => e.Code + ' - ' + e.Name}
                            getOptionValue={e => e.Code}
                            loadOptions={funDArticulos}
                            onChange={handleSelectChange}
                            maxMenuHeight={150}
                          />
                        </td>
                        <td className='td3'>{slcArticulo?.Name}</td>
                        <td className='td4'>{slcArticulo?.Unidad}</td>
                        <td className='td5'>
                          <input type="text" id="txt_det1" name="txt_det1" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet1} onChange={handleFieldTabChange('txtDet1')} />
                        </td>
                        <td className='td6'>
                          <input type="number" id="txt_det2" name="txt_det2" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet2} onChange={handleFieldTabChange('txtDet2')} />
                        </td>
                        <td className='td7'>
                          <input type="number" id="txt_det3" name="txt_det3" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet3} onChange={handleFieldTabChange('txtDet3')} />
                        </td>
                        <td className='td8'>
                          <input type="number" id="txt_det4" name="txt_det4" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet4} onChange={handleFieldTabChange('txtDet4')} />
                        </td>
                        <td className='td9'>
                          <input type="number" id="txt_det5" name="txt_det5" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet5} onChange={handleFieldTabChange('txtDet5')} />
                        </td>
                        <td className='td10'>
                          <input type="number" id="txt_det6" name="txt_det6" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet6} onChange={handleFieldTabChange('txtDet6')} />
                        </td>
                        <td className='td11'>
                          <input type="number" id="txt_det7" name="txt_det7" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet7} onChange={handleFieldTabChange('txtDet7')} />
                        </td>
                        <td className='td12'>
                          <input type="number" id="txt_det8" name="txt_det8" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet8} onChange={handleFieldTabChange('txtDet8')} disabled />
                        </td>
                        <td className='td13'>
                          <input type="number" id="txt_det9" name="txt_det9" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet9} onChange={handleFieldTabChange('txtDet9')} disabled />
                        </td>
                        <td className='td14'>
                          <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                        </td>
                      </tr>
                    )
                  }
                </React.Fragment>
              ))
                : onValBloqueo ? <div className="d-none"></div> :
                  <tr key={prosp?.datoRow?.idArt}>
                    <td className='td1'>
                      <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info"  bt_funct={handleOpenGeneral}  ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                    </td>
                    <td className='td2' style={{ position: 'relative', zIndex: '2' }} >
                      <AsyncSelect
                        styles={styles}
                        placeholder="Busque por Cod./Desc."
                        id="selectprinr"
                        name="form-field-name"
                        className="from-control form-control-sm "
                        value={slcArticulo}
                        defaultOptions
                        getOptionLabel={e => e.Code + ' - ' + e.Name}
                        getOptionValue={e => e.Code}
                        loadOptions={funDArticulos}
                        onChange={handleSelectChange}
                        maxMenuHeight={150}
                      />
                    </td>
                    <td className='td3'>{slcArticulo?.Name}</td>
                    <td className='td4'>{slcArticulo?.Unidad}</td>
                    <td className='td5'>
                      <input type="text" id="txt_det1" name="txt_det1" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet1} onChange={handleFieldTabChange('txtDet1')} />
                    </td>
                    <td className='td6'>
                      <input type="number" id="txt_det2" name="txt_det2" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet2} onChange={handleFieldTabChange('txtDet2')} />
                    </td>
                    <td className='td7'>
                      <input type="number" id="txt_det3" name="txt_det3" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet3} onChange={handleFieldTabChange('txtDet3')} />
                    </td>
                    <td className='td8'>
                      <input type="number" id="txt_det4" name="txt_det4" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet4} onChange={handleFieldTabChange('txtDet4')} />
                    </td>
                    <td className='td9'>
                      <input type="number" id="txt_det5" name="txt_det5" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet5} onChange={handleFieldTabChange('txtDet5')} />
                    </td>
                    <td className='td10'>
                      <input type="number" id="txt_det6" name="txt_det6" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet6} onChange={handleFieldTabChange('txtDet6')} />
                    </td>
                    <td className='td11'>
                      <input type="number" id="txt_det7" name="txt_det7" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet7} onChange={handleFieldTabChange('txtDet7')} />
                    </td>
                    <td className='td12'>
                      <input type="number" id="txt_det8" name="txt_det8" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet8} onChange={handleFieldTabChange('txtDet8')} disabled />
                    </td>
                    <td className='td13'>
                      <input type="number" id="txt_det9" name="txt_det9" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet9} onChange={handleFieldTabChange('txtDet9')} disabled />
                    </td>
                    <td className='td14'>
                      <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                    </td>
                  </tr>
            }
          </tbody>
        </table>
      </div>
      {
       generalModal &&   <ModalDetallesBoniParStock
          hanOpenModal = {generalModal}
          hanClosModal = {handleCloseGeneral}
          onReturnData = {handleRetrunData}
          onDataEstras = {{"columns":columns,"url":'/apiParArticulos?vcodEmpresa='+cook_idEmp}}
      />
      }

    </>
  )
}
export const BTCCategoriaCliente = (prosp) => {
  const { arrCampDatos, listInitData, onDataUpdate, onHandleVPut, onValCopyDat, onValBloqueo } = prosp

  const [arrDatosInsert, setArrDatosInsert] = useState([]);
  const [filaActiva, setFilaActiva] = useState(null);
  const [slcArticulo, setSlcArticulo] = useState([""]);


  const funDArticulos = useCallback(async (inputValue) => {
    const url = '/apiCategoriaCliente?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue; // cambiar la api 
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }, [])
  const handleSelectChange = (selectedOption) => {
    setSlcArticulo(selectedOption); // Actualiza el estado si es necesario.
  };
  const onClickInsert = async () => {
    // if (arrCampDatos.txtCod!=="" && arrCampDatos.txtDBon !=="" && slcArticulo.Code !== undefined) {
    if (arrCampDatos.txtDBon !== "" && slcArticulo.Code !== undefined) {
      const datosrepetidos = arrDatosInsert.filter(varr => varr.CodArt === slcArticulo.Code)
      if (datosrepetidos.length === 0) {
        const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
        const newId = (lastItem === undefined || lastItem === "") ? 1 : lastItem.idArt + 1;
        const newItem = { idArt: newId, CodArt: slcArticulo.Code, namArt: slcArticulo.Name };

        // const url = '/apiBuuInsert'
        // const body = { numTab:6, arrCabecera: arrCampDatos, arrDetalle: newItem};
        // const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
        // const response = await fetch(url,opPe);
        // const resdatos = await response.json();

        // if (resdatos.vl_api === 1){
        //   notify_warning("Error al agregar o registro ya eXiste, verificar datos!")
        // }
        // if (resdatos.vl_existe === 1){
        //   notify_warning("Registro ya eXiste!")
        // }
        // if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1 ){
        notify_valido("Se agrego correctamente!")
        setArrDatosInsert([...arrDatosInsert, newItem]);
        onDataUpdate('bcecategoriacliente', [...arrDatosInsert, newItem]) //! el nombre del vector se cambio el vector creado 
        setFilaActiva(newId);
        setSlcArticulo([])
        //}        
      } else {
        notify_warning('Datos selecionados ya exiten.');
      }
    } else {
      // if (arrCampDatos.txtCod==="" && arrCampDatos.txtDBon==="") {
      if (arrCampDatos.txtDBon === "") {
        notify_warning('Campo descripción es obligatorios no debe estar vacio.');
      } else {
        // if (arrCampDatos.txtCod.trim()==="") { notify_warning('Campo codigo es obligatorio no debe estar vacio.'); }
        if (arrCampDatos.txtDBon.trim() === "") { notify_warning('Campo descripción son obligatorio no debe estar vacio.'); }
        if (!slcArticulo.Code) { notify_warning('Codigo es obligatorio, no debe estar vacio.'); }
      }
    }
  }


  useEffect(() => {
    const lastItem = listInitData[listInitData?.length - 1]
    //console.log("lastItem",lastItem);
    setFilaActiva(lastItem?.idArt)
    setArrDatosInsert(listInitData)
  }, [listInitData])

  const onClickDeletefila = (code) => {
    onHandleVPut("1")
    const myList = [...arrDatosInsert];
    const data = arrDatosInsert.findIndex((res) => (res.CodArt === code));
    myList.splice(data, 1);
    setArrDatosInsert(myList);
    console.log(arrDatosInsert);
    onDataUpdate('bcecategoriacliente', myList)
    setSlcArticulo([])

  }

  const TRPlantilllaCD = (prosp) => {
    return (
      <tr key={prosp.datoRow.idArt} >
        <td className='text-center' >
          {onValBloqueo ? <div className="d-none"></div> :
            <AButton tp_tilde="Eliminar" disabled={onValCopyDat ? false : prosp.datoRow.estadoComprometido} bt_funct={() => onClickDeletefila(prosp.datoRow.CodArt)} tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />
          }
        </td>
        <td style={{ position: 'relative', zIndex: '2', maxWidth: '50px' }} >
          {prosp.datoRow.CodArt}</td>
        <td>{prosp.datoRow.namArt}</td>
        <td></td>
      </tr>
    )
  }
  /** Modal */
  const [generalModal, setGeneralModal] = useState(false);
  const handleOpenGeneral = () => { setGeneralModal(true); }
  const handleCloseGeneral = () => { setGeneralModal(false); }
  const handleRetrunData = (data) => {
    // console.log("data ", data );
    const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
    let newId = (lastItem === undefined || lastItem === "") ? 0 : lastItem.idArt;
    if (listInitData.length > 0) {
      // Obtener los códigos que ya existen en array1
      const existingCodes = listInitData.map(item => item.CodArt);
      // Filtrar array2 para quedarnos sólo con elementos con códigos nuevos
      let lastId = listInitData[listInitData.length - 1].idArt;
      const newElements = data.filter(item => !existingCodes.includes(item.Code)).map(item => {
        lastId++;
        return {
          idArt: lastId,
          CodArt: item.Code,
          namArt: item.Name
        }
      });
      const result = [...listInitData, ...newElements];
      //console.log("result", result);
      onDataUpdate('bcecategoriacliente', [...result])
      handleCloseGeneral()

    } else {
      console.log("no hay data");
      // const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
      // let newId = (lastItem === undefined || lastItem === "") ? 0 : lastItem.idArt;
      data?.map((row) => {
        newId++;
        return arrDatosInsert?.push({
          "idArt": newId,
          "CodArt": row.Code,
          "namArt": row.Name,
        })
      })
      onDataUpdate('bcecategoriacliente', [...arrDatosInsert])
      handleCloseGeneral()

    }
  }
  let columns = [
    { header: 'Code', accessorKey: 'Code' },
    { header: 'Name', accessorKey: 'Name' }
  ]

  return (
    <>
      <div className="data-tables table-responsive text-nowrap " style={{ position: 'relative', zIndex: '1', width: 'auto', height: '400px', overflowY: 'scroll', overflowX: 'scroll' }}>
        <table className="table table-sm" id='tab_BCEMesa' style={{ fontSize: '10px', zIndex: '2', }}>
          <thead style={{ position: 'sticky', top: 0, zIndex: 10 }}>
            <tr >
              <th>Actions</th>
              <th style={{ width: '350px' }} >Code Cat. Cliente <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger d-none" ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /></th>
              <th style={{ width: '450px' }} >Des. Cat. Cliente  </th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody >
            {
              (arrDatosInsert.length) ? arrDatosInsert.map((row) => (
                <React.Fragment key={row.idArt}>
                  <TRPlantilllaCD key={row.idArt} datoRow={row} />
                  {onValBloqueo ? <div className="d-none"></div> :
                    (!filaActiva || filaActiva === row.idArt) && (
                      <tr key={prosp?.datoRow?.idArt}>
                        <td className='td1'>
                        </td>
                        <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                          <AsyncSelect
                            styles={styles}
                            placeholder="Busque por Cod./Desc."
                            name="form-field-name"
                            className="sele_pru from-control form-control-sm tbCodArticulo"
                            value={slcArticulo}
                            defaultOptions
                            getOptionLabel={e => e.Code + ' - ' + e.Name}
                            getOptionValue={e => e.Code}
                            loadOptions={funDArticulos}
                            onChange={handleSelectChange}
                            maxMenuHeight={150}
                          />
                        </td>
                        <td className='td3'>{slcArticulo.Name}</td>
                        <td className='td4'>
                          <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                          <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info" bt_funct={handleOpenGeneral} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                        </td>
                      </tr>
                    )
                  }
                </React.Fragment>
              ))
                : onValBloqueo ? <div className="d-none"></div> :
                  <tr key={prosp?.datoRow?.idArt}>
                    <td className='td1'>
                    </td>
                    <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                      <AsyncSelect
                        styles={styles}
                        placeholder="Busque por Cod./Desc."
                        name="form-field-name"
                        className="sele_pru from-control form-control-sm tbCodArticulo"
                        value={slcArticulo}
                        defaultOptions
                        getOptionLabel={e => e.Code + ' - ' + e.Name}
                        getOptionValue={e => e.Code}
                        loadOptions={funDArticulos}
                        onChange={handleSelectChange}
                        maxMenuHeight={150}
                      />
                    </td>
                    <td className='td3'>{slcArticulo.Name}</td>
                    <td className='td4'>
                      <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                      <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info" bt_funct={handleOpenGeneral} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                    </td>
                  </tr>
            }
          </tbody>
        </table>
      </div>
      {
        generalModal && (
          <ModalDetallesBoni
            hanOpenModal={generalModal}
            hanClosModal={handleCloseGeneral}
            onReturnData={handleRetrunData}
            onDataEstras={{ "columns": columns, "url": '/apiCategoriaCliente?vcodEmpresa=' + cook_idEmp }}
          />
        )
      }

    </>
  )
}
export const BTCGrupoVentas = (prosp) => {
  const { arrCampDatos, listInitData, onDataUpdate, onHandleVPut, onValCopyDat, onValBloqueo } = prosp

  const [arrDatosInsert, setArrDatosInsert] = useState([]);
  const [filaActiva, setFilaActiva] = useState(null);
  const [slcArticulo, setSlcArticulo] = useState([""]);


  const funDArticulos = useCallback(async (inputValue) => {
    const url = '/apiGrupoVenta?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue; // cambiar la api ahora esta con api de MEsa
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }, [])
  const handleSelectChange = (selectedOption) => {
    setSlcArticulo(selectedOption); // Actualiza el estado si es necesario.
  };
  const onClickInsert = async () => {
    // if (arrCampDatos.txtCod!=="" && arrCampDatos.txtDBon !=="" && slcArticulo.Code !== undefined) {
    if (arrCampDatos.txtDBon !== "" && slcArticulo.Code !== undefined) {
      const datosrepetidos = arrDatosInsert.filter(varr => varr.CodArt === slcArticulo.Code)
      if (datosrepetidos.length === 0) {
        const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
        const newId = (lastItem === undefined || lastItem === "") ? 1 : lastItem.idArt + 1;
        const newItem = { idArt: newId, CodArt: slcArticulo.Code, namArt: slcArticulo.Name };
        notify_valido("Se agrego correctamente!")
        setArrDatosInsert([...arrDatosInsert, newItem]);
        onDataUpdate('bcegrupoventas', [...arrDatosInsert, newItem]) //! el nombre del vector se cambio el vector creado 
        setFilaActiva(newId);
        setSlcArticulo([])
      } else {
        notify_warning('Datos selecionados ya exiten.');
      }
    } else {
      // if (arrCampDatos.txtCod==="" && arrCampDatos.txtDBon==="") {
      if (arrCampDatos.txtDBon === "") {
        notify_warning('Campo descripción es obligatorio no debe estar vacios.');
      } else {
        // if (arrCampDatos.txtCod.trim()==="") { notify_warning('Campo codigo es obligatorio no debe estar vacio.'); }
        if (arrCampDatos.txtDBon.trim() === "") { notify_warning('Campo descripción son obligatorio no debe estar vacio.'); }
        if (!slcArticulo.Code) { notify_warning('Codigo es obligatorio, no debe estar vacio.'); }
      }
    }
  }


  useEffect(() => {
    const lastItem = listInitData[listInitData?.length - 1]
    //console.log("lastItem",lastItem);
    setFilaActiva(lastItem?.idArt)
    setArrDatosInsert(listInitData)
  }, [listInitData])

  const onClickDeletefila = (code) => {
    onHandleVPut("1")
    const myList = [...arrDatosInsert];
    const data = arrDatosInsert.findIndex((res) => (res.CodArt === code));
    myList.splice(data, 1);
    setArrDatosInsert(myList);
    console.log(arrDatosInsert);
    onDataUpdate('bcegrupoventas', myList)
    setSlcArticulo([])

  }

  const TRPlantilllaCD = (prosp) => {
    return (
      <tr key={prosp.datoRow.idArt} >
        <td className='text-center' >
          {onValBloqueo ? <div className="d-none"></div> :
            <AButton tp_tilde="Eliminar" disabled={onValCopyDat ? false : prosp.datoRow.estadoComprometido} bt_funct={() => onClickDeletefila(prosp.datoRow.CodArt)} tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />
          }
        </td>
        <td style={{ position: 'relative', zIndex: '2', maxWidth: '50px' }} >
          {prosp.datoRow.CodArt}</td>
        <td>{prosp.datoRow.namArt}</td>
        <td></td>
      </tr>
    )
  }
  /** Modal */
  const [generalModal, setGeneralModal] = useState(false);
  const handleOpenGeneral = () => { setGeneralModal(true); }
  const handleCloseGeneral = () => { setGeneralModal(false); }
  const handleRetrunData = (data) => {
    // console.log("data ", data );
    const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
    let newId = (lastItem === undefined || lastItem === "") ? 0 : lastItem.idArt;
    if (listInitData.length > 0) {
      // Obtener los códigos que ya existen en array1
      const existingCodes = listInitData.map(item => item.CodArt);
      // Filtrar array2 para quedarnos sólo con elementos con códigos nuevos
      let lastId = listInitData[listInitData.length - 1].idArt;
      const newElements = data.filter(item => !existingCodes.includes(item.Code)).map(item => {
        lastId++;
        return {
          idArt: lastId,
          CodArt: item.Code,
          namArt: item.Name
        }
      });
      const result = [...listInitData, ...newElements];
      //console.log("result", result);
      onDataUpdate('bcegrupoventas', [...result])
      handleCloseGeneral()

    } else {
      console.log("no hay data");
      // const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
      // let newId = (lastItem === undefined || lastItem === "") ? 0 : lastItem.idArt;
      data?.map((row) => {
        newId++;
        return arrDatosInsert?.push({
          "idArt": newId,
          "CodArt": row.Code,
          "namArt": row.Name,
        })
      })
      onDataUpdate('bcegrupoventas', [...arrDatosInsert])
      handleCloseGeneral()

    }
  }
  let columns = [
    { header: 'Code', accessorKey: 'Code' },
    { header: 'Name', accessorKey: 'Name' }
  ]

  return (
    <>
      {/* Hola mundo BCEInicioDetalle 
      <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger " bt_funct={probar2} ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /> */}
      <div className="data-tables table-responsive text-nowrap " style={{ position: 'relative', zIndex: '1', width: 'auto', height: '400px', overflowY: 'scroll', overflowX: 'scroll' }}>
        <table className="table table-sm" id='tab_BCEMesa' style={{ fontSize: '10px', zIndex: '2', }}>
          <thead style={{ position: 'sticky', top: 0, zIndex: 10 }}>
            <tr >
              <th>Actions</th>
              <th style={{ width: '350px' }} >Code Grupo Ventas <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger d-none" ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /></th>
              <th style={{ width: '450px' }} >Des. Grupo Mesa </th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody >
            {
              (arrDatosInsert.length) ? arrDatosInsert.map((row) => (
                <React.Fragment key={row.idArt}>
                  <TRPlantilllaCD key={row.idArt} datoRow={row} />
                  {onValBloqueo ? <div className="d-none"></div> :
                    (!filaActiva || filaActiva === row.idArt) && (
                      <tr key={prosp?.datoRow?.idArt}>
                        <td className='td1'>
                        </td>
                        <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                          <AsyncSelect
                            styles={styles}
                            placeholder="Busque por Cod./Desc."
                            name="form-field-name"
                            className="sele_pru from-control form-control-sm tbCodArticulo"
                            value={slcArticulo}
                            defaultOptions
                            getOptionLabel={e => e.Code + ' - ' + e.Name}
                            getOptionValue={e => e.Code}
                            loadOptions={funDArticulos}
                            onChange={handleSelectChange}
                            maxMenuHeight={150}
                          />
                        </td>
                        <td className='td3'>{slcArticulo.Name}</td>
                        <td className='td4'>
                          <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                          <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info" bt_funct={handleOpenGeneral} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                        </td>
                      </tr>
                    )
                  }
                </React.Fragment>
              ))
                : onValBloqueo ? <div className="d-none"></div> :
                  <tr key={prosp?.datoRow?.idArt}>
                    <td className='td1'>
                    </td>
                    <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                      <AsyncSelect
                        styles={styles}
                        placeholder="Busque por Cod./Desc."
                        name="form-field-name"
                        className="sele_pru from-control form-control-sm tbCodArticulo"
                        value={slcArticulo}
                        defaultOptions
                        getOptionLabel={e => e.Code + ' - ' + e.Name}
                        getOptionValue={e => e.Code}
                        loadOptions={funDArticulos}
                        onChange={handleSelectChange}
                        maxMenuHeight={150}
                      />
                    </td>
                    <td className='td3'>{slcArticulo.Name}</td>
                    <td className='td4'>
                      <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                      <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info" bt_funct={handleOpenGeneral} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                    </td>
                  </tr>
            }
          </tbody>
        </table>
      </div>
      {
        generalModal && (
          <ModalDetallesBoni
            hanOpenModal={generalModal}
            hanClosModal={handleCloseGeneral}
            onReturnData={handleRetrunData}
            onDataEstras={{ "columns": columns, "url": '/apiLstGrupoVenta?vcodEmpresa=' + cook_idEmp }}
          />
        )
      }

    </>
  )
}
export const BTCCondicionesPago = (prosp) => {
  const { arrCampDatos, listInitData, onDataUpdate, onHandleVPut, onValCopyDat, onValBloqueo } = prosp

  const [arrDatosInsert, setArrDatosInsert] = useState([]);
  const [filaActiva, setFilaActiva] = useState(null);
  const [slcArticulo, setSlcArticulo] = useState([""]);


  const funDArticulos = useCallback(async (inputValue) => {
    const url = '/apiCondicionPagos?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue; // cambiar la api 
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }, [])
  const handleSelectChange = (selectedOption) => {
    setSlcArticulo(selectedOption); // Actualiza el estado si es necesario.
  };
  const onClickInsert = async () => {
    // if (arrCampDatos.txtCod!=="" && arrCampDatos.txtDBon !=="" && slcArticulo.Code !== undefined) {
    if (arrCampDatos.txtDBon !== "" && slcArticulo.Code !== undefined) {
      const datosrepetidos = arrDatosInsert.filter(varr => varr.CodArt === slcArticulo.Code)
      if (datosrepetidos.length === 0) {
        const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
        const newId = (lastItem === undefined || lastItem === "") ? 1 : lastItem.idArt + 1;
        const newItem = { idArt: newId, CodArt: slcArticulo.Code, namArt: slcArticulo.Name };

        // const url = '/apiBuuInsert'
        // const body = { numTab:6, arrCabecera: arrCampDatos, arrDetalle: newItem};
        // const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
        // const response = await fetch(url,opPe);
        // const resdatos = await response.json();

        // if (resdatos.vl_api === 1){
        //   notify_warning("Error al agregar o registro ya eXiste, verificar datos!")
        // }
        // if (resdatos.vl_existe === 1){
        //   notify_warning("Registro ya eXiste!")
        // }
        // if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1 ){
        notify_valido("Se agrego correctamente!")
        setArrDatosInsert([...arrDatosInsert, newItem]);
        onDataUpdate('bcecondicionespago', [...arrDatosInsert, newItem]) //! el nombre del vector se cambio el vector creado 
        setFilaActiva(newId);
        setSlcArticulo([])
        //}        
      } else {
        notify_warning('Datos selecionados ya exiten.');
      }
    } else {
      // if (arrCampDatos.txtCod==="" && arrCampDatos.txtDBon==="") {
      if (arrCampDatos.txtDBon === "") {
        notify_warning('Campo descripción es obligatorio no debe estar vacio.');
      } else {
        // if (arrCampDatos.txtCod.trim()==="") { notify_warning('Campo codigo es obligatorio no debe estar vacio.'); }
        if (arrCampDatos.txtDBon.trim() === "") { notify_warning('Campo descripción son obligatorio no debe estar vacio.'); }
        if (!slcArticulo.Code) { notify_warning('Codigo es obligatorio, no debe estar vacio.'); }
      }
    }
  }


  useEffect(() => {
    const lastItem = listInitData[listInitData?.length - 1]
    //console.log("lastItem",lastItem);
    setFilaActiva(lastItem?.idArt)
    setArrDatosInsert(listInitData)
  }, [listInitData])

  const onClickDeletefila = (code) => {
    onHandleVPut("1")
    const myList = [...arrDatosInsert];
    const data = arrDatosInsert.findIndex((res) => (res.CodArt === code));
    myList.splice(data, 1);
    setArrDatosInsert(myList);
    console.log(arrDatosInsert);
    onDataUpdate('bcecondicionespago', myList)
    setSlcArticulo([])

  }

  const TRPlantilllaCD = (prosp) => {
    return (
      <tr key={prosp.datoRow.idArt} >
        <td className='text-center' >
          {onValBloqueo ? <div className="d-none"></div> :
            <AButton tp_tilde="Eliminar" disabled={onValCopyDat ? false : prosp.datoRow.estadoComprometido} bt_funct={() => onClickDeletefila(prosp.datoRow.CodArt)} tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />
          }
        </td>
        <td style={{ position: 'relative', zIndex: '2', maxWidth: '50px' }} >
          {prosp.datoRow.CodArt}</td>
        <td>{prosp.datoRow.namArt}</td>
        <td></td>
      </tr>
    )
  }

  return (
    <>
      {/* Hola mundo BCEInicioDetalle 
      <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger " bt_funct={probar2} ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /> */}
      <div className="data-tables table-responsive text-nowrap " style={{ position: 'relative', zIndex: '1', width: 'auto', height: '400px', overflowY: 'scroll', overflowX: 'scroll' }}>
        <table className="table table-sm" id='tab_BCEMesa' style={{ fontSize: '10px', zIndex: '2', }}>
          <thead style={{ position: 'sticky', top: 0, zIndex: 10 }}>
            <tr >
              <th>Actions</th>
              <th style={{ width: '350px' }} >Code Cond. Pago <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger d-none" ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /></th>
              <th style={{ width: '450px' }} >Des. Cond. Pago </th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody >
            {
              (arrDatosInsert.length) ? arrDatosInsert.map((row) => (
                <React.Fragment key={row.idArt}>
                  <TRPlantilllaCD key={row.idArt} datoRow={row} />
                  {onValBloqueo ? <div className="d-none"></div> :
                    (!filaActiva || filaActiva === row.idArt) && (
                      <tr key={prosp?.datoRow?.idArt}>
                        <td className='td1'>
                        </td>
                        <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                          <AsyncSelect
                            styles={styles}
                            placeholder="Busque por Cod./Desc."
                            name="form-field-name"
                            className="sele_pru from-control form-control-sm tbCodArticulo"
                            value={slcArticulo}
                            defaultOptions
                            getOptionLabel={e => e.Code + ' - ' + e.Name}
                            getOptionValue={e => e.Code}
                            loadOptions={funDArticulos}
                            onChange={handleSelectChange}
                            maxMenuHeight={150}
                          />
                        </td>
                        <td className='td3'>{slcArticulo.Name}</td>
                        <td className='td4'>
                          <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                        </td>
                      </tr>
                    )
                  }
                </React.Fragment>
              ))
                : onValBloqueo ? <div className="d-none"></div> :
                  <tr key={prosp?.datoRow?.idArt}>
                    <td className='td1'>
                    </td>
                    <td className='td2' style={{ position: 'relative', zIndex: '3' }} >

                      <AsyncSelect
                        styles={styles}
                        placeholder="Busque por Cod./Desc."
                        name="form-field-name"
                        className="sele_pru from-control form-control-sm tbCodArticulo"
                        value={slcArticulo}
                        defaultOptions
                        getOptionLabel={e => e.Code + ' - ' + e.Name}
                        getOptionValue={e => e.Code}
                        loadOptions={funDArticulos}
                        onChange={handleSelectChange}
                        maxMenuHeight={150}
                      />
                    </td>
                    <td className='td3'>{slcArticulo.Name}</td>
                    <td className='td4'>
                      <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                    </td>
                  </tr>
            }
          </tbody>
        </table>
      </div>


    </>
  )
}
export const BTCVendedoresExcluidos = (prosp) => {
  const { arrCampDatos, listInitData, onDataUpdate, onHandleVPut, onValCopyDat, onValBloqueo } = prosp
 
 const [arrDatosInsert, setArrDatosInsert] = useState([]);
 const [filaActiva, setFilaActiva] = useState(null);
 const [slcArticulo, setSlcArticulo] = useState([""]);
 const selectRowRef = useRef(null);


 const funDArticulos = useCallback( async (inputValue) => {
   const url ='/apiCodeVendedores?vcodEmpresa='+cook_idEmp+'&vtxtConsult='+inputValue;
   const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
   const response = await fetch(url,opPe);
   const data = await response.json();
   return data;
 },[])
 const handleSelectChange = (selectedOption) => {
   setSlcArticulo(selectedOption); // Actualiza el estado si es necesario.
 };
 const onClickInsert =  async()=>{

   // if (arrCampDatos.txtCod!=="" && arrCampDatos.txtDBon !=="" && slcArticulo.Code !== undefined) {     
   if ( arrCampDatos.txtDBon !=="" && slcArticulo.Code !== undefined
   ) {     
     const datosrepetidos = arrDatosInsert.filter(varr => varr.CodArt === slcArticulo.Code )
     if (datosrepetidos.length === 0) {
       const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
       const newId = (lastItem===undefined || lastItem==="" ) ? 1 : lastItem.idArt + 1;
       const newItem = { idArt: newId, CodArt:slcArticulo.Code, namArt:slcArticulo.Name};

       notify_valido("Se agrego correctamente!")
       setArrDatosInsert([...arrDatosInsert,newItem]);
       onDataUpdate('bcevendedoresexcluidos',[...arrDatosInsert,newItem])
       setFilaActiva(newId);
       setSlcArticulo([])  
       setTimeout(() => { selectRowRef.current.focus() }, 500);
     } else {
       notify_warning('Datos selecionados ya exiten.');
     }
   } else {
     //if (arrCampDatos.txtCod==="" && arrCampDatos.txtDBon==="") {
     if (arrCampDatos.txtDBon==="") {
       notify_warning('Campo descripción es obligatorio, no debe estar vacio.');
     }else{
       //if (arrCampDatos.txtCod.trim()==="") { notify_warning('Campo codigo es obligatorio no debe estar vacio.'); }
       if (arrCampDatos.txtDBon.trim()===""){ notify_warning('Campo descripción son obligatorio no debe estar vacio.'); }
       if (!slcArticulo.Code){ notify_warning('Codigo es obligatorio, no debe estar vacio.'); }
     }
   }
 }
 
 useEffect (() => {
   const lastItem = listInitData[listInitData?.length - 1];
   setFilaActiva(lastItem?.idArt)
   setArrDatosInsert(listInitData)
 },[listInitData])

 const onClickDeletefila = (code) => {
   onHandleVPut("1")
   const myList = [...arrDatosInsert];
   const data = arrDatosInsert.findIndex((res) => (res.CodArt === code));
   myList.splice(data, 1);
   setArrDatosInsert(myList);
   //console.log(arrDatosInsert);
   onDataUpdate('bcevendedordetall',myList)
   setSlcArticulo([])
   
 }
 const TRPlantilllaCD = (prosp) => {
   return (
     <tr key={prosp.datoRow.idArt} >
       <td className='text-center' >
         { onValBloqueo ? <div className="d-none"></div> :
           <AButton tp_tilde="Eliminar" disabled={onValCopyDat ? false : prosp.datoRow.estadoComprometido} bt_funct={()=> onClickDeletefila(prosp.datoRow.CodArt)} tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />
         }
       </td> 
       <td style={{ position: 'relative', zIndex: '2',maxWidth:'50px' }} >
         {prosp.datoRow.CodArt}</td>
       <td>{prosp.datoRow.namArt}</td>
       <td></td>
     </tr>
   )
 }

 /** Modal */
 const [generalModal, setGeneralModal] = useState(false);
 const handleOpenGeneral = () => { 
   // if (arrCampDatos.txtCod!=="" && arrCampDatos.txtDBon !=="") { 
   if (arrCampDatos.txtDBon !=="") { 
     setGeneralModal(true); 
   } else {
     // if (arrCampDatos.txtCod==="" && arrCampDatos.txtDBon==="") {
     if (arrCampDatos.txtDBon==="") {
       notify_warning('Campo descripción es obligatorio, no debe estar vacio.');
     }else{
       // if (arrCampDatos.txtCod.trim()==="") { notify_warning('Campo codigo es obligatorio no debe estar vacio.'); }
       if (arrCampDatos.txtDBon.trim()===""){ notify_warning('Campo descripción son obligatorio no debe estar vacio.'); }
     }
   }
 }
 const handleCloseGeneral = () => { setGeneralModal(false); }
 const handleRetrunData= (data) => {
   // console.log("data ", data );
   const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
   let newId = (lastItem === undefined || lastItem === "") ? 0 : lastItem.idArt;
   if (listInitData.length > 0 ) {
     // Obtener los códigos que ya existen en array1
     const existingCodes = listInitData.map(item => item.CodArt);
     // Filtrar array2 para quedarnos sólo con elementos con códigos nuevos
     let lastId = listInitData[listInitData.length - 1].idArt;
     const newElements = data.filter(item => !existingCodes.includes(item.Code)).map(item => {
       lastId++;
       return {
         idArt: lastId,
         CodArt: item.Code,
         namArt: item.Name
       }
     });
     const result = [...listInitData, ...newElements];
     //console.log("result", result);
     onDataUpdate('bcevendedoresexcluidos',[...result])
     handleCloseGeneral()

   }else{
     //console.log("no hay data");
     // const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
     // let newId = (lastItem === undefined || lastItem === "") ? 0 : lastItem.idArt;
     data?.map((row) => {
        newId++;
        return arrDatosInsert?.push({
          "idArt": newId,
          "CodArt": row.Code,
          "namArt": row.Name,
        })
     })
     onDataUpdate('bcevendedoresexcluidos',[...arrDatosInsert])
     handleCloseGeneral()

   }
 }
 let columns  = [
   { header: 'Code', accessorKey: 'Code'},
   { header: 'Name', accessorKey: 'Name' }
 ]

 return (
   <>
     <div className="data-tables table-responsive text-nowrap "  style={{position: 'relative', zIndex: '1', width:'auto', height:'400px', overflowY: 'scroll', overflowX: 'scroll'}}>
       <table className="table table-sm" id='tab_BCEInicioDetalle' style={{fontSize:'10px', zIndex: '2',}}>
         <thead style={{ position: 'sticky', top: 0, zIndex: 10 }}>
           <tr >
             <th>Actions</th>
             <th style={{width:'350px'}} >Code Vendedor <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger d-none" ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /></th>
             <th style={{width:'450px'}} >Des. vendedor</th>
             <th>Opciones</th>
           </tr>
         </thead>
         <tbody >
           {
             (arrDatosInsert.length) ? arrDatosInsert.map((row) => (
               <React.Fragment key={row.idArt}>
                 <TRPlantilllaCD key={row.idArt} datoRow={row} />
                 {
                 onValBloqueo ? <div className="d-none"></div> :
                 (!filaActiva || filaActiva === row.idArt) && (
                   <tr key={prosp?.datoRow?.idArt}>
                     <td className='td1'>
                     </td> 
                     <td className='td2' style={{ position: 'relative', zIndex: '3' }} >
                       
                       <AsyncSelect 
                         ref={selectRowRef}
                         styles={styles}
                         placeholder="Busque por Cod./Desc."
                         name="form-field-name"
                         className="sele_pru from-control form-control-sm tbCodArticulo"  
                         value={slcArticulo}
                         defaultOptions
                         getOptionLabel={e => e.Code +' - '+ e.Name}
                         getOptionValue={e => e.Code }
                         loadOptions={funDArticulos}
                         onChange={handleSelectChange}
                         maxMenuHeight={150}
                       />
                     </td>
                     <td className='td3'>{slcArticulo.Name}</td>
                     <td className='td4'>
                       <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success "  bt_funct={onClickInsert}  ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                       <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info"  bt_funct={handleOpenGeneral}  ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                     </td>
                   </tr>
                 )
                 }
               </React.Fragment>
             )) 
             : 
             onValBloqueo ? <div className="d-none"></div> :
             <tr key={prosp?.datoRow?.idArt}>
               <td className='td1'>
               </td> 
               <td className='td2' style={{ position: 'relative', zIndex: '3' }} >
                 
                 <AsyncSelect 
                   styles={styles}
                   placeholder="Busque por Cod./Desc."
                   name="form-field-name"
                   className="sele_pru from-control form-control-sm tbCodArticulo"  
                   value={slcArticulo}
                   defaultOptions
                   getOptionLabel={e => e.Code +' - '+ e.Name}
                   getOptionValue={e => e.Code }
                   loadOptions={funDArticulos}
                   onChange={handleSelectChange}
                   maxMenuHeight={150}
                 />
               </td>
               <td className='td3'>{slcArticulo.Name}</td>
               <td className='td4'>
                 <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success "  bt_funct={onClickInsert}  ic_class="bx bx-ms bx-send" vl_tpbtn={1} />
                 <AButton tp_tilde="Mas Detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info"  bt_funct={handleOpenGeneral}  ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
               </td>
             </tr>
           }
         </tbody>
       </table>
     </div>
     {
       generalModal && (
         <ModalDetallesBoni
           hanOpenModal = {generalModal}
           hanClosModal = {handleCloseGeneral}
           onReturnData = {handleRetrunData}
           onDataEstras = {{"columns":columns,"url":'/apiListaCodeVendedores?vcodEmpresa='+cook_idEmp}}
         />
       )
     }

   </>
 )
}

const BONTicketCompra = (prosp) => {
  const { dataid, onDataUpdate2  } = prosp
  /** Inicio :> Datos para validar */
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const cod = searchParams.get('cod')
  const tab = searchParams.get('tab');
  const tip = searchParams.get('tip');
  /** Fin :> Datos para validar */
  const [listTabs, setListTabs] = useState({
    id1: '',id2: '',id3: '',id4: '',id5: '', id6: '', id7: '', id8: '', id9: '', id10: '', id11: '', id12: '', id13: ''
  })
  const [listDatos, setListDatos] = useState({
    txtCod: '',
    txtDBon: '',
    txtActi: '',
    txtFIni: new Date(),
    txtFFin: new Date(),
    txtBode: '',
    txtMayo: '',
    txtMerc: '',
    txtMinimar: '', 
    txttipi: 'N'
  })
  const [listDataUstMemori, setlistDataUstMemori] = useState({
    bceiniciodetallador: [],
    bcecliente: [],
    bcegrupoarticulo: [],
    bcevendedordetall: [],
    bcesucursaldetll: [],
    bcecatcliente: [],
    bcemesadetall: [],
    bceobsequiodetll: [],
    bcecategoriacliente: [],
    bcegrupoventas: [],
    bcecondicionespago: [],
    bcevendedoresexcluidos:[]
  })
  const [valCopy, setValCopy] = useState(false);
  const [valBloq, setValBloq] = useState(false);
  const [disabledb1, setdisabledb1] = useState(false);
  const [disabledb2, setdisabledb2] = useState(false);
  const [btnVisible1, setBtnVisible1] = useState(false);
  const [btnVisible2, setBtnVisible2] = useState(false);

  const [validarPut, setValidarPut] = useState('0');

  const handleFieldChange = (fieldName) => (event) => {
    const value = event.target.value;
    setListDatos({
      ...listDatos,
      [fieldName]: value,
    });
  };
  const handleDateChange1 = (date) => {
    setListDatos({
      ...listDatos,
      txtFIni: date, // Actualiza el valor de la fecha seleccionada
    });
  };
  const handleDateChange2 = (date) => {
    setListDatos({
      ...listDatos,
      txtFFin: date, // Actualiza el valor de la fecha seleccionada
    });
  };
  const handleKeyDown = async (e, cod, tip) => {
    if ((e && e.key === 'Enter') || cod) {
      if (e) { e.preventDefault(); }

      Swal.fire({
        title: 'Procesando...',
        html: 'Se esta consultado datos.',
        didOpen: () => { Swal.showLoading() },
        customClass: { container: 'my-swal' },
        allowOutsideClick: false
      })
      const url = '/apiBtc'
      const body = { vcodEmpresa:cook_idEmp, vlCodigo: (cod) ? cod : listDatos.txtCod };
      const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
      const response = await fetch(url, opPe);
      const resdatos = await response.json();
      //console.log("resdatos", resdatos);
      if (resdatos.length) {
        setBtnVisible1(true)
        setListDatos({
          ...listDatos,
          //txtCod:resdatos[0].txtCod,
          txtCod: (tip === "copy") ? '' : resdatos[0].txtCod,
          txtDBon: resdatos[0].txtDBon,
          txtActi: resdatos[0].txtActi,
          txtFIni: new Date(moment(resdatos[0].txtFIni, 'Y-M-D').format('YYYY-MM-DD HH:mm:ss')),
          txtFFin: new Date(moment(resdatos[0].txtFFin, 'Y-M-D').format('YYYY-MM-DD HH:mm:ss')),
          txtBode: resdatos[0].txtBode,
          txtMayo: resdatos[0].txtMayo,
          txtMerc: resdatos[0].txtMerc,
          txtMinimar: resdatos[0].txtMinimar, 

          txttipi: 'N'
        });
        setlistDataUstMemori({
          ...listDataUstMemori,
          bceiniciodetallador: resdatos[0]?.arrDetalle1,
          bcecliente: resdatos[0]?.arrDetalle2,
          bcegrupoarticulo: resdatos[0]?.arrDetalle3,
          bcevendedordetall: resdatos[0]?.arrDetalle4,
          bcesucursaldetll: resdatos[0]?.arrDetalle5,
          bcemesadetall: resdatos[0]?.arrDetalle6,
          bceobsequiodetll: resdatos[0]?.arrDetalle8,
          bcecategoriacliente: resdatos[0]?.arrDetalle9, 
          bcegrupoventas: resdatos[0]?.arrDetalle10,
          bcecondicionespago: resdatos[0]?.arrDetalle11,
          bcevendedoresexcluidos:resdatos[0]?.arrDetalle12,
        })
        setBtnVisible2(true)
      } else {
        notify_warning('Codigo no existe en nuestra base de datos.');
        onClickLimpiar()
      }
      Swal.close();


    }
  };
  const handleDataFromChild = (id, data) => {
    setlistDataUstMemori({
      ...listDataUstMemori,
      [id]: data
    });
  }
  const onClickActualizar = async () => {
    setdisabledb2(true)
    const url = '/apiBtcCabecera'
    const body = { vcodEmpresa:cook_idEmp, arrCabecera: listDatos };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url, opPe);
    const resdatos = await response.json();
    if (resdatos.vl_api === 1) {
      notify_warning("Error al actualizar, verificar datos!")
    }
    if (resdatos.vl_existe === 1) {
      notify_warning("Ya esta actualizado!")
    }
    if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1) {
      notify_valido("Se actualizo correctamente!")
    }
    setdisabledb2(false)
  }
  const validarDescripcion = async (descrip, namUdo) => {
    try {
      // Esta funcion valida si la descripcion de la bni ya fue resgistrada, con el fin de porder 
      // evitar duplicados
      // Retorna verdadero si la descripcion es unica
      setdisabledb2(true)
      const url = '/apiConsulDescripBoD'
      const body = {
        vcodEmpresa: cook_idEmp,
        vNomUdo: namUdo,
        vDesUdo: descrip
      };
      const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
      const response = await fetch(url, opPe);
      const resdatos = await response.json();
      const cantidad = resdatos[0].Count
      if (parseInt(cantidad) === 0) {
        return true
      }
      // console.log("Res ::  [validarDescripcion ] :: ", cantidad);
      return false;

    } catch (error) {
      notify_error('No se pudo validar la Descripcion de la bonificacion')
      return false;
    }
  }
  const onclickCrearG = async () => {
    try {
      // Verificar si el campo está vacío
      if (listDatos.txtDBon.trim() === "") {
        notify_warning('Campo descripción es obligatorio, no debe estar vacío.');
        return;
      }
      /**const descripcionValida = await validarDescripcion(listDatos.txtDBon.trim(), 'AB_BCTC');
      if (descripcionValida === false) {
        notify_warning("Ya se ha registrado una bonificación con esta descripción.");
        return;
      }**/

      setdisabledb1(true)
      if (
          listDatos.txtDBon !== "" && listDatos.txtActi !=="" && 
          listDatos.txtBode !== "" && listDatos.txtMayo !=="" && 
          listDatos.txtMerc !=="" && listDatos.txtMinimar !==""
          ) {
            //(listDataUstMemori.bcecategoriacliente.length >= 1) ||
        if (
          (listDataUstMemori.bceiniciodetallador.length >= 1) ||
          (listDataUstMemori.bcecliente.length >= 1) ||
          (listDataUstMemori.bcegrupoarticulo.length >= 1) ||
          (listDataUstMemori.bcevendedordetall.length >= 1) ||
          (listDataUstMemori.bcesucursaldetll.length >= 1) ||
          (listDataUstMemori.bcemesadetall.length >= 1) ||
          (listDataUstMemori.bceobsequiodetll.length >= 1) ||
          (listDataUstMemori.bcegrupoventas.length >= 1) ||
          (listDataUstMemori.bcecondicionespago.length >= 1) ||
          (listDataUstMemori.bcevendedoresexcluidos.length >= 1)
        ) {
          //&& listDataUstMemori.bcecategoriacliente.length >= 1
          if (
              listDataUstMemori.bceobsequiodetll.length >= 1 && 
              listDataUstMemori.bcesucursaldetll.length >= 1 
            ) {
            const url = '/apiBtcInsert'
            const body = { vcodEmpresa: cook_idEmp, arrCabecera: listDatos, arrDetalle: listDataUstMemori, validarPut: validarPut };
            const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
            const response = await fetch(url, opPe);
            const resdatos = await response.json();
            if (resdatos.vl_api === 1) {
              notify_warning("Error al registrar, verificar datos!")
            }
            if (resdatos.vl_existe === 1) {
              notify_warning("Ya esta registrado!")
            }
            if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1) {
              notify_valido("Se registro correctamente!")
              setBtnVisible1(true)
              setBtnVisible2(true)
              if (tip === "copy") {
                setValCopy(false)
                updateParUrl('edit', tab, resdatos.vl_codres)
              } else {
                setListDatos(() => ({
                  ...listDatos,
                  txtCod: resdatos.vl_codres
                }));
              }
            }
          } else { 
            if(listDataUstMemori.bceobsequiodetll.length === 0) {notify_warning('Se requiere Obsequio.'); } 
            if(listDataUstMemori.bcesucursaldetll.length === 0) {notify_warning('Se requiere Sucursal.'); } 
            //if(listDataUstMemori.bcecategoriacliente.length === 0) {notify_warning('Se requiere Categoria Cliente.'); } 
          }
        } else {
          notify_warning('En niguno de los tabs hay filas agregadas, por favor agregar minimo 1 fila en un tab.');
        }
      } else {
        if(listDatos.txtDBon==="") { notify_warning('Campo descripción es obligatorio, no debe estar vacio.');}
        if(listDatos.txtActi==="") { notify_warning('Seleccione Activo');}
        if(listDatos.txtBode==="") { notify_warning('Seleccione Bodegas');}
        if(listDatos.txtMayo==="") { notify_warning('Seleccione Mayoritas');}
        if(listDatos.txtMerc==="") { notify_warning('Seleccione Mercados');}
        if(listDatos.txtMinimar==="") { notify_warning('Seleccione minimarket');}
      }
      setdisabledb1(false)
    } catch(e){
      console.log("error", e);
      notify_error('Error, comunicarse con la area de TI');
      setdisabledb1(false)
    }
  }
  const onClickLimpiar = async () => {
    cookies.remove('interBloq');
    setListDatos({
      txtCod: '',
      txtDBon: '',
      txtActi: '',
      txtFIni: new Date(),
      txtFFin: new Date(),
      txtBode: '',
      txtMayo: '',
      txtMerc: '',
      txtMinimar: '',
    })

    setlistDataUstMemori({
      bceiniciodetallador: [],
      bcecliente: [],
      bcegrupoarticulo: [],
      bcevendedordetall: [],
      bcesucursaldetll: [],
      bcecatcliente: [],
      bcemesadetall: [],
      bceobsequiodetll: [],
      bcecategoriacliente: [],
      bcegrupoventas: [],
      bcecondicionespago: [],
      bcevendedoresexcluidos:[]
    })

    setBtnVisible1(false)
    setBtnVisible2(false)
    notify_valido("Campos de cabecera y tablas de tab se limpiaron correctamente!")
  }
  const onClickLimpiar1 = async () => {
    setListDatos({
      txtCod: '',
      txtDBon: '',
      txtActi: '',
      txtFIni: new Date(),
      txtFFin: new Date(),
      txtBode: '',
      txtMayo: '',
      txtMerc: '',
      txtMinimar: ''
    })

    setlistDataUstMemori({
      bceiniciodetallador: [],
      bcecliente: [],
      bcegrupoarticulo: [],
      bcevendedordetall: [],
      bcesucursaldetll: [],
      bcecatcliente: [],
      bcemesadetall: [],
      bceobsequiodetll: [],
      bcecategoriacliente: [],
      bcegrupoventas: [],
      bcecondicionespago: [],
      bcevendedoresexcluidos:[]
    })

    setBtnVisible1(false)
    setBtnVisible2(false)
  }
  const updateParUrl = (etip, etab, ecod) => {
    const newParams = { tip: etip, tab: etab, cod: ecod };
    navigate({
      pathname: window.location.pathname,
      search: '?' + new URLSearchParams(newParams).toString()
    });
  }
  const onClickEnviarDatos = async() => {
    onDataUpdate2('bondatosReglas',{'rtxtCod':listDatos?.txtCod,'rtxtDesc':listDatos?.txtDBon})
    setTimeout(()=>{
      onClickLimpiar1()
      $(".closeModal").click()
    },200)
  }


  const [disabledEdit, setDisabledEdit] = useState(false)
  useEffect(() => {
    if (cod && tab === 'bonticketcompra') {
      setTimeout(() => {
        setListDatos(() => ({
          ...listDatos,
          txtCod: cod
        }));
      }, 1000)

      if (tip === "ver") {
        setValBloq(true)
        $("input").attr("disabled", true)
        $("select").attr("disabled", true)
      } else {
        setValBloq(false)
      }
      if (tip === "edit") { setDisabledEdit(true) }

      setTimeout(() => {
        (tip === "copy") ? setValCopy(true) : setValCopy(false)
        handleKeyDown(null, cod, tip);
      }, 2000)
    }else{ cookies.remove('boniValidarEstado'); }

    /** Mostrar datos en modal */
    if (dataid) {
      //console.log("dataid?.codigo", dataid?.codigo);
      onClickLimpiar1()

      if (dataid.codigo!=="") {
        setTimeout(() => {
          console.log("hola", dataid.codigo)
          setListDatos(() => ({
            ...listDatos,
            txtCod: dataid.codigo,
            txtDBon: ''
          }));
        }, 1000)
        setTimeout(() => {
          handleKeyDown(null,dataid.codigo,'');
        }, 2000)
        
      }else{
        onClickLimpiar1()
        setBtnVisible1(false)
      }
      setListTabs(dataid)
    }

  }, [cod, tab, tip, dataid])

  const [listSubTabsVisu, setListSubTabsVisu] = useState({ id1: false, id2: false, id3: false, id4: false, id5: false, id6: false, id7: false, id8: false, id9: false, id10: false, id11: false })
  const clickSubTab = (valor) => {
    setListSubTabsVisu({
      id1: (valor === '1') ? true : false,
      id2: (valor === '2') ? true : false,
      id3: (valor === '3') ? true : false,
      id4: (valor === '4') ? true : false,
      id5: (valor === '5') ? true : false,
      id6: (valor === '6') ? true : false,
      id7: (valor === '7') ? true : false,
      id8: (valor === '8') ? true : false,
      id9: (valor === '9') ? true : false,
      id10: (valor === '10') ? true : false,
      id11: (valor === '11') ? true : false,
    })
    //alert("funciona")
  }
  const onHandleVPut = (dato) => {
    setValidarPut(dato)
  }
   /** Inicio :> Modal de consulta */
   const [dtEPorE, setDtEPorE] = useState([]);
   const onclickMostrarEntePorEntre = async() => {
     const url = '/apiEntrePorEntre?vcodEmpresa=' + cook_idEmp + '&tpudo=BCTC&codigo=' + listDatos.txtCod
     const opPe9 = { method: "GET", headers: { 'Content-Type': 'application/json' } }
     const resp = await fetch(url, opPe9);
     const data = await resp.json();
     setDtEPorE(data)
   }
   const [generalModalUni, setGeneralModalUni] = useState(false);
   const handleOpen = async () => {
     onclickMostrarEntePorEntre()
     setGeneralModalUni(true);
   }
   const handleClose = () => {
     setGeneralModalUni(false);
   }
   /** Fin :> Modal de consulta */
  return (
    <>
      <div className="row ">
        <div className="col-md-1">Codigo:</div>
        <div className="col-md-3">
          <input type="text" id="txtCodT" name="txtCodT" className="form-control form-control-sm" autoComplete='off' value={listDatos.txtCod} onChange={handleFieldChange('txtCod')} onKeyDown={handleKeyDown} disabled/>
        </div>
        <div className="col-md-1">D.Bonif:</div>
        <div className="col-md-7">
          <input type="text" id="txtDBonT" name="txtDBonT" className="form-control form-control-sm" autoComplete='off' value={listDatos.txtDBon} onChange={handleFieldChange('txtDBon')} />
        </div>

      </div>
      <div className="row ">
        <div className="col-md-1">Inicio:</div>
        <div className="col-md-3" style={{ position: 'relative', zIndex: '2' }}>
          <DatePicker
            id="txtFIniT"
            locale="es"
            wrapperClassName='input_date'
            className="form-control form-control-sm text-center"
            selected={listDatos.txtFIni}
            onChange={handleDateChange1}
            dateFormat="yyyy-MM-dd"
          />
          {/* <input type="text" id="txtFIni" name="txtFIni" className="form-control form-control-sm"  autoComplete='off' value={listDatos.txtFIni} onChange={handleFieldChange('txtFIni')}/> */}
        </div>
        <div className="col-md-1">Fin:</div>
        <div className="col-md-3" style={{ position: 'relative', zIndex: '2' }}>
          <DatePicker
            id="txtFFinT"
            locale="es"
            wrapperClassName='input_date'
            className="form-control form-control-sm text-center"
            selected={listDatos.txtFFin}
            onChange={handleDateChange2}
            dateFormat="yyyy-MM-dd"
          />
          {/* <input type="text" id="txtFFin" name="txtFFin" className="form-control form-control-sm"  autoComplete='off' value={listDatos.txtFFin} onChange={handleFieldChange('txtFFin')}/> */}
        </div>
        <div className="col-md-1">Activo:</div>
        <div className="col-md-3">
          <select id="txtActiT" name="txtActiT" className="form-select form-select-sm" value={listDatos.txtActi} onChange={handleFieldChange('txtActi')} disabled={disabledEdit} >
            <option value={""} >{"<<Selecciona>>"}</option>
            <option value={"Y"} >Si</option>
            <option value={"N"} >No</option>
          </select>
        </div>

      </div>
      <div className="row mb-1">
        <div className="col-md-1">Bodega:</div>
        <div className="col-md-3">
          <select id="txtBode" name="txtBode" className="form-select form-select-sm" value={listDatos.txtBode} onChange={handleFieldChange('txtBode')} >
            <option value={""} >{"<<Selecciona>>"}</option>
            <option value={"Y"} >Si</option>
            <option value={"N"} >No</option>
          </select>
        </div>
        <div className="col-md-1">Mayorista:</div>
        <div className="col-md-3">
          <select id="txtMayoT" name="txtMayoT" className="form-select form-select-sm" value={listDatos.txtMayo} onChange={handleFieldChange('txtMayo')} >
            <option value={""} >{"<<Selecciona>>"}</option>
            <option value={"Y"} >Si</option>
            <option value={"N"} >No</option>
          </select>
        </div>
        <div className="col-md-1">Mercados:</div>
        <div className="col-md-3">
          <select id="txtMercT" name="txtMercT" className="form-select form-select-sm" value={listDatos.txtMerc} onChange={handleFieldChange('txtMerc')} >
            <option value={""} >{"<<Selecciona>>"}</option>
            <option value={"Y"} >Si</option>
            <option value={"N"} >No</option>
          </select>
        </div>
        <div className="col-md-1">Minimarket</div>
        <div className="col-md-3">
          <select id="txtMinimar" name="txtMinimar" className="form-select form-select-sm"  value={listDatos.txtMinimar} onChange={handleFieldChange('txtMinimar')} >
            <option value={""} >{"<<Selecciona>>"}</option>
            <option value={"Y"} >Si</option>
            <option value={"N"} >No</option>
          </select>
        </div>
      </div>
      {
        valBloq ? <div className="d-none"></div> :

          <div className="row mb-2">
            <div className="col-lg-4"></div>
            <div className="col-lg-8 text-end">
              {
                btnVisible2 && (
                  <AButton id="btn_actualizar" tp_tilde="Actualizar Cabecera" bt_descr="Actualizar Cabecera" tp_posit="top" bt_class="btn btn-sm btn-label-success " bt_funct={onClickActualizar} ic_class="bx bx-ms bx-send" vl_tpbtn={2} disabled={disabledb2} />
                )
              }
              <AButton id="btn_LimpiarTod" tp_tilde="Limpiar campos y tablas" bt_descr="Limpiar" tp_posit="top" bt_class="btn btn-sm btn-label-secondary" bt_funct={onClickLimpiar} ic_class="bx bx-ms bx-eraser" vl_tpbtn={1} />
              {
                btnVisible2 && (
                  <AButton id="btn_ModalEPorE" tp_tilde="Consultar entregado y por entregar" tp_posit="top" bt_class="btn btn-sm btn-label-info" bt_funct={handleOpen} ic_class="bx bx-ms bx-bong" vl_tpbtn={1} />
                )
              }
            </div>
          </div>
      }
      {
        generalModalUni && (
          <ModalBEPorE
            hanOpenModal={generalModalUni}
            hanClosModal={handleClose}
            onDataEstras={dtEPorE}
          />)
      }
      

      <div className="row ">
        <div className="nav-align-top">
          <ul className="nav nav-pills " role="tablist">
            <li className="nav-item me-1">
              <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4 active" role="tab" data-bs-toggle="tab" data-bs-target={"#btciniciodetallador"+listTabs.id1} aria-controls="btciniciodetallador" aria-selected="true"><i className="tf-icons bx bx-run bx-xs" /> BTC Inicio </button>
            </li>
            <li className="nav-item me-1">
              <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4" role="tab" data-bs-toggle="tab" data-bs-target={"#btcclientedetalle"+listTabs.id2} aria-controls="btcclientedetalle" aria-selected="false" onClick={() => { clickSubTab('2') }}><i className="tf-icons bx bx-face bx-xs" /> BTCCliente </button>
            </li>
            <li className="nav-item me-1">
              <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4" role="tab" data-bs-toggle="tab" data-bs-target={"#btcgrupoarticulo"+listTabs.id3} aria-controls="btcgrupoarticulo" aria-selected="false" onClick={() => { clickSubTab('3') }}><i className="tf-icons bx bx-category bx-xs" /> BTCGruArticulos </button>
            </li>
            <li className="nav-item me-1">
              <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4" role="tab" data-bs-toggle="tab" data-bs-target={"#btcvendedordetall"+listTabs.id4} aria-controls="btcvendedordetall" aria-selected="false" onClick={() => { clickSubTab('4') }}><i className="tf-icons bx bx-user bx-xs" /> BTCVendedor </button>
            </li>
            <li className="nav-item me-1">
              <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4" role="tab" data-bs-toggle="tab" data-bs-target={"#btcsucursaldetll"+listTabs.id5} aria-controls="btcsucursaldetll" aria-selected="false" onClick={() => { clickSubTab('5') }}><i className="tf-icons bx bx-store-alt bx-xs" /> BTCSucursal </button>
            </li>
            <li className="nav-item me-1">
              <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4" role="tab" data-bs-toggle="tab" data-bs-target={"#btcmesadetall"+listTabs.id6} aria-controls="btcmesadetall" aria-selected="false" onClick={() => { clickSubTab('6') }}><i className="tf-icons bx bxs-rename bx-xs" /> BTCMesa </button>
            </li>
            <li className="nav-item me-1">
              <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4" role="tab" data-bs-toggle="tab" data-bs-target={"#btcobsequiodetll"+listTabs.id7} aria-controls="btcobsequiodetll" aria-selected="false" onClick={() => { clickSubTab('7') }}><i className="tf-icons bx bxs-package bx-xs" /> BTCObsequio </button>
            </li>
            {/* <li className="nav-item me-1">
              <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4 " role="tab" data-bs-toggle="tab" data-bs-target={"#btccategoriacliente"+listTabs.id8} aria-controls="btccategoriacliente" aria-selected="false" onClick={() => { clickSubTab('8') }}><i className="tf-icons bx bxs-rename bx-xs" /> BTCCatCliente </button>
            </li> */}
            <li className="nav-item me-1">
              <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4 " role="tab" data-bs-toggle="tab" data-bs-target={"#btcgrupoventas"+listTabs.id9} aria-controls="bceobsequiodetll" aria-selected="false" onClick={() => { clickSubTab('9') }}><i className="tf-icons bx bxs-package bx-xs" /> BTCGruVentas </button>
            </li>
            <li className="nav-item me-1">
              <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4 " role="tab" data-bs-toggle="tab" data-bs-target={"#btccondicionespago"+listTabs.id10} aria-controls="btcobsequiodetll" aria-selected="false" onClick={() => { clickSubTab('10') }}><i className="tf-icons bx bxs-package bx-xs" /> BTCCondPago </button>
            </li>
            <li className="nav-item me-1">
              <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4 " role="tab" data-bs-toggle="tab" data-bs-target={"#btcvendedoresexcluidos"+listTabs.id11} aria-controls="btcvendedoresexcluidos" aria-selected="false" onClick={() => { clickSubTab('11') }}><i className="tf-icons bx bxs-package bx-xs" /> BTCVendedores Ex. </button>
            </li>

          </ul>
          <div className="tab-content" style={{ 'height': '500px' }}>

            <div className="tab-pane fade show active" id={"btciniciodetallador"+listTabs.id1} role="tabpanel">

              <BTCInicioDetalle
                arrCampDatos={listDatos}
                onDataUpdate={handleDataFromChild}
                listInitData={listDataUstMemori.bceiniciodetallador}
                onHandleVPut={onHandleVPut}
                onValCopyDat={valCopy}
                onValBloqueo={valBloq}
              />

            </div>
            <div className="tab-pane fade" id={"btcclientedetalle"+listTabs.id2} role="tabpanel">
              {
                (listSubTabsVisu.id2) && (
                  <BTCClienteDetalle
                    arrCampDatos={listDatos}
                    onDataUpdate={handleDataFromChild}
                    listInitData={listDataUstMemori.bcecliente}
                    onHandleVPut={onHandleVPut}
                    onValCopyDat={valCopy}
                    onValBloqueo={valBloq}
                    validarLData={listDataUstMemori.bceobsequiodetll}
                  />
                )
              }
            </div>
            <div className="tab-pane fade" id={"btcgrupoarticulo"+listTabs.id3} role="tabpanel">
              {
                (listSubTabsVisu.id3) && (
                  <BTCGrupoArticulosDetallados
                    arrCampDatos={listDatos}
                    onDataUpdate={handleDataFromChild}
                    listInitData={listDataUstMemori.bcegrupoarticulo}
                    onHandleVPut={onHandleVPut}
                    onValCopyDat={valCopy}
                    onValBloqueo={valBloq}
                  />
                )
              }
            </div>
            <div className="tab-pane fade" id={"btcvendedordetall"+listTabs.id4} role="tabpanel">
              {
                (listSubTabsVisu.id4) && (
                  <BTCVendedorDetallador
                    arrCampDatos={listDatos}
                    onDataUpdate={handleDataFromChild}
                    listInitData={listDataUstMemori.bcevendedordetall}
                    onHandleVPut={onHandleVPut}
                    onValCopyDat={valCopy}
                    onValBloqueo={valBloq}
                  />
                )
              }
            </div>
            <div className="tab-pane fade" id={"btcsucursaldetll"+listTabs.id5} role="tabpanel">
              {
                (listSubTabsVisu.id5) && (
                  <BTCSucursalDetallado
                    arrCampDatos={listDatos}
                    onDataUpdate={handleDataFromChild}
                    listInitData={listDataUstMemori.bcesucursaldetll}
                    onHandleVPut={onHandleVPut}
                    onValCopyDat={valCopy}
                    onValBloqueo={valBloq}
                  />
                )
              }
            </div>
            <div className="tab-pane fade" id={"btcmesadetall"+listTabs.id6} role="tabpanel">
              {
                (listSubTabsVisu.id6) && (
                  <BTCMesaDetalle
                    arrCampDatos={listDatos}
                    onDataUpdate={handleDataFromChild}
                    listInitData={listDataUstMemori.bcemesadetall}
                    onHandleVPut={onHandleVPut}
                    onValCopyDat={valCopy}
                    onValBloqueo={valBloq}
                  />
                )
              }
            </div>
            <div className="tab-pane fade" id={"btcobsequiodetll"+listTabs.id7} role="tabpanel">
              {
                (listSubTabsVisu.id7) && (
                  <BTCObsequioDetalle
                    arrCampDatos={listDatos}
                    onDataUpdate={handleDataFromChild}
                    listInitData={listDataUstMemori.bceobsequiodetll}
                    onHandleVPut={onHandleVPut}
                    onValCopyDat={valCopy}
                    onValBloqueo={valBloq}
                  />
                )
              }
            </div>
            {/* <div className="tab-pane fade " id={"btccategoriacliente"+listTabs.id8} role="tabpanel">
              {
                (listSubTabsVisu.id8) && (
                  <BTCCategoriaCliente
                    arrCampDatos={listDatos}
                    onDataUpdate={handleDataFromChild}
                    listInitData={listDataUstMemori.bcecategoriacliente}
                    onHandleVPut={onHandleVPut}
                    onValCopyDat={valCopy}
                    onValBloqueo={valBloq}
                  />
                )
              }
            </div> */}
            <div className="tab-pane fade " id={"btcgrupoventas"+listTabs.id9} role="tabpanel">
              {
                (listSubTabsVisu.id9) && (
                  <BTCGrupoVentas
                    arrCampDatos={listDatos}
                    onDataUpdate={handleDataFromChild}
                    listInitData={listDataUstMemori.bcegrupoventas}
                    onHandleVPut={onHandleVPut}
                    onValCopyDat={valCopy}
                    onValBloqueo={valBloq}
                  />
                )
              }
            </div>
            <div className="tab-pane fade " id={"btccondicionespago"+listTabs.id10} role="tabpanel">
              {
                (listSubTabsVisu.id10) && (
                  <BTCCondicionesPago
                    arrCampDatos={listDatos}
                    onDataUpdate={handleDataFromChild}
                    listInitData={listDataUstMemori.bcecondicionespago}
                    onHandleVPut={onHandleVPut}
                    onValCopyDat={valCopy}
                    onValBloqueo={valBloq}
                  />
                )
              }
            </div>
            <div className="tab-pane fade " id={"btcvendedoresexcluidos"+listTabs.id11} role="tabpanel">
              {
                (listSubTabsVisu.id11) && (
                  <BTCVendedoresExcluidos
                    arrCampDatos={listDatos}
                    onDataUpdate={handleDataFromChild}
                    listInitData={listDataUstMemori.bcevendedoresexcluidos}
                    onHandleVPut={onHandleVPut}
                    onValCopyDat={valCopy}
                    onValBloqueo={valBloq}
                  />
                )
              }
            </div>
          </div>
        </div>
      </div>
      <div className="row ">
        <div className="col-lg-4"></div>
        <div className="col-lg-8 text-end"> {/**  bt_funct={onClickModal} */}

          {valBloq ? <div className="d-none"></div> :
            btnVisible1 ?
              valCopy ?
                <AButton tp_tilde="Copy" bt_descr="Copy" tp_posit="top" bt_class="btn btn-sm btn-label-warning " ic_class="bx bx-ms bx-copy" vl_tpbtn={2} bt_funct={onclickCrearG} disabled={disabledb1} />
                :
                <>
                  <AButton tp_tilde="Guardar nuevos registros" bt_descr="Guardar" tp_posit="top" bt_class="btn btn-sm btn-label-primary " ic_class="bx bx-ms bx-send" vl_tpbtn={2} bt_funct={onclickCrearG} disabled={disabledb1} />
                  {dataid?.codigo === "" && (
                    <AButton tp_tilde="Agregar a fila" bt_descr="Agregar a fila" tp_posit="top" bt_class="btn btn-sm btn-label-warning " ic_class="bx bx-ms bx-send" vl_tpbtn={2} bt_funct={onClickEnviarDatos} />
                  )
                  }
                </>
              :
              <AButton tp_tilde="Crear" bt_descr="Crear" tp_posit="top" bt_class="btn btn-sm btn-label-primary " ic_class="bx bx-ms bx-send" vl_tpbtn={2} bt_funct={onclickCrearG} disabled={disabledb1} />
          }
        </div>
      </div>
    </>
  )
}

export default BONTicketCompra