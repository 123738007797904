import React, { useState, useEffect } from 'react';//useCallback
import { useLocation, useNavigate } from 'react-router-dom';
import { notify_warning, notify_valido, notify_error } from '../../../components/utils';
import { AButton } from '../../../components/Button';
import 'react-datepicker/dist/react-datepicker.css';
import './DESC.css'
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2';
import $ from 'jquery'
import DESUnoAUno from './DESUnoAUno';
import DESTicketCompra from './DESTicketCompra';
import DESTicketCompraEscala from './DESTicketCompraEscala';
import DESComboEscala from './DESComboEscala';
import { ModalBEPorE } from '../../../components/Modals/ModalsDetVen';
// import AsyncSelect from 'react-select/async';
// import DESReglas from './DESReglas'

import Cookies from 'universal-cookie';
const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id');
const moment = require('moment')
// const styles = {
//   control: (provided, state) => ({
//     ...provided,
//     background: `#fff`,
//     borderColor: `#D2D5DC`,
//     minHeight: `29px`,
//     height: `29px`,
//     boxShadow: state.isFocused ? null : null,
//     border: '1px solid #D2D5DC',

//   }),

//   valueContainer: (provided, state) => ({
//     ...provided,
//     height: `29px`,
//     padding: `0 6px`,
//     color: `#697a8d`,

//   }),

//   input: (provided, state) => ({
//     ...provided,
//     color: `#697a8d`,
//     fontSize: `0.75rem`,

//   }),
//   indicatorSeparator: state => ({
//     display: `none`,

//   }),
//   indicatorsContainer: (provided, state) => ({
//     ...provided,
//     height: `29px`,

//   }),
// };


export const DESInicioDetalle = (prosp) => {
  const { arrCampDatos, listInitData, onDataUpdate, onHandleVPut, onValCopyDat, onValBloqueo } = prosp;
  const [arrDatosInsert, setArrDatosInsert] = useState([]);
  const [filaActiva, setFilaActiva] = useState(null);
  // const [slcArticulo, setSlcArticulo] = useState([""]);

  const [listDataUstMemori, setlistDataUstMemori] = useState({
    DESdatosReglas: [],
  })
  const [dataid, setDataid] = useState({ codigo: '', id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', id7: '', id8: '', id9: '', id10: '', id11: '', id12: '', id13: ''  });
  const [arFileUltim, setArFileUltim] = useState({
    txtDet0: '',
    txtDet1: '',
    txtDet2: '',
  });
  const handleDataFromChild = (id, data) => {
    setlistDataUstMemori({
      ...listDataUstMemori,
      [id]: data
    });
  }
  const handleFieldTabChange = (fieldName) => (event) => {
    const value = event.target.value;
    setArFileUltim({
      ...arFileUltim,
      [fieldName]: value,
    });
  };
  
  const onClickInsert = async () => {
    if (arrCampDatos.txtDesc !== "" && arFileUltim.txtDet1 !== "") {
      const datosrepetidos = arrDatosInsert.filter(varr => varr.CodArt === arFileUltim.txtDet1)
      if (datosrepetidos.length === 0) {
        const lastItem = arrDatosInsert[arrDatosInsert.length - 1]
        const newId = (lastItem === undefined || lastItem === "") ? 1 : lastItem.idArt + 1;
        const newItem = { idArt: newId, CodArt: arFileUltim.txtDet1, namArt: arFileUltim.txtDet2, namTip: arFileUltim.txtDet0  };
        notify_valido("Se registro correctamente!")
        setArrDatosInsert([...arrDatosInsert, newItem]);
        onDataUpdate('DESiniciodetallador', [...arrDatosInsert, newItem])
        setFilaActiva(newId);
        //setSlcArticulo([])
        setlistDataUstMemori({ DESdatosReglas: [] })
        setArFileUltim({ txtDet0: '', txtDet1: '', txtDet2: '' });
      } else { notify_warning('Datos selecionados ya exiten.'); }
    } else {
      //if (arrCampDatos.txtCod === "" && arrCampDatos.txtDesc === "") {
      if (arrCampDatos.txtDesc === "") {
        notify_warning('Campo descripción es obligatorio no debe estar vacio.');
      } else {
        if (arFileUltim.txtDet0 === "") { notify_warning('Seleccione de tipo de udo'); }
        if (arFileUltim.txtDet1 === "") { notify_warning('Codigo es obligatorio, no debe estar vacio.'); }
        //if (arrCampDatos.txtCod.trim() === "") { notify_warning('Campo codigo es obligatorio no debe estar vacio.'); }
        if (arrCampDatos.txtDesc.trim() === "") { notify_warning('Campo descripción son obligatorio no debe estar vacio.'); }
      }
    }
  }



  useEffect(() => {
    const lastItem = listInitData[listInitData?.length - 1];
    setFilaActiva(lastItem?.idArt)
    setArrDatosInsert(listInitData)
  }, [listInitData])
  const onClickDeletefila = (code) => {
    onHandleVPut("1")
    const myList = [...arrDatosInsert];
    const data = arrDatosInsert.findIndex((res) => (res.CodArt === code));
    myList.splice(data, 1);
    setArrDatosInsert(myList);
    console.log(arrDatosInsert);
    onDataUpdate('DESiniciodetallador', myList)

  }
  const TRPlantilllaCD = (prosp) => {
    return (
      <tr key={prosp.datoRow.idArt} >
        <td className='text-center' >
          {/* <AButton tp_tilde="Editar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-primary" ic_class="bx bx-ms bx-edit" vl_tpbtn={1} /> */}
          {onValBloqueo ? <div className="d-none"></div> :

            <AButton tp_tilde="Eliminar" disabled={onValCopyDat ? false : prosp.datoRow.estadoComprometido} bt_funct={() => onClickDeletefila(prosp.datoRow.CodArt)} tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-ms bx-x-circle" vl_tpbtn={1} />

          }
        </td>
        <td>{prosp.datoRow.namTip}</td>
        <td style={{ position: 'relative', zIndex: '2', maxWidth: '50px' }} >
          <div className="row">
            <div className="col-8">
              <label className="form-control-sm">{prosp.datoRow.CodArt}</label>
            </div>
            <div className="col-4 text-end">{/** bt_funct={(e) =>{onClickAbrirModal(prosp.datoRow.CodArt)}} */}
              <AButton tp_tilde="Mas detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info " bt_vmodal={1} bt_funct={(e) => { onClickAbrirModal(prosp.datoRow.CodArt, prosp.datoRow.namTip) }} bt_nmodal="#exLargeModal1" ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
            </div>
          </div>
        </td>
        <td>{prosp.datoRow.namArt}</td>
        <td></td>
      </tr>
    )
  }
  const fcoffModal = () => {
    if (listDataUstMemori) {
      setArFileUltim(()=>({
        ...arFileUltim,
        txtDet1: listDataUstMemori?.DESdatosReglas?.rtxtCod,
        txtDet2: listDataUstMemori?.DESdatosReglas?.rtxtDesc,
      }));
    }
  }
  const [ estUdo, setEstUdo ] = useState('')
  const [ desUdo, setDesUdo ] = useState('')
  // const onClickAbrirModal = (cod,name) => (event) => {
  const onClickAbrirModal = (cod,name) => {
    setDesUdo('')
    setEstUdo(false)
    if(name==="AB_DUUC"){ setDesUdo('Descuento Uno a Uno') }
    if(name==="AB_DTBO"){ setDesUdo('Descuento Ticket Compra') }
    if(name==="AB_DCST"){ setDesUdo('Descuento Ticket Compra Escala') }
    if(name==="AB_DCCE"){ setDesUdo('Descuento Combo Escala') }
    setEstUdo(name)

    setDataid({ codigo: cod, id1: 'tab1', id2: 'tab2', id3: 'tab3', id4: 'tab4', id5: 'tab5', id6: 'tab6', id7: 'tab7', id8: 'tab8', id9: 'tab9', id10: 'tab10', id11: 'tab11', id12: 'tab12', id13: 'tab13'  })
  }


  return (
    <>
      <div className="data-tables table-responsive text-nowrap " style={{ position: 'relative', zIndex: '1', width: 'auto', height: '400px', overflowY: 'scroll', overflowX: 'scroll' }}>
        <table className="table table-sm" id='tab_DInicio' style={{ fontSize: '10px', zIndex: '2', }} >
          <thead style={{ position: 'sticky', top: 0, zIndex: 10 }}>
            <tr >
              <th>Actions</th>
              <th style={{ width: '250px' }} >TipoUdo </th>
              <th style={{ width: '350px' }}>Code Regla  <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger d-none" ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} /></th>
              <th style={{ width: '450px' }}>Des. Regla</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody >
            {

              (arrDatosInsert?.length) ? arrDatosInsert?.map((row) => (
                <React.Fragment key={row.idArt}>
                  <TRPlantilllaCD key={row.idArt} datoRow={row} />
                  {onValBloqueo ? <div className="d-none"></div> :

                    (!filaActiva || filaActiva === row.idArt) && (
                      <tr key={prosp?.datoRow?.idArt}>
                        <td className='td1'></td>
                        <td className='td2' style={{ position: 'relative', zIndex: '3' }} >
                          <select className="form-select form-select-sm" value={arFileUltim.txtDet0} onChange={handleFieldTabChange('txtDet0')} >
                                  <option value='' >-- Seleccione --</option> 
                                  <option value='AB_DUUC' >Desc.1a1</option>
                                  <option value='AB_DTBO' >Desc.TicketCompra</option>
                                  <option value='AB_DCST' >Desc.TicketCompraEscala</option>
                                  <option value='AB_DCCE' >Desc.ComboEscala</option>
                          </select>
                        </td>
                        <td className='td3' style={{ position: 'relative', zIndex: '3' }} >

                          <div className='input-group'>{/** bt_funct={(e) =>{onClickAbrirModal('')}} */}
                            <input type="text" id="txt_det1" name="txt_det1" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet1} onChange={handleFieldTabChange('txtDet1')} readOnly={true} />
                            {
                              arFileUltim.txtDet0 !== '' && (
                                <AButton tp_tilde="Mas detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info " bt_vmodal={1} bt_funct={(e) => { onClickAbrirModal('') }} bt_nmodal="#exLargeModal1" ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                              ) 
                            }
                          </div>
                        </td>
                        <td className='td4'>
                          <input type="text" id="txt_det2" name="txt_det2" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet2} onChange={handleFieldTabChange('txtDet2')} readOnly={true} />
                        </td>
                        <td className='td5'>
                          <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />

                        </td>
                      </tr>
                    )
                  }
                </React.Fragment>
              ))
                : onValBloqueo ? <div className="d-none"></div> :

                  <tr key={prosp?.datoRow?.idArt}>
                    <td className='td1'></td>
                    <td className='td2' style={{ position: 'relative', zIndex: '3' }} >
                      <select className="form-select form-select-sm" value={arFileUltim.txtDet0} onChange={handleFieldTabChange('txtDet0')} >
                              <option value='' >-- Seleccione --</option> 
                              <option value='AB_DUUC' >Desc.1a1</option>
                              <option value='AB_DTBO' >Desc.TicketCompra</option>
                              <option value='AB_DCST' >Desc.TicketCompraEscala</option>
                              <option value='AB_DCCE' >Desc.ComboEscala</option>
                      </select>
                    </td>
                    <td className='td3' style={{ position: 'relative', zIndex: '3' }} >
                      <div className='input-group'>{/**bt_funct={(e) =>{onClickAbrirModal('')}} */}
                        <input type="text" id="txt_det1" name="txt_det1" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet1} onChange={handleFieldTabChange('txtDet1')} readOnly={true} />
                        {
                          arFileUltim.txtDet0 !== '' && (
                            <AButton tp_tilde="Mas detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info " bt_vmodal={1} bt_funct={(e) => { onClickAbrirModal('',arFileUltim.txtDet0) }} bt_nmodal="#exLargeModal1" ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                          ) 
                        }
                      </div>
                    </td>
                    <td className='td4'>
                      <input type="text" id="txt_det2" name="txt_det2" className="form-control form-control-sm" autoComplete='off' value={arFileUltim.txtDet2} onChange={handleFieldTabChange('txtDet2')} readOnly={true} />
                    </td>
                    <td className='td5'>
                      <AButton tp_tilde="Guardar" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-success " bt_funct={onClickInsert} ic_class="bx bx-ms bx-send" vl_tpbtn={1} />

                    </td>
                  </tr>
            }
          </tbody>
        </table>
      </div>
      <div className="modal fade" id="exLargeModal1" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog  modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header ">
              <h5 className="modal-title" id="exampleModalLabel4">{desUdo}</h5>
              <button type="button" onClick={fcoffModal} className="btn-close closeModal" data-bs-dismiss="modal" aria-label="Close" ></button>
            </div>
            <div className="modal-body" >
            {
                  //(arFileUltim.txtDet0 === "AB_BUU" || estUdo) && ( 
                  (arFileUltim.txtDet0 === "AB_DUUC" || estUdo === "AB_DUUC") && ( 
                    <DESUnoAUno 
                      dataid={dataid}
                      onDataUpdate2={handleDataFromChild}
                    /> 
                  )
                }
                {
                  (arFileUltim.txtDet0 === "AB_DTBO" || estUdo === "AB_DTBO") && ( 
                    <DESTicketCompra 
                      dataid={dataid}
                      onDataUpdate2={handleDataFromChild}
                    /> 
                  )
                }
                {
                  (arFileUltim.txtDet0 === "AB_DCST" || estUdo === "AB_DCST") && ( 
                    <DESTicketCompraEscala 
                      dataid={dataid}
                      onDataUpdate2={handleDataFromChild}
                    /> 
                  )
                }
                {
                  (arFileUltim.txtDet0 === "AB_DCCE" || estUdo === "AB_DCCE") && ( 
                    <DESComboEscala 
                      dataid={dataid}
                      onDataUpdate2={handleDataFromChild}
                    /> 
                  )
                }
            </div>

          </div>
        </div>
      </div>

    </>
  )
}





const DESAgrupacionRegla = () => {
  /** Inicio :> Datos para validar */
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const cod = searchParams.get('cod')
  const tab = searchParams.get('tab');
  const tip = searchParams.get('tip');
  /** Fin :> Datos para validar */
  const [listDatos, setListDatos] = useState({
    txtCod: '',
    txtDesc: '',
    txtTasDes: '0.00',
    txtPresu:'9999999',
    txtActi: '',
    //txtDescuento: '0.00',
    txtFIni: new Date(),
    txtFFin: new Date(),
    txtBode: '',
    txtMayo: '',
    txtMerc: '',
    txtMinimar: ''

  })
  const [listDataUstMemori, setlistDataUstMemori] = useState({
    DESiniciodetallador: [],
    DESdescuento: []
  })
  const [valCopy, setValCopy] = useState(false);
  const [valBloq, setValBloq] = useState(false);
  const [disabledb1, setdisabledb1] = useState(false);
  const [disabledb2, setdisabledb2] = useState(false);
  const [btnVisible1, setBtnVisible1] = useState(false);
  const [btnVisible2, setBtnVisible2] = useState(false);
  const [validarPut, setValidarPut] = useState('0');
  const [vlExtPresu, setvlExtPresu] = useState(false);
  const handleFieldChange = (fieldName) => (event) => {
    const value = event.target.value;
    setListDatos({
      ...listDatos,
      [fieldName]: value,
    });
  };
  const handleDateChange1 = (date) => {
    setListDatos({
      ...listDatos,
      txtFIni: date, // Actualiza el valor de la fecha seleccionada fecha inicio
    });
  };
  const handleDateChange2 = (date) => {
    setListDatos({
      ...listDatos,
      txtFFin: date, // Actualiza el valor de la fecha seleccionada fecha fin
    });
  };
  const handleKeyDown = async (e, cod, tip) => {
    if ((e && e.key === 'Enter') || cod) {
      if (e) { e.preventDefault(); }

      Swal.fire({
        title: 'Procesando...',
        html: 'Se esta consultado datos.',
        didOpen: () => { Swal.showLoading() },
        customClass: { container: 'my-swal' },
        allowOutsideClick: false
      })
      const url = '/apiDagr'
      const body = { vcodEmpresa:cook_idEmp, vlCodigo: (cod) ? cod : listDatos.txtCod };
      const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
      const response = await fetch(url, opPe);
      const resdatos = await response.json();
      //console.log("resdatos", resdatos);
      if (resdatos.length) {
        setListDatos({
          ...listDatos,
          // txtCod:resdatos[0].txtCod,
          txtCod: (tip === "copy") ? '' : resdatos[0].txtCod,
          txtDesc: resdatos[0].txtDesc,
          txtTasDes: resdatos[0].txtTasDes,
          txtPresu: resdatos[0].txtPresu,
          txtActi: resdatos[0].txtActi,
          //txtDescuento: resdatos[0].txtDescuento,
          txtFIni: new Date(moment(resdatos[0].txtFIni, 'Y-M-D').format('YYYY-MM-DD HH:mm:ss')),
          txtFFin: new Date(moment(resdatos[0].txtFFin, 'Y-M-D').format('YYYY-MM-DD HH:mm:ss')),
          txtBode: resdatos[0].txtBode,
          txtMayo: resdatos[0].txtMayo,
          txtMerc: resdatos[0].txtMerc,
          txtMinimar: resdatos[0].txtMinimar, 


        });
        setlistDataUstMemori({
          ...listDataUstMemori,
          DESiniciodetallador: resdatos[0]?.arrDetalle1,
          // DESdescuento:resdatos[0]?.arrDetalle2,
        })
        setBtnVisible1(true)
        setBtnVisible2(true)
        if(tip === "edit") { onClickExtenderPresupuesto(resdatos[0].txtCod) } 
      } else {
        notify_warning('Codigo no existe en nuestra base de datos.');
        onClickLimpiar()
      }
      Swal.close();
    }
  };
  const handleDataFromChild = (id, data) => {
    setlistDataUstMemori({
      ...listDataUstMemori,
      [id]: data
    });
  }
  const onClickActualizar = async () => {
    setdisabledb2(true)
    const url = '/apiDagrCabecera'
    const body = { vcodEmpresa:cook_idEmp, arrCabecera: listDatos };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url, opPe);
    const resdatos = await response.json();
    if (resdatos.vl_api === 1) {
      notify_warning("Error al actualizar, verificar datos!")
    }
    if (resdatos.vl_existe === 1) {
      notify_warning("Ya esta actualizado!")
    }
    if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1) {
      notify_valido("Se actualizo correctamente!")
    }
    setdisabledb2(false)
  }
  const onclickCrearG = async () => {
    try {
      setdisabledb1(true)
      if (
          listDatos.txtDesc !== "" && listDatos.txtActi !=="" && 
          listDatos.txtBode !== "" && listDatos.txtMayo !=="" && 
          listDatos.txtMerc !== "" && listDatos.txtMinimar !== "" &&
          (listDatos.txtTasDes !== '0.00' && listDatos.txtTasDes !== '0') 
        ) {
        if (listDataUstMemori.DESiniciodetallador.length >= 1) {
          const url = '/apiDagrInsert'
          const body = { vcodEmpresa: cook_idEmp, arrCabecera: listDatos, arrDetalle: listDataUstMemori, validarPut: validarPut };
          const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
          const response = await fetch(url, opPe);
          const resdatos = await response.json();
          if (resdatos.vl_api === 1) {
            notify_warning("Error al registrar, verificar datos!")
          }
          if (resdatos.vl_existe === 1) {
            notify_warning("Ya esta registrado!")
          }
          if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1) {
            notify_valido("Se registro correctamente!")
            setBtnVisible1(true)
            setBtnVisible2(true)
            if (tip === "copy") {
              setValCopy(false)
              updateParUrl('edit', tab, resdatos.vl_codres)
            } else {
              setListDatos(() => ({
                ...listDatos,
                txtCod: resdatos.vl_codres
              }));
            }
          }
        } else {
          notify_warning('En niguno de los tabs hay filas agregadas, por favor agregar minimo 1 fila en un tab.');
        }
      } else {
        if(listDatos.txtDesc==="") { notify_warning('Campo descripción es obligatorio, no debe estar vacio.');}
        if(listDatos.txtActi==="") { notify_warning('Seleccione Activo');}
        if(listDatos.txtBode==="") { notify_warning('Seleccione Bodegas');}
        if(listDatos.txtMayo==="") { notify_warning('Seleccione Mayoritas');}
        if(listDatos.txtMerc==="") { notify_warning('Seleccione Mercados');}
        if(listDatos.txtMinimar==="") { notify_warning('Seleccione minimarket');}
        if(listDatos.txtTasDes === '0.00' || listDatos.txtTasDes === '0'){ notify_warning('Se requiere Tasa de Descuento.'); }
    
      }
      setdisabledb1(false)
    } catch(e){
      console.log("error", e);
      notify_error('Error, comunicarse con la area de TI');
      setdisabledb1(false)
    }
  }
  const onClickLimpiar = async () => {
    setListDatos({
      txtCod: '',
      txtDesc: '',
      txtTasDes: '0.000',
      txtPresu: '9999999',
      //txtDescuento: '0.000',
      txtActi: '',
      txtFIni: new Date(),
      txtFFin: new Date(),
      txtBode: '',
      txtMayo: '',
      txtMerc: '',
      txtMinimar: ''
    })
    setlistDataUstMemori({
      DESiniciodetallador: [],
      DESdescuento: []
    })

    setBtnVisible1(false)
    setBtnVisible2(false)
    notify_valido("Campos de cabecera y tablas de tab se limpiaron correctamente!")
  }
  const updateParUrl = (etip, etab, ecod) => {
    const newParams = { tip: etip, tab: etab, cod: ecod };
    navigate({
      pathname: window.location.pathname,
      search: '?' + new URLSearchParams(newParams).toString()
    });
  }
  const onClickExtenderPresupuesto = async (Cod) => {
    const url = '/apiVEPresupuesto?vcodEmpresa=' + cook_idEmp + '&tpudo=AB_DBAR&codigo=' + Cod
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const resdatos = await response.json();
    if(resdatos.Registros === "Y"){
      setValBloq(true)
      $("input").attr("disabled", true)
      $("select").attr("disabled", true)
      $("input[name=txtPres]").attr("disabled", false)
      setvlExtPresu(true)
    }
  }
  const [disabledEdit, setDisabledEdit] = useState(false)
  useEffect(() => {
    if (cod && tab === 'desagrupacionreglas') {
      setTimeout(() => {
        setListDatos(() => ({
          ...listDatos,
          txtCod: cod
        }));
      }, 1000)

      if (tip === "ver") {
        setValBloq(true)
        $("input").attr("disabled", true)
        $("select").attr("disabled", true)
      } else {
        setValBloq(false)
      }
      if (tip === "edit") { setDisabledEdit(true) }

      setTimeout(() => {
        (tip === "copy") ? setValCopy(true) : setValCopy(false)
        handleKeyDown(null, cod, tip);
      }, 2000)
    }

  }, [cod, tab])

  const onHandleVPut = (dato) => {
    setValidarPut(dato)
  }
    /** Inicio :> Modal de consulta */
    const [dtEPorE, setDtEPorE] = useState([]);
    const onclickMostrarEntePorEntre = async() => {
      const url = '/apiEntrePorEntre?vcodEmpresa=' + cook_idEmp + '&tpudo=BUU&codigo=' + listDatos.txtCod
      const opPe9 = { method: "GET", headers: { 'Content-Type': 'application/json' } }
      const resp = await fetch(url, opPe9);
      const data = await resp.json();
      setDtEPorE(data)
    }
    const [generalModalUni, setGeneralModalUni] = useState(false);
    const handleOpen = async () => {
      onclickMostrarEntePorEntre()
      setGeneralModalUni(true);
    }
    const handleClose = () => {
      setGeneralModalUni(false);
    }
    /** Fin :> Modal de consulta */
  return (
    <>
      <div className="row ">
        <div className="col-md-1">Codigo:</div>
        <div className="col-md-2">
          <div className="input-group">
            <input type="text" id="txtCod" name="txtCod" className="form-control form-control-sm" autoComplete='off' value={listDatos.txtCod} onChange={handleFieldChange('txtCod')} onKeyDown={handleKeyDown} disabled/>
          </div>
        </div>
        <div className="col-md-1">D.Desc:</div>
        <div className="col-md-5">
          <input type="text" id="txtDesc" name="txtDesc" className="form-control form-control-sm" autoComplete='off' value={listDatos.txtDesc} onChange={handleFieldChange('txtDesc')} />
        </div>
        <div className="col-md-1">Activo:</div>
        <div className="col-md-2">
          <select id="txtActi" name="txtActi" className="form-select form-select-sm" value={listDatos.txtActi} onChange={handleFieldChange('txtActi')} disabled={disabledEdit} >
            <option value={""} >{"<<Selecciona>>"}</option>
            <option value={"Y"} >Si</option>
            <option value={"N"} >No</option>
          </select>
        </div>

      </div>
      <div className="row">
        <div className="col-md-1">Inicio:</div>
        <div className="col-md-2" style={{ position: 'relative', zIndex: '2' }}>
          <DatePicker
            id="txtFIni"
            locale="es"
            wrapperClassName='input_date'
            className="form-control form-control-sm text-center"
            selected={listDatos.txtFIni}
            onChange={handleDateChange1}
            dateFormat="yyyy-MM-dd"
          />
        </div>
        <div className="col-md-1">Fin:</div>
        <div className="col-md-2" style={{ position: 'relative', zIndex: '2' }}>
          <DatePicker
            id="txtFFin"
            locale="es"
            wrapperClassName='input_date'
            className="form-control form-control-sm text-center"
            selected={listDatos.txtFFin}
            onChange={handleDateChange2}
            dateFormat="yyyy-MM-dd"
          />
        </div>
        <div className="col-md-1">TasaDesc.:</div>
        <div className="col-md-2">
          <div className="input-group">
            <input type="number" id="txtTasDes" name="txtTasDes" className="form-control form-control-sm" autoComplete='off' value={listDatos.txtTasDes} onChange={handleFieldChange('txtTasDes')} />
          </div>
        </div>
        <div className="col-md-1">Presupuesto:</div>
        <div className="col-md-2">
          <input type="number" id="txtPresu" name="txtPresu" className="form-control form-control-sm" autoComplete='off' value={listDatos.txtPresu} onChange={handleFieldChange('txtPresu')} />
        </div>

      </div>
      <div className="row ">
        <div className="col-md-1">Bodega:</div>
        <div className="col-md-2">
          <select id="txtBode" name="txtBode" className="form-select form-select-sm" value={listDatos.txtBode} onChange={handleFieldChange('txtBode')} >
            <option value={""} >{"<<Selecciona>>"}</option>
            <option value={"Y"} >Si</option>
            <option value={"N"} >No</option>
          </select>
        </div>
        <div className="col-md-1">Mayorista:</div>
        <div className="col-md-2">
          <select id="txtMayo" name="txtMayo" className="form-select form-select-sm" value={listDatos.txtMayo} onChange={handleFieldChange('txtMayo')} >
            <option value={""} >{"<<Selecciona>>"}</option>
            <option value={"Y"} >Si</option>
            <option value={"N"} >No</option>
          </select>
        </div>
        <div className="col-md-1">Mercados:</div>
        <div className="col-md-2">
          <select id="txtMerc" name="txtMerc" className="form-select form-select-sm" value={listDatos.txtMerc} onChange={handleFieldChange('txtMerc')} >
            <option value={""} >{"<<Selecciona>>"}</option>
            <option value={"Y"} >Si</option>
            <option value={"N"} >No</option>
          </select>
        </div>
        <div className="col-md-1">Minimarket:</div>
        <div className="col-md-2">
          <select id="txtMinimar" name="txtMinimar" className="form-select form-select-sm" value={listDatos.txtMinimar} onChange={handleFieldChange('txtMinimar')} >
            <option value={""} >{"<<Selecciona>>"}</option>
            <option value={"Y"} >Si</option>
            <option value={"N"} >No</option>
          </select>
          </div>

        {/* <div className="col-md-1">Descuento:</div>
        <div className="col-md-2">
          <input type="number" id="txtDescuento" name="txtDescuento" className="form-control form-control-sm" autoComplete='off' value={listDatos.txtDescuento} onChange={handleFieldChange('txtDescuento')} />
        </div> */}
      </div>
      {
        valBloq? 
          vlExtPresu ?
            <div className="row mb-1">
              <div className="col-lg-12 text-end">
                <AButton tp_tilde="Aumentar Presupuesto" bt_descr="Aumentar Presupuesto" tp_posit="top" bt_class="btn btn-sm btn-label-success " bt_funct={onClickActualizar} ic_class="bx bx-ms bx-send" vl_tpbtn={2} disabled={disabledb2} />
              </div> 
            </div>
          :
          <div className="d-none"></div> 
        :
          <div className="row mb-1">
            <div className="col-lg-12 text-end">
              {
                btnVisible2 && (
                  <AButton id="btn_actualizar" tp_tilde="Actualizar Cabecera" bt_descr="Actualizar Cabecera" tp_posit="top" bt_class="btn btn-sm btn-label-success " bt_funct={onClickActualizar} ic_class="bx bx-ms bx-send" vl_tpbtn={2} disabled={disabledb2} />
                )
              }
              <AButton id="btn_LimpiarTod" tp_tilde="Limpiar campos y tablas" bt_descr="Limpiar" tp_posit="top" bt_class="btn btn-sm btn-label-secondary" bt_funct={onClickLimpiar} ic_class="bx bx-ms bx-eraser" vl_tpbtn={1} />
              
              {
                  btnVisible2 && (
                    <AButton id="btn_ModalEPorE" tp_tilde="Consultar entregado y por entregar" tp_posit="top" bt_class="btn btn-sm btn-label-info" bt_funct={handleOpen} ic_class="bx bx-ms bx-bong" vl_tpbtn={1} />
                  )
                }
            </div>
          </div>
      }
      {
        generalModalUni && (
          <ModalBEPorE
            hanOpenModal={generalModalUni}
            hanClosModal={handleClose}
            onDataEstras={dtEPorE}
          />)
      }
      <div className="row ">
        <div className="nav-align-top">
          <ul className="nav nav-pills " role="tablist">
            <li className="nav-item me-1">
              <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4 active" role="tab" data-bs-toggle="tab" data-bs-target="#DAEiniciodetallador" aria-controls="DAEiniciodetallador" aria-selected="true"><i className="tf-icons bx bx-run bx-xs" /> DAE Inicio </button>
            </li>
            {/* <li className="nav-item me-1">
                    <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4" role="tab" data-bs-toggle="tab" data-bs-target="#DAEobsequiodetll" aria-controls="DAEobsequiodetll" aria-selected="false"><i className="tf-icons bx bxs-package bx-xs" /> DAE Obsequio </button>
                </li> */}

          </ul>
          <div className="tab-content">

            <div className="tab-pane fade show active" id="DAEiniciodetallador" role="tabpanel">
              <DESInicioDetalle
                arrCampDatos={listDatos}
                onDataUpdate={handleDataFromChild}
                listInitData={listDataUstMemori.DESiniciodetallador}
                onHandleVPut={onHandleVPut}
                onValCopyDat={valCopy}
                onValBloqueo={valBloq}
              />
            </div>

            {/* <div className="tab-pane fade" id="DAEobsequiodetll" role="tabpanel">
                  <DESObsequioDetalle arrCampDatos={listDatos} listInitData={listDataUstMemori.DESdescuento} />
                </div> */}



          </div>
        </div>
      </div>
      <div className="row ">
        <div className="col-lg-4"></div>
        <div className="col-lg-8 text-end">
          {btnVisible1 ?
            <AButton tp_tilde="Guardar nuevos registros" bt_descr="Guardar" tp_posit="top" bt_class="btn btn-sm btn-label-primary " ic_class="bx bx-ms bx-send" vl_tpbtn={2} bt_funct={onclickCrearG} disabled={disabledb1} />
            :
            <AButton tp_tilde="Crear" bt_descr="Crear" tp_posit="top" bt_class="btn btn-sm btn-label-primary " ic_class="bx bx-ms bx-send" vl_tpbtn={2} bt_funct={onclickCrearG} disabled={disabledb1} />
          }
        </div>
      </div>
    </>
  )
}

export default DESAgrupacionRegla