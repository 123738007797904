/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { SelectSucursal, SelectTipo } from '../../components/Selects/SelectsPrincipales'
import { notify_warning, notify_valido, selectTabla, selectTablaMultiple, removeSelectedRows, notify_error } from '../../components/utils';
import $, { map } from 'jquery'
import '../../App.css';
import { ToastContainer } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { Box, Modal, Tooltip } from '@mui/material';
import AsyncSelect from 'react-select/async';
import DatePicker from "react-datepicker";
import { AButton } from '../../components/Button';
import {RepViewAlmacen,RepViewSalidaAlamacen} from './RepViewAlmacen';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

import PdfAlmacenT001 from "../../components/pdf/PdfAlmacenT001";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Swal from 'sweetalert2';

const moment = require('moment')
const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id')
const globalTheme = window.templateCustomizer.settings.style;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const styles = {

  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? null : null,
    backgroundColor: globalTheme === 'light'
      ? '#FEFFF4'
      : '#2B2C40',
    color: globalTheme === 'light'
      ? '#2B2C40'
      : '#FEFFF4',
    minHeight: `29px`,
    height: `29px`,
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40')
      : (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40'),
    color: state.isSelected
      ? '#FF3E1D'
      : (globalTheme === 'light' ? '#2B2C40' : '#FEFFF4'),
    '&:hover': {
      backgroundColor: '#FF3E1D',
      color: 'white',
    },
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    backgroundColor: globalTheme === 'light' ? '#fefff4' : '#2B2C40',
    color: globalTheme === 'light' ? '#2B2C40' : '#fefff4',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
    padding: `0 6px`,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
  }),
  input: (provided, state) => ({
    ...provided,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
    fontSize: `0.75rem`,
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
  }),
};

// Reporte de finanzas 
const TablaPersona = (prosp) => {
  const selectFila = prosp.selectFila
  const [data, setdata] = useState([])
  const funListarPersonas = async (cadena) => {

    try {
      const response = await fetch(`/personasRepFinanzCredi?vcodEmpresa=${cook_idEmp}&cadenaBuscar=${cadena}`, { method: "GET", headers: { 'Content-Type': 'application/json' } });
      if (!response.ok) throw new Error(`Error al obtener los datos. Código de estado: ${response.status}`);
      const data = await response.json();
      console.log("--:: ", data[0]);
      setdata(data);
    } catch (error) {
      notify_warning('Sin datos registrados');
      console.error('Error:', error);
      // Manejar el error aquí según sea necesario.
    } finally {
    }
  }

  const [cadenaBuscar, setCadenabuscar] = useState('');
  const onChangeBuscar = (ev) => {
    const cadena = (ev.target.value).toUpperCase()
    setCadenabuscar(cadena);

  }
  const onClickBuscar = async () => {
    console.log("cadena a buscar :", cadenaBuscar);
    funListarPersonas(cadenaBuscar)
  }
  useEffect(() => {
    funListarPersonas(cadenaBuscar)
  }, [])

  const FilaTabla = (prosp) => {
    const data = prosp.datoRow
    return (
      <>
        <tr key={data.CODIGO} >
          <td>
            <AButton bt_funct={(e) => { selectFila(data) }} tp_tilde="Seleccionar" tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-select-multiple" vl_tpbtn={1} />
          </td>
          <td >
            {data.CODIGO}
          </td>
          <td>{data.NOMBRE}</td>
          <td> {data.AREA}</td>
        </tr>
      </>
    )
  }

  return (
    <div>
      <div className="col-3">
        <label>Bucar </label>

        <div className='input-group'>
          <input className='form-control form-control-sm mr-2' type="text" value={cadenaBuscar} onChange={onChangeBuscar} />
          <button className='btn btn-sm btn-icon btn-label-info ml-3' onClick={onClickBuscar} >
            <i className='bx bx-search-alt-2'></i>
          </button>
        </div>
      </div>
      <div className="data-tables table-responsive text-nowrap mt-3 " style={{ position: 'relative', zIndex: '1', width: 'auto', height: '400px', overflowY: 'scroll', overflowX: 'scroll' }}>
        <table className="table table-sm" style={{ fontSize: '10px', zIndex: '0', }}>
          <thead className='' style={{ position: 'sticky', top: 0, zIndex: 10 }}>
            <tr >
              <th>Actions</th>
              <th style={{ width: '250px' }} >Codigo </th>
              <th style={{ width: '250px' }} > Nombre  </th>
              <th style={{ width: '650px' }} >Area</th>
              {/* <th>Opciones</th> */}
            </tr>
          </thead>
          <tbody >
            {
              (data.length) ? data.map(row => (
                // console.log("dsds");
                <React.Fragment key={row.CODIGO}>
                  <FilaTabla key={row.CODIGO} datoRow={row} />
                </React.Fragment>
              )) : <div></div>
            }
          </tbody>
        </table>
      </div>


    </div>
  )
}

const TablaCuenta = (prosp) => {
  const selecionaFila = prosp.selecionaFila;
  const [data, setdata] = useState([])
  const [pendingModal, setpendingModal] = useState(false);
  const funListarcuentas = async () => {

    try {
      setpendingModal(true);
      const response = await fetch(`/cuentasRepFinanzCredi?vcodEmpresa=${cook_idEmp}`, { method: "GET", headers: { 'Content-Type': 'application/json' } });
      if (!response.ok) throw new Error(`Error al obtener los datos. Código de estado: ${response.status}`);
      const data = await response.json();
      console.log("--:: ", data[0]);
      setdata(data);
    } catch (error) {
      notify_warning('Sin datos registrados');
      console.error('Error:', error);
      // Manejar el error aquí según sea necesario.
    } finally {
      setpendingModal(false);
    }
  }

  const columnsModal = useMemo(() => [
    { header: 'Codigo', accessorKey: 'Codigo' },
    { header: 'Nombre', accessorKey: 'Nombre' },

  ], [])
  useEffect(() => {
    funListarcuentas()
  }, [])
  return (
    <>
      <div className="" >
        <p>Cuentas </p>
        <MaterialReactTable
          columns={columnsModal}
          data={data}
          state={{ isLoading: pendingModal }}
          initialState={{ density: 'compact', showColumnFilters: false, showGlobalFilter: false, }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: '' }}
          localization={MRT_Localization_ES} primary
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 8 }}
          enableRowVirtualization
          muiTableContainerProps={{ sx: { maxHeight: 400 } }}
          //enableEditing

          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 100, //make actions column wider
            },
          }}
          // onRowDoubleClick={selecionaFila}
          muiTableBodyRowProps={({ row }) => ({
            hover: false,

            onMouseDown: (event) => selecionaFila(event, row), // El  presiona el mouse 

          })}
          muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}

        />
      </div>
    </>
  )
}

const SelectChofer = (prosp) => {
  const setSelectFila = prosp.setSelectFila
  const fila = prosp.fila

  const [data, setdata] = useState([])

  const funListarChofer = useCallback(async (nom) => {
    //console.log("cadena:", cadena);
    let cadena = nom ? nom.toUpperCase() : "";
    const url = `/apiAlmacenLisChoferes?vcodEmpresa=${cook_idEmp}&cadenaBuscar=${cadena}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };

    try {

      const response = await fetch(url, opPe);
      if (!response.ok) {
        throw new Error(`Error al obtener los datos. Código de estado: ${response.status}`);
        return [];
      }
      const data = response.json();
      // console.log("data :", data);
      return data;
    } catch (error) {
      console.error("Error en funListarChofer:", error);
      return [];
      throw error;
    }
  }, [])

  const [selecChofer, selectChofer] = useState([""]);

  const [cadenaBuscar, setCadenabuscar] = useState('');
  const handleSelectChange = (selectedOption) => {
    selectChofer(selectedOption);
    setSelectFila(selectedOption)
  };


  return (
    <div className='td2' style={{ position: 'relative', zIndex: '3' }} >
      <AsyncSelect
        isMulti
        // styles={styles}
        placeholder="Busque por Cod./Desc."
        name="form-field-name"
        className=" from-control form-control-sm"
        value={fila}
        cacheOptions
        defaultOptions
        getOptionLabel={e => e.Placa + '  -  ' + e.Name}
        getOptionValue={e => e.Placa}
        loadOptions={funListarChofer}
        // onChange={handleSelectChange}
        maxMenuHeight={150}
        onChange={(e) => {
          setSelectFila(e)
          setTimeout(() => {
            $('.css-wsp0cs-MultiValueGeneric').each(function () {
              var textoCompleto = $(this).text();
              var textoCorto = textoCompleto.split('-')[0].trim();
              $(this).text(textoCorto);
            });
          }, 1000)
        }}
      />

    </div>
  )
}

const SelectLiquidador = (prosp) => {
  const setSelectFila = prosp.setSelectFila
  const filaselect = prosp.fila
  const funListarLiquidador = useCallback(async (nom) => {
    //console.log("cadena:", cadena);
    let cadena = nom ? nom.toUpperCase() : "";
    const url = `/apiAlmacenLisCodLiquidador?vcodEmpresa=${cook_idEmp}&cadenaBuscar=${cadena}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };

    try {
      const response = await fetch(url, opPe);
      if (!response.ok) {
        throw new Error(`Error al obtener los datos. Código de estado: ${response.status}`);
        return [];
      }
      const data = await response.json();
      console.log("data d:", data);
      return data;
    } catch (error) {
      console.error("Error en F()SelectLiquidador:", error);
      return [];
    }
  }, [])

  const handleSelectChange = (selectedOption) => {
    setSelectFila(selectedOption)
  };


  return (
    <div className='td2' style={{ position: 'relative', zIndex: '3' }} >
      <AsyncSelect
        styles={styles}
        placeholder="Busque por Cod./Desc."
        name="form-field-name"
        className=" from-control form-control-sm"
        value={filaselect}
        cacheOptions
        defaultOptions
        getOptionLabel={e => e.codeUser}
        getOptionValue={e => e.code}
        loadOptions={funListarLiquidador}
        onChange={handleSelectChange}
        maxMenuHeight={230}
      />

    </div>
  )
}

export const Report1 = (prosp) => { // 
  const { dataTipo } = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');
  // variables 
  const [text_fechaD, setText_fechaD] = useState(new Date());

  const [emp_sl_t_suc, emp_setSl_t_suc] = useState('');
  const emp_changeSl_t_suc = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    setnameSucur(selectedOption.text);

    emp_setSl_t_suc(event.target.value);
  }

  const [dataReport, setdataReport] = useState([]);
  const [pending, setPending] = useState(false);
  const [selecChofer, setselectChofer] = useState([]);

  const [Rdireccion, setdireccion] = useState('');
  const [Ralmacen, setalmacen] = useState('');
  const [Rconsolidado, setconsolidado] = useState('');
  const [nameSucur, setnameSucur] = useState('');
  const [detalleRepotPDF, setdetalleRepotPDF] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState([]);
  //  nuevo  agregado 
  const [pdfSrc, setPdfSrc] = useState('');

  const funVehiculo = useCallback( async (inputValue) => {
    const url ='/apiReporPlacasSucursal?vcodEmpresa='+cook_idEmp+'&cadenaBuscar='+inputValue+'&sucursal='+emp_sl_t_suc;
    const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
    const response = await fetch(url,opPe);
    const data = await response.json();
    return data;
  },[emp_sl_t_suc]) 

  useEffect(() => {
    setdataReport([])
    setselectChofer([])
    const loadDefaultOptions = async () => {
      const defaultData = await funVehiculo('');
      setDefaultOptions(defaultData);
    };
    loadDefaultOptions();
  }, [emp_sl_t_suc ]);

  function validarDatos(data) {

    let consolidado = "Varios";
    let almacen = "";
    let direccion = ""
    if (data.length && data.left != 0) {
      // Si hay datos, validamos los campos que se mostraran en la vista
      //
      if (selecChofer.length === 1) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].Consolidado !== '') {
            consolidado = data[i].U_AB_CODCON;
            break;
          }
        }
      }
      if (selecChofer.length === 0) {
        consolidado = 'Todos';
      }

      for (let i = 0; i < data.length; i++) {
        if (data[i].CodAlmacen !== '') {
          almacen = data[i].CodAlmacen;
          break;
        }
      }
      for (let i = 0; i < data.length; i++) {
        if (data[i].Dire !== '') {
          direccion = data[i].Dire;
          break;
        }
      }
    }
    setdireccion(direccion)
    setalmacen(almacen)
    setconsolidado(consolidado)
    return consolidado;
  }

  const handleDescargarPDF = () => {
    // Crear un enlace temporal
    const link = document.createElement('a');
    link.href = pdfSrc;
    // Nombre del archivo 
    link.download = 'R001_SALIDA_DE_PRODUCTOS_DE_ALMACEN.pdf'; 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

  const onClickListar = async () => {
    setPending(true)
    setdataReport([])
    console.log("INICON");
    const url_dataReport = `/funciondePDF?`;
    // cuando es multiple seleccion 
    let arrvPlacas = selecChofer.map(option => option.Placa)
    // cuando es solo una seleccion
    //let arrvPlacas = selecChofer?.Placa

    const body = {
      vTipoReport: 0,
      vcodEmpresa: cook_idEmp,
      fecha: moment(text_fechaD).format('YYYYMMDD'),
      fechaImpresion: moment().format('YYYY-MM-DD HH:mm:ss'),
      placa: arrvPlacas ? arrvPlacas : '',
      sucursal: emp_sl_t_suc,
      almacen: nameSucur? nameSucur:''
    };
    const opPe = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    };
    
    const response = await fetch(url_dataReport, opPe);
    if (response.status === 204) {
      setPending(false)
      notify_warning("¡No se encontraron registros !")
      return
    } 
    const res = await response.json();
    const { pdf, data } = res;
    
    setPdfSrc(`data:application/pdf;base64,${pdf}`);
    setdataReport(data)
    setPending(false)
    const a = validarDatos(data)
    
    console.log("selecChofer::", selecChofer);
    
    // const detalle = {
    //   "sucursal": nameSucur,
    //   "fecha": text_fechaD,
    //   "consolidado": a
    // };
    // setdetalleRepotPDF({ ...detalle })

  }

  const onClickListarAnterior = async () => {

    console.log("selecChofer:: ", selecChofer);
    console.log("nameSucur:: ", nameSucur);

    // return 
    if (!emp_sl_t_suc || emp_setSl_t_suc === '9999') {
      notify_warning("¡Seleccione una sucursal!");
      return;
    }
    setdataReport([])
    setPending(true)
    const url_dataReport = `/apiAlmacenReporte?`;
    let arrvPlacas = selecChofer.map(option => option.Placa)
    const body = {
      vTipoReport: dataTipo,
      vcodEmpresa: cook_idEmp,
      fecha: moment(text_fechaD).format('YYYYMMDD'),
      placa: arrvPlacas ? arrvPlacas : '',
      sucursal: emp_sl_t_suc
    };

    console.log("body: ", body);

    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url_dataReport, opPe);
    const data = await response.json();
    // console.log("data :", data);
    if (data.length === 0) {
      notify_warning("¡No de encontraron registros !")
    }

    const a = validarDatos(data)
    // var masculinos = data.slice(1, 50);
    setdataReport(data)
    setPending(false)
    const detalle = {
      "sucursal": nameSucur,
      "fecha": text_fechaD,
      "consolidado": a
    };
    setdetalleRepotPDF({ ...detalle })
    console.log("setdetalleRepotPDF::", detalle);

  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    setselectChofer([])
    setText_fechaD(new Date())
    emp_setSl_t_suc('')
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "REPORTE-SALIDA DE PRODUCTOS DE ALMACEN_" + moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "buffer" });//array
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "REPORTE-SALIDA DE PRODUCTOS DE ALMACEN_" + moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });//array
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const columnsReport = useMemo(() => [
    // { header: 'Consolidado', accessorKey: 'Consolidado' },
    { header: 'Documento', accessorKey: 'CardName' }, // filtrado
    { header: 'G.U.Negocio', accessorKey: 'ItmsGrpNam' }, // filtrado 
    { header: 'Codigo', accessorKey: 'ItemCode' },
    // { header: 'Alamacen', accessorKey: 'CodAlmacen' },
    // { header: 'Direccion', accessorKey: 'Dire' },

    { header: 'Articulo', accessorKey: 'Articulo' },
    // { header: 'Producto', accessorKey: 'ItemName' },
    { header: 'Lote', accessorKey: 'Lote' },
    { header: 'Unidad V. M.', accessorKey: 'Unid1' },
    { header: 'Cantidad M.', accessorKey: 'Q1' },
    { header: 'Unidad V. m.', accessorKey: 'InvntryUom' },
    { header: 'Cantidad m.', accessorKey: 'Q2' },
    // { header: 'n', accessorKey: 'U_SYP_MDFN' },


  ], []);

  const [openModal, setOpenModal] = useState(false);
  const hanOpenModal = () => { setOpenModal(true); }
  const hanClosModal = () => { setOpenModal(false); }
  return (
    <>
      <div className="row mt-2">
        <div className="col-lg-12">
          <div className="row mb-1">
            <div className="col-lg-1">
              <label className="form-label mb-0 " >Fecha:</label>
            </div>
            <div className="col-lg-2" style={{ zIndex: '3' }}>
              <DatePicker
                locale="es"
                wrapperClassName='input_date'
                className="form-control form-control-sm text-center"
                selected={text_fechaD}
                onChange={date => setText_fechaD(date)}
                dateFormat="yyyy-MM-dd"
              />
            </div>
            <div className="col-lg-1">
              <label className="form-label mb-0" >Sucursal :</label>
            </div>
            <div className="col-lg-2" >
              <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
            </div>
            <div className="col-lg-1">
              <label className="form-label mb-0" >Chofer :</label>
            </div>
            <div className="col-lg-4" style={{ zIndex: '4' }}>
              {/* <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} /> */}
              {/* <SelectChofer setSelectFila={setselectChofer} fila={selecChofer} />
               */}
              <AsyncSelect
                isMulti
                //styles={styles}
                placeholder="Busque por Cod/Desc"
                className="sele_pru from-control form-control-sm "
                value={selecChofer}
                cacheOptions
                defaultOptions={defaultOptions}
                getOptionLabel={e => e.Placa + '   /   ' + e.Name}
                getOptionValue={e => e.Placa}
                loadOptions={funVehiculo}
                onChange={(e) => {
                  // handleSelectChange(e)
                  setselectChofer(e)
                  setTimeout(() => {
                    $('.css-wsp0cs-MultiValueGeneric').each(function () {
                      var textoCompleto = $(this).text();
                      var textoCorto = textoCompleto.split('/')[0].trim();
                      $(this).text(textoCorto);
                    });
                  }, 1000)
                }}
              />
            </div>
          </div>

          <hr />

          {(dataReport.length != 0) && (
            <>

              <div className="row mb-1">
                <div className="col-1">
                  <label className=''> Consolidado </label>
                </div>
                <div className="col-3">
                  <label className='form-control form-control-sm'>{Rconsolidado}  </label>
                </div>
                <div className="col-1">
                  <label className=''> Almacen  </label>
                </div>
                <div className="col-2">
                  {/* <label className='form-control form-control-sm'> {Ralmacen} </label> */}
                  <label className='form-control form-control-sm'> {nameSucur} </label>
                  
                </div>
              </div>
              <div className="row">
                <div className="col-1">
                  <label className=''> Direccion  </label>
                </div>
                <div className="col-9">
                  <label className='form-control form-control-sm'>{Rdireccion} </label>
                </div>
              </div>
            </>



          )}

          <div className="row ">
            <div className="col-lg-12 text-end">
              <Tooltip title="Consultar información" placement="top">

                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </Tooltip>
              {dataReport && dataReport.length > 0 && (
                <>
                  <Tooltip title="Exportar" placement="top">
                    <div className="btn-group" role="group">
                      <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-label-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                        <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                        <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                        <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                      </ul>
                    </div>
                  </Tooltip>
                  <AButton tp_tilde="Visualizar Rep View" tp_posit="top" bt_class="btn btn-label-info btn-sm" bt_funct={hanOpenModal} ic_class="bx bxs-spreadsheet bx-ms" vl_tpbtn={2} />
                  {/* {dataReport && dataReport.length > 0 && (
                <PDFDownloadLink className="btn btn-sm  btn-label-youtube" document={<PdfAlmacenT001 datallegada={dataReport} detalleRepotPDF={detalleRepotPDF} />} fileName="R001_SALIDA_DE_PRODUCTOS_DE_ALMACEN.pdf">
                  {({ blob, url, loading, error }) =>
                    loading ? 'Cargando PDF...' : <i className="bx bx-xs bxs-file-pdf" ></i>
                  }
                </PDFDownloadLink>
              )}  */}


                  <AButton tp_tilde="Descargar PDF" id="btn3" tp_posit="top" bt_class="btn btn-sm  btn-label-youtube" bt_funct={handleDescargarPDF} ic_class="bx bx-xs bxs-file-pdf" vl_tpbtn={2} />
                </>
              )}
              <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="top" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
            </div>
          </div>
        </div>

      </div>

      <div className="card-datatable table-responsive ">
        <MaterialReactTable
          columns={columnsReport}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{
            density: 'compact',
            showColumnFilters: false,
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
            // grouping: ['Documento']
            grouping: ['CardName', 'ItmsGrpNam']
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 120, //make actions column wider
            },
          }}
          muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}


        />
      </div>


      <Modal
        open={openModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '100%', height: '100%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            className='btn btn-outline-dark btn-sm'
            onClick={hanClosModal}
          >
            <i className='fa fa-x'></i>
          </button>
          <div className="py-3" style={{ userSelect: 'none' }}>
            <RepViewSalidaAlamacen
              Rdireccion={Rdireccion}
              Ralmacen={nameSucur}
              Rconsolidado={Rconsolidado}
              Chofer={selecChofer.map(option => option.Name).join(', ')}
              data={dataReport}

            />
          </div>
        </Box>
      </Modal>
    </>
  )
}

export const Report1_dev = (prosp) => { // 
  const { dataTipo } = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');
  // variables 
  const [text_fechaD, setText_fechaD] = useState(new Date());

  const [emp_sl_t_suc, emp_setSl_t_suc] = useState('');
  const emp_changeSl_t_suc = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    setnameSucur(selectedOption.text);

    emp_setSl_t_suc(event.target.value);
  }

  const [dataReport, setdataReport] = useState([]);
  const [pending, setPending] = useState(false);
  const [selecChofer, setselectChofer] = useState([]);

  const [Rdireccion, setdireccion] = useState('');
  const [Ralmacen, setalmacen] = useState('');
  const [Rconsolidado, setconsolidado] = useState('');
  const [nameSucur, setnameSucur] = useState('');
  const [detalleRepotPDF, setdetalleRepotPDF] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState([]);
  //  nuevo  agregado 
  const [pdfSrc, setPdfSrc] = useState('');
  const [checked, setChecked] = useState(false);
  const [todasPlacas, setTodasPlacas] = useState(false);

  const handleCheckboxChange = (event) => {
    console.log("event.target.checked::", event.target.checked);
    if (event.target.checked){
      setselectChofer([])
    }
    setChecked(event.target.checked);
    setTodasPlacas(eve => !eve)
  };
  const funVehiculo = useCallback( async (inputValue) => {
    const url ='/apiReporPlacasSucursal?vcodEmpresa='+cook_idEmp+'&cadenaBuscar='+inputValue+'&sucursal='+emp_sl_t_suc;
    const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
    const response = await fetch(url,opPe);
    const data = await response.json();
    return data;
  },[emp_sl_t_suc]) 

  useEffect(() => {
    setdataReport([])
    setselectChofer([])
    const loadDefaultOptions = async () => {
      const defaultData = await funVehiculo('');
      setDefaultOptions(defaultData);
    };
    loadDefaultOptions();
  }, [emp_sl_t_suc ]);

  function validarDatos(data) {

    let consolidado = "Varios";
    let almacen = "";
    let direccion = ""
    if (data.length && data.left != 0) {
      // Si hay datos, validamos los campos que se mostraran en la vista
      //
      if (selecChofer.length === 1) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].Consolidado !== '') {
            consolidado = data[i].U_AB_CODCON;
            break;
          }
        }
      }
      if (selecChofer.length === 0) {
        consolidado = 'Todos';
      }

      for (let i = 0; i < data.length; i++) {
        if (data[i].CodAlmacen !== '') {
          almacen = data[i].CodAlmacen;
          break;
        }
      }
      for (let i = 0; i < data.length; i++) {
        if (data[i].Dire !== '') {
          direccion = data[i].Dire;
          break;
        }
      }
    }
    setdireccion(direccion)
    setalmacen(almacen)
    setconsolidado(consolidado)
    return consolidado;
  }

  const handleDescargarPDF = () => {
    // Crear un enlace temporal
    const link = document.createElement('a');
    link.href = pdfSrc;
    // Nombre del archivo 
    link.download = 'R001_SALIDA_DE_PRODUCTOS_DE_ALMACEN.pdf'; 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

  const onClickListar = async () => {
    try {
      
    
    setPending(true)
    setdataReport([])
    console.log("INICON");
    const url_dataReport = `/getSalidaProductos?`;
    // cuando es multiple seleccion 
    let arrvPlacas = selecChofer.map(option => option.Placa)
    // cuando es solo una seleccion
    //let arrvPlacas = selecChofer?.Placa
    // console.log("arrvPlacas::", arrvPlacas);
    
    // Validamos la sucursal 
    if (todasPlacas == true && (emp_sl_t_suc == '' || emp_sl_t_suc == '9999')) {
      notify_warning("seleccione la sucursal")
      // console.log("Seleccione la sucursal ");
      return
    }

    if (todasPlacas == false && arrvPlacas.length == 0 ) {
      // console.log("seleccione la sucursal");
      notify_warning("seleccione  Placa")
      return
    }
    // console.log("todasPlacas::", todasPlacas);
    // console.log("arrvPlacas::", arrvPlacas.length);
    
    // notify_valido("Funcionando")
    // return;
    
    const body = {
      vTipoReport: 0,
      vcodEmpresa: cook_idEmp,
      fecha: moment(text_fechaD).format('YYYYMMDD'),
      fechaImpresion: moment().format('YYYY-MM-DD HH:mm:ss'),
      placa: arrvPlacas ? arrvPlacas : '',
      sucursal: emp_sl_t_suc,
      almacen: nameSucur? nameSucur:'',
      tipotodo:todasPlacas?'TODOS':''
    };
    const opPe = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    };
    
    const response = await fetch(url_dataReport, opPe);
    if (response.status != 200) {
      setPending(false)
      notify_warning("¡No se encontraron registros !")
      if (response.status == 500) {
        setPending(false)
        notify_warning("¡Ocurrio un error en el proceso !")
        return
      }
      return
    }
   
    const res = await response.json();
    const { pdf, data } = res;
    
    setPdfSrc(`data:application/pdf;base64,${pdf}`);
    setdataReport(data)
    setPending(false)
    // const a = validarDatos(data)
    
    console.log("selecChofer::", selecChofer);
    
    // const detalle = {
    //   "sucursal": nameSucur,
    //   "fecha": text_fechaD,
    //   "consolidado": a
    // };
    // setdetalleRepotPDF({ ...detalle })
  } catch (error) {
      notify_warning("Datos incorrectos")
  }finally{
    setPending(false)
  }

  }

  const onClickListarAnterior = async () => {

    console.log("selecChofer:: ", selecChofer);
    console.log("nameSucur:: ", nameSucur);

    // return 
    if (!emp_sl_t_suc || emp_setSl_t_suc === '9999') {
      notify_warning("¡Seleccione una sucursal!");
      return;
    }
    setdataReport([])
    setPending(true)
    const url_dataReport = `/apiAlmacenReporte?`;
    let arrvPlacas = selecChofer.map(option => option.Placa)
    const body = {
      vTipoReport: dataTipo,
      vcodEmpresa: cook_idEmp,
      fecha: moment(text_fechaD).format('YYYYMMDD'),
      placa: arrvPlacas ? arrvPlacas : '',
      sucursal: emp_sl_t_suc
    };

    console.log("body: ", body);

    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url_dataReport, opPe);
    const data = await response.json();
    // console.log("data :", data);
    if (data.length === 0) {
      notify_warning("¡No de encontraron registros !")
    }

    const a = validarDatos(data)
    // var masculinos = data.slice(1, 50);
    setdataReport(data)
    setPending(false)
    const detalle = {
      "sucursal": nameSucur,
      "fecha": text_fechaD,
      "consolidado": a
    };
    setdetalleRepotPDF({ ...detalle })
    console.log("setdetalleRepotPDF::", detalle);

  };


  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    setselectChofer([])
    setText_fechaD(new Date())
    emp_setSl_t_suc('')
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "REPORTE-SALIDA DE PRODUCTOS DE ALMACEN_" + moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "buffer" });//array
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "REPORTE-SALIDA DE PRODUCTOS DE ALMACEN_" + moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });//array
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const columnsReport = useMemo(() => [
    // { header: 'Consolidado', accessorKey: 'Consolidado' },
    { header: 'Documento', accessorKey: 'CardName' }, // filtrado
    { header: 'G.U.Negocio', accessorKey: 'ItmsGrpNam' }, // filtrado 
    { header: 'Codigo', accessorKey: 'ItemCode' },
    // { header: 'Alamacen', accessorKey: 'CodAlmacen' },
    // { header: 'Direccion', accessorKey: 'Dire' },

    { header: 'Articulo', accessorKey: 'Articulo' },
    // { header: 'Producto', accessorKey: 'ItemName' },
    { header: 'Lote', accessorKey: 'Lote' },
    { header: 'Unidad V. M.', accessorKey: 'Unid1' },
    { header: 'Cantidad M.', accessorKey: 'Q1' },
    { header: 'Unidad V. m.', accessorKey: 'InvntryUom' },
    { header: 'Cantidad m.', accessorKey: 'Q2' },
    // { header: 'n', accessorKey: 'U_SYP_MDFN' },


  ], []);

  const [openModal, setOpenModal] = useState(false);
  const hanOpenModal = () => { setOpenModal(true); }
  const hanClosModal = () => { setOpenModal(false); }
  
  return (
    <>
      <div className="row mt-2">
        <div className="col-lg-12">
          <div className="row mb-1">
            <div className="col-lg-1">
              <label className="form-label mb-0 " >Fecha:</label>
            </div>
            <div className="col-lg-2" style={{ zIndex: '3' }}>
              <DatePicker
                locale="es"
                wrapperClassName='input_date'
                className="form-control form-control-sm text-center"
                selected={text_fechaD}
                onChange={date => setText_fechaD(date)}
                dateFormat="yyyy-MM-dd"
              />
            </div>
            <div className="col-lg-1">
              <label className="form-label mb-0" >Sucursal :</label>
            </div>
            <div className="col-lg-2" >
              <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
            </div>
            <div className="col-lg-1">
              <div className="form-check">
                <input  className="form-check-input"    type="checkbox"   id="flexCheckChecked"
                  checked={checked}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="flexCheckChecked">
                  Todos
                </label>
              </div>
            </div>
            
         {todasPlacas==false && (
              <>
                <div className="col-lg-1">
                  <label className="form-label mb-0" >Chofer :</label>
                </div>
                <div className="col-lg-3" style={{ zIndex: '4' }}>
                  {/* <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} /> */}
                  {/* <SelectChofer setSelectFila={setselectChofer} fila={selecChofer} />
               */}
                  <AsyncSelect
                    isMulti
                    //styles={styles}
                    placeholder="Busque por Cod/Desc"
                    className="sele_pru from-control form-control-sm "
                    value={selecChofer}
                    cacheOptions
                    defaultOptions={defaultOptions}
                    getOptionLabel={e => e.Placa + '   /   ' + e.Name}
                    getOptionValue={e => e.Placa}
                    loadOptions={funVehiculo}
                    onChange={(e) => {
                      // handleSelectChange(e)
                      setselectChofer(e)
                      setTimeout(() => {
                        $('.css-wsp0cs-MultiValueGeneric').each(function () {
                          var textoCompleto = $(this).text();
                          var textoCorto = textoCompleto.split('/')[0].trim();
                          $(this).text(textoCorto);
                        });
                      }, 1000)
                    }}
                  // isDisabled={true}
                  />
                </div>
              </> 
             )} 
          </div>
          

          <hr />
{/* 
          {(dataReport.length != 0) && (
            <>

              <div className="row mb-1">
                <div className="col-1">
                  <label className=''> Consolidado </label>
                </div>
                <div className="col-3">
                  <label className='form-control form-control-sm'>{Rconsolidado}  </label>
                </div>
                <div className="col-1">
                  <label className=''> Almacen  </label>
                </div>
                <div className="col-2">
                  <label className='form-control form-control-sm'> {nameSucur} </label>
                  
                </div>
              </div>
              <div className="row">
                <div className="col-1">
                  <label className=''> Direccion  </label>
                </div>
                <div className="col-9">
                  <label className='form-control form-control-sm'>{Rdireccion} </label>
                </div>
              </div>
            </>



          )} */}

          <div className="row ">
            <div className="col-lg-12 text-end">
              <Tooltip title="Consultar información" placement="top">

                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </Tooltip>
              {dataReport && dataReport.length > 0 && (
                <>
                  <Tooltip title="Exportar" placement="top">
                    <div className="btn-group" role="group">
                      <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-label-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                        <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                        <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                        <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                      </ul>
                    </div>
                  </Tooltip>
                  <AButton tp_tilde="Visualizar Rep View" tp_posit="top" bt_class="btn btn-label-info btn-sm" bt_funct={hanOpenModal} ic_class="bx bxs-spreadsheet bx-ms" vl_tpbtn={2} />
                  {/* {dataReport && dataReport.length > 0 && (
                <PDFDownloadLink className="btn btn-sm  btn-label-youtube" document={<PdfAlmacenT001 datallegada={dataReport} detalleRepotPDF={detalleRepotPDF} />} fileName="R001_SALIDA_DE_PRODUCTOS_DE_ALMACEN.pdf">
                  {({ blob, url, loading, error }) =>
                    loading ? 'Cargando PDF...' : <i className="bx bx-xs bxs-file-pdf" ></i>
                  }
                </PDFDownloadLink>
              )}  */}


                  <AButton tp_tilde="Descargar PDF" id="btn3" tp_posit="top" bt_class="btn btn-sm  btn-label-youtube" bt_funct={handleDescargarPDF} ic_class="bx bx-xs bxs-file-pdf" vl_tpbtn={2} />
                </>
              )}
              <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="top" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
            </div>
          </div>
        </div>

      </div>

      <div className="card-datatable table-responsive ">
        <MaterialReactTable
          columns={columnsReport}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{
            density: 'compact',
            showColumnFilters: false,
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
            // grouping: ['Documento']
            grouping: ['CardName', 'ItmsGrpNam']
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 120, //make actions column wider
            },
          }}
          muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}


        />
      </div>


      <Modal
        open={openModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '100%', height: '100%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            className='btn btn-outline-dark btn-sm'
            onClick={hanClosModal}
          >
            <i className='fa fa-x'></i>
          </button>
          <div className="py-3" style={{ userSelect: 'none' }}>
            <RepViewSalidaAlamacen
              Rdireccion={Rdireccion}
              Ralmacen={nameSucur}
              Rconsolidado={Rconsolidado}
              Chofer={selecChofer.map(option => option.Name).join(', ')}
              data={dataReport}

            />
          </div>
        </Box>
      </Modal>
    </>
  )
}
export const Report2 = (prosp) => { //   
  const { dataTipo } = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');
  // variables 
  const [text_fechaD, setText_fechaD] = useState(new Date());
  const [nameSucur, setnameSucur] = useState('');
  const [emp_sl_t_suc, emp_setSl_t_suc] = useState('');
  const emp_changeSl_t_suc = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    setnameSucur(selectedOption.text);
    emp_setSl_t_suc(event.target.value);
  }



  const [dataReport, setdataReport] = useState([]);
  const [pending, setPending] = useState(false);

  // DATOS
  const [Rdireccion, setdireccion] = useState('');
  const [Ralmacen, setalmacen] = useState('');
  const [Rconsolidado, setconsolidado] = useState('');

  const [selecLiquidador, setselecLiquidador] = useState({
    code: '',
    codeUser: ''
  })
  const [conLiquidador, setconLiquidador] = useState('')

  const [selecChofer, setselectChofer] = useState({
    Placa: '',
    Peso:'' ,
    Volumen:'',
    Sucursal: '',
    Code: '',
    Name:''
  });
  const [pdfSrc, setPdfSrc] = useState('');
  
  const funVehiculo = useCallback( async (inputValue) => {
    const url ='/apiReporPlacasSucursal?vcodEmpresa='+cook_idEmp+'&cadenaBuscar='+inputValue+'&sucursal='+emp_sl_t_suc;
    const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
    const response = await fetch(url,opPe);
    const data = await response.json();
    return data;
  },[emp_sl_t_suc]) 

  const [defaultOptions, setDefaultOptions] = useState([]);
  useEffect(() => {
    setdataReport([])
    setselectChofer({
      Placa: '',
      Peso:'' ,
      Volumen:'',
      Sucursal: '',
      Code: '',
      Name:''
    })
    const loadDefaultOptions = async () => {
      const defaultData = await funVehiculo('');
      setDefaultOptions(defaultData);
    };
    loadDefaultOptions();
  }, [emp_sl_t_suc ]);
  const onChangeLiquidador = (event) => {
    setconLiquidador(event.target.value);
  }
  const [openModal, setOpenModal] = useState(false);
  const hanOpenModal = () => { setOpenModal(true); }
  const hanClosModal = () => { setOpenModal(false); }

  const onClickListar = async () => {
    console.log("selecChofer:: ", selecChofer);
    // return ;
    if (!emp_sl_t_suc || emp_setSl_t_suc === '9999') {
      notify_warning("¡Seleccione una sucursal!");
      return;
    }
    // console.log("selecLiquidador:", selecLiquidador);
    if (selecChofer.Placa=='') {
      notify_warning("¡Seleccione al vehiculo!");
      return;
    }
    setPending(true)

    const url_dataReport = `/apiAlmacenReporte?`;
    const body = {
      vTipoReport: dataTipo,
      vcodEmpresa: cook_idEmp,
      fecha: moment(text_fechaD).format('YYYYMMDD'),
      placa: selecChofer.Placa,
      sucursal: emp_sl_t_suc,
      fechaImpresion: moment().format('YYYY-MM-DD HH:mm:ss'),
      almacen: nameSucur? nameSucur:''
    };
    console.log("body: ", body);


    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url_dataReport, opPe);
    if (response.status === 204) {
      setPending(false)
      notify_warning("¡No se encontraron registros !")
      return
    } 

    const res = await response.json();
    const { pdf, data } = res;
    setPdfSrc(`data:application/pdf;base64,${pdf}`);
    // console.log(data);
    if (data.length === 0) {
      notify_warning("No se encontraron registros!");
    }
    setdataReport(data)
    validarDatos(data)
    setPending(false)
  };
  function validarDatos(data) {

    let consolidado = "Todos";
    let almacen = "";
    let direccion = ""
    if (data.length || data.left != 0) {
      // SI  no se ha selecionado el chofer, la data de agrupa por consolidado
      for (let i = 0; i < data.length; i++) {
        if (data[i].Consolidado !== '') {
          consolidado = data[i].U_AB_CODCON;
          break;
        }
      }

      for (let i = 0; i < data.length; i++) {
        if (data[i].CodAlmacenacen !== '') {
          almacen = data[i].CodAlmacen;
          break;
        }
      }
      for (let i = 0; i < data.length; i++) {
        if (data[i].Dire !== '') {
          direccion = data[i].Dire;
          break;
        }
      }
    }
    console.log("direccion:", direccion);
    setdireccion(direccion)
    setalmacen(almacen)
    setconsolidado(consolidado)

  }
  const handleDescargarPDF = () => {
    // Crear un enlace temporal
    const link = document.createElement('a');
    link.href = pdfSrc;
    // Nombre del archivo 
    link.download = 'R002_REPORTE_DEVOLUCION_DE_MERCANCIAS_POR_RECHAZOS.pdf'; 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    setText_fechaD(new Date())
    setselectChofer({
      Placa: '',
      Peso:'' ,
      Volumen:'',
      Sucursal: '',
      Code: '',
      Name:''
    })
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "REPORTE DEVOLUCION DE MERCANCIAS POR RECHAZOS DE CLIENTE_" + moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      // const response = await fetch(url0,opti_t);
      // const datadtos = await response.json();
      //console.log("--saas",datadtos);
      //setData(datadtos)
      if (dataReport.length !== 0) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "buffer" });//array
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    //console.log(sl_t_unid.desLinea);
    const fileType = "xlsx"
    const nameFileType = "REPORTE-DEVOLUCION DE MERCANCIAS POR RECHAZOS DE CLIENTE_" + moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });//array
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const columnsReport = useMemo(() => [
    { header: 'Consolidado', accessorKey: 'U_AB_CODCON' },
    // { header: 'Documento', accessorKey: 'CodAlmacen' },
    { header: 'G.U.Negocio  ', accessorKey: 'CardName' },
    // { header: 'Direccion', accessorKey: 'Dire' },
    // { header: 'CardName', accessorKey: 'CardName' },
    // { header: '  U.Negocio', accessorKey: 'UnidadNeg' },
    { header: ' Cod. U.Negocio', accessorKey: 'ItmsGrpNam' },
    // { header: 'Articulo', accessorKey: 'Articulo' },
    { header: 'Cod. Articulo ', accessorKey: 'ItemCode' },
    { header: 'Nom. Articulo ', accessorKey: 'ItemName' },
    { header: 'Lote ', accessorKey: 'Lote' },
    { header: 'U.M', accessorKey: 'Unid1' },
    { header: 'Unidad V.M. ', accessorKey: 'Q1' },
    { header: 'U.m', accessorKey: 'InvntryUom' },
    { header: 'Unidad V.m.', accessorKey: 'Q2' },
    // { header: 'DocDate', accessorKey: 'U_SYP_MDFN' },
  ], []);


  // componente 
  const SelectTipoMulti = ({ id, url, value, onChange, disabled }) => {
    const [select, setSelect] = useState([])
    useEffect(() => {
      const showData1 = async () => {
        const apig = url
        //const body = { txt_tipo: 'TE', };
        const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
        const response = await fetch(apig, opPe);
        const datadtos = await response.json();
        console.log(datadtos);
        setSelect(datadtos)
      }
      showData1()
    }, [url])
  
    return (
      <select id={id} className="form-select form-select-sm" value={value} onChange={onChange} disabled={disabled}>
        <option value='9999' >-- Seleccione --</option>
        {
          select?.map((item, index) => {
            return <option value={item?.code} key={index} >{item?.codeUser}</option>
          })
        }
      </select>
    )
  }

  return (
    <>
      <div className="row mt-2">
        <div className="col-lg-12">
          <div className="row mb-1">
            <div className="col-lg-1">
              <label className="form-label mb-0 " >Fecha:</label>
            </div>
            <div className="col-lg-2" style={{ zIndex: '3' }}>
              <DatePicker
                locale="es"
                wrapperClassName='input_date'
                className="form-control form-control-sm text-center"
                selected={text_fechaD}
                onChange={date => setText_fechaD(date)}
                dateFormat="yyyy-MM-dd"
              />
            </div>
            <div className="col-lg-1">
              <label className="form-label mb-0" >Sucursal :</label>
            </div>
            <div className="col-lg-2" >
              <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
            </div>
            <div className="col-lg-1">
              <label className="form-label mb-0" >Placa: </label>
            </div>
            <div className="col-lg-3" style={{ zIndex: '3' }}>
            {/* '/apiReporPlacasSucursal?vcodEmpresa='+cook_idEmp+'&cadenaBuscar='+inputValue+'&sucursal='+emp_sl_t_suc */}
            {/* <SelectTipoMulti id="slc_t_tga" url={`/apiAlmacenLisCodLiquidador?vcodEmpresa=${cook_idEmp}&sucursal=${emp_sl_t_suc}&cadenaBuscar=  `} value={conLiquidador} onChange={onChangeLiquidador}  /> */}
            {/* <SelectTipo id="slc_t_tga" url={`/apiConsulCON?vcodEmpresa=${cook_idEmp}&vcodSucursal=${emp_sl_t_suc}&vFecha=${moment(text_fechaD).format('YYYY-MM-DD')}`} value={conLiquidador} onChange={onChangeLiquidador}  /> */}
            {/* <SelectTipo id="slc_t_tga" url={'/apiReporPlacasSucursal?vcodEmpresa='+cook_idEmp+'&cadenaBuscar=&sucursal='+emp_sl_t_suc} value={conLiquidador} onChange={onChangeLiquidador}  /> */}
            {/* const url = `/apiAlmacenLisCodLiquidador?vcodEmpresa=${cook_idEmp}&cadenaBuscar=${cadena}`; */}
            {/* <SelectLiquidador setSelectFila={setselecLiquidador} fila={selecLiquidador} /> */}
            <AsyncSelect
                // isMulti
                styles={styles}
                placeholder="Busque por Cod/Desc"
                className="sele_pru from-control form-control-sm "
                value={selecChofer}
                cacheOptions
                defaultOptions={defaultOptions}
                getOptionLabel={e => e.Placa + '   -   ' + e.Name}
                getOptionValue={e => e.Placa}
                loadOptions={funVehiculo}
                onChange={setselectChofer}

                // onChange={(e) => {
                //   // handleSelectChange(e)
                //   setselectChofer(e)
                //   setTimeout(() => {
                //     $('.css-wsp0cs-MultiValueGeneric').each(function () {
                //       var textoCompleto = $(this).text();
                //       var textoCorto = textoCompleto.split('/')[0].trim();
                //       $(this).text(textoCorto);
                //     });
                //   }, 1000)
                // }}
              />
            
            </div>
          </div>

          <hr />
          {(dataReport.length != 0) && (
            <>

              <div className="row mb-1">
                <div className="col-1">
                  <label className=''> Consolidado </label>
                </div>
                <div className="col-3">
                  <label className='form-control form-control-sm'>{Rconsolidado}  </label>
                </div>
                <div className="col-1">
                  <label className=''> Almacen  </label>
                </div>
                <div className="col-2">
                  <label className='form-control form-control-sm'> {nameSucur} </label>
                </div>
              </div>
              <div className="row">
                <div className="col-1">
                  <label className=''> Direccion  </label>
                </div>
                <div className="col-9">
                  <label className='form-control form-control-sm'>{Rdireccion} </label>
                </div>
              </div>
            </>



          )}
          <div className="row ">
            <div className="col-lg-12 text-end">

              <Tooltip title="Consultar información" placement="top">

                <button title="Consultar información" className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>

              </Tooltip>

              <Tooltip title="Exportar" placement="top">
                <div className="btn-group" role="group">
                  <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                    <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                    <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                    <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                  </ul>
                </div>
              </Tooltip>
              {dataReport && dataReport.length > 0 && (
                <AButton tp_tilde="Descargar PDF" id="btn3" tp_posit="top" bt_class="btn btn-sm  btn-label-youtube" bt_funct={handleDescargarPDF} ic_class="bx bx-xs bxs-file-pdf" vl_tpbtn={2} />
              )}
              <AButton tp_tilde="Visualizar Rep View" tp_posit="top" bt_class="btn btn-label-info btn-sm" bt_funct={hanOpenModal} ic_class="bx bxs-spreadsheet bx-ms" vl_tpbtn={2} />
              <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
            </div>
          </div>
        </div>

      </div>

      <div className="card-datatable table-responsive ">
        <MaterialReactTable
          columns={columnsReport}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{
            density: 'compact', showColumnFilters: false,
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 120, //make actions column wider
            },
          }}
          muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}


        />
      </div>
      <Modal
        open={openModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '100%', height: '100%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            className='btn btn-outline-dark btn-sm'
            onClick={hanClosModal}
          >
            <i className='fa fa-x'></i>
          </button>
          <div className="py-3" style={{ userSelect: 'none' }}>
            <RepViewAlmacen
              Rdireccion={Rdireccion}
              Ralmacen={nameSucur}
              Rconsolidado={Rconsolidado}
              RResponsable={selecLiquidador.code}
              data={dataReport}

            />
          </div>
        </Box>
      </Modal>
    </>
  )
}
export const Report3 = (prosp) => { //   
  const { dataTipo } = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');
  // variables 
  const [text_fechaD, setText_fechaD] = useState(new Date());

  const [emp_sl_t_suc, emp_setSl_t_suc] = useState('');
  const emp_changeSl_t_suc = (event) => {
    emp_setSl_t_suc(event.target.value);
  }

  const [dataReport, setdataReport] = useState([]);
  const [pending, setPending] = useState(false);

  // DATOS
  const [Rdireccion, setdireccion] = useState('');
  const [Ralmacen, setalmacen] = useState('');
  const [Rconsolidado, setconsolidado] = useState('');

  const [selecLiquidador, setselecLiquidador] = useState({
    code: '',
    codeUser: ''
  })


  const onClickListar = async () => {
    if (!emp_sl_t_suc || emp_sl_t_suc === '9999') {
      notify_warning("¡Seleccione una sucursal!");
      return;
    }
    setPending(true)
    const url_dataReport = `/apiAlmacenReporte?`;
    const body = {
      vTipoReport: dataTipo,
      vcodEmpresa: cook_idEmp,
      fecha: moment(text_fechaD).format('YYYYMMDD'),
      sucursal: emp_sl_t_suc
    };

    console.log("body: ", body);


    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url_dataReport, opPe);
    const data = await response.json();
    console.log(data);
    if (data.length === 0) {
      notify_warning("No se encontraron registros!");
    }
    setdataReport(data)
    setPending(false)
  };

  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    setText_fechaD(new Date())

  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "REPORTE-GUIA DE UNIDADES PROGRAMADAS_" + moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      // const response = await fetch(url0,opti_t);
      // const datadtos = await response.json();
      //console.log("--saas",datadtos);
      //setData(datadtos)
      if (dataReport.length !== 0) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "buffer" });//array
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }

  const onClickExcel = async () => {
    //console.log(sl_t_unid.desLinea);
    const fileType = "xlsx"
    const nameFileType = "REPORTE-GUIA DE UNIDADES PROGRAMADAS_" + moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });//array
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }

  const columnsReport = useMemo(() => [
    { header: 'Tipo de documento', accessorKey: 'Tipo de documento' },
    { header: 'RUC/DNI', accessorKey: 'RUC/DNI' },
    { header: 'Numero de comprobante', accessorKey: 'Numero de comprobante' },
    { header: 'Direcccion', accessorKey: 'Direcccion' },
    { header: 'Peso', accessorKey: 'Peso' },
    { header: 'PlacaVehiculo', accessorKey: 'PlacaVehiculo' },
    { header: 'Sucursal', accessorKey: 'Sucursal' },

  ], []);

  return (
    <>
      <div className="row mt-2">
        <div className="col-lg-12">
          <div className="row mb-1">
            <div className="col-lg-1">
              <label className="form-label mb-0 " >Fecha:</label>
            </div>
            <div className="col-lg-2" style={{ zIndex: '3' }}>
              <DatePicker
                locale="es"
                wrapperClassName='input_date'
                className="form-control form-control-sm text-center"
                selected={text_fechaD}
                onChange={date => setText_fechaD(date)}
                dateFormat="yyyy-MM-dd"
              />
            </div>
            <div className="col-lg-1">
              <label className="form-label mb-0" >Sucursal :</label>
            </div>
            <div className="col-lg-2" >
              <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
            </div>
          </div>

          <hr />

          <div className="row ">
            <div className="col-lg-12 text-end">
              <Tooltip title="Consultar información" placement="top">
                <button title="Consultar información" className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </Tooltip>
              <Tooltip title="Exportar" placement="top">
                <div className="btn-group" role="group">
                  <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                    <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                    <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                    <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                  </ul>
                </div>
              </Tooltip>
              {/* <AButton tp_tilde="Visualizar Rep View" tp_posit="top" bt_class="btn btn-label-info btn-sm" bt_funct={hanOpenModal} ic_class="bx bxs-spreadsheet bx-ms" vl_tpbtn={2} /> */}
              <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
            </div>
          </div>
        </div>

      </div>

      <div className="card-datatable table-responsive ">
        <MaterialReactTable
          columns={columnsReport}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{
            density: 'compact', showColumnFilters: false,
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 120, //make actions column wider
            },
          }}
          muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}


        />
      </div>
      {/* <Modal
        open={openModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '100%', height: '100%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            className='btn btn-outline-dark btn-sm'
            onClick={hanClosModal}
          >
            <i className='fa fa-x'></i>
          </button>
          <div className="py-3" style={{ userSelect: 'none' }}>
            <RepViewAlmacen 
              Rdireccion = {Rdireccion}
              Ralmacen = {Ralmacen}
              Rconsolidado = {Rconsolidado}
              RResponsable = {selecLiquidador.code}
              data={dataReport} 
            
            />
          </div>
        </Box>
      </Modal> */}
    </>
  )
}
export const Report4_aux = (prosp) => { //  Reporte  por cliente   
  const { dataTipo } = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');
  // variables 
  const [text_fechaD, setText_fechaD] = useState(new Date());
  const [nameSucur, setnameSucur] = useState('');
  const [emp_sl_t_suc, emp_setSl_t_suc] = useState('');
  const emp_changeSl_t_suc = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    setnameSucur(selectedOption.text);
    emp_setSl_t_suc(event.target.value);
  }



  const [dataReport, setdataReport] = useState([]);
  const [pending, setPending] = useState(false);

  // DATOS
  const [Rdireccion, setdireccion] = useState('');
  const [Ralmacen, setalmacen] = useState('');
  const [Rconsolidado, setconsolidado] = useState('');

  const [selecLiquidador, setselecLiquidador] = useState({
    code: '',
    codeUser: ''
  })
  const [conLiquidador, setconLiquidador] = useState('')

  const [selecChofer, setselectChofer] = useState({
    Placa: '',
    Peso:'' ,
    Volumen:'',
    Sucursal: '',
    Code: '',
    Name:''
  });
  const [pdfSrc, setPdfSrc] = useState('');
  
  const funVehiculo = useCallback( async (inputValue) => {
    const url ='/apiReporPlacasSucursal?vcodEmpresa='+cook_idEmp+'&cadenaBuscar='+inputValue+'&sucursal='+emp_sl_t_suc;
    const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
    const response = await fetch(url,opPe);
    const data = await response.json();
    return data;
  },[emp_sl_t_suc]) 

  const [defaultOptions, setDefaultOptions] = useState([]);
  useEffect(() => {
    setdataReport([])
    setselectChofer({
      Placa: '',
      Peso:'' ,
      Volumen:'',
      Sucursal: '',
      Code: '',
      Name:''
    })
    const loadDefaultOptions = async () => {
      const defaultData = await funVehiculo('');
      setDefaultOptions(defaultData);
    };
    loadDefaultOptions();
  }, [emp_sl_t_suc ]);
  const onChangeLiquidador = (event) => {
    setconLiquidador(event.target.value);
  }
  const [openModal, setOpenModal] = useState(false);
  const hanOpenModal = () => { setOpenModal(true); }
  const hanClosModal = () => { setOpenModal(false); }

  const onClickListar = async () => {
    console.log("selecChofer:: ", selecChofer);
    // return ;
    if (!emp_sl_t_suc || emp_setSl_t_suc === '9999') {
      notify_warning("¡Seleccione una sucursal!");
      return;
    }
    // console.log("selecLiquidador:", selecLiquidador);
    if (selecChofer.Placa=='') {
      notify_warning("¡Seleccione al vehiculo!");
      return;
    }
    setPending(true)

    const url_dataReport = `/apiAlmacenReporte?`;
    const body = {
      vTipoReport: dataTipo,
      vcodEmpresa: cook_idEmp,
      fecha: moment(text_fechaD).format('YYYYMMDD'),
      placa: selecChofer.Placa,
      sucursal: emp_sl_t_suc,
      fechaImpresion: moment().format('YYYY-MM-DD HH:mm:ss'),
      almacen: nameSucur? nameSucur:''
    };
    console.log("body: ", body);


    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url_dataReport, opPe);
    if (response.status === 204) {
      setPending(false)
      notify_warning("¡No se encontraron registros !")
      return
    } 

    const res = await response.json();
    const { pdf, data } = res;
    setPdfSrc(`data:application/pdf;base64,${pdf}`);
    // console.log(data);
    if (data.length === 0) {
      notify_warning("No se encontraron registros!");
    }
    setdataReport(data)
    validarDatos(data)
    setPending(false)
  };
  function validarDatos(data) {

    let consolidado = "Todos";
    let almacen = "";
    let direccion = ""
    if (data.length || data.left != 0) {
      // SI  no se ha selecionado el chofer, la data de agrupa por consolidado
      for (let i = 0; i < data.length; i++) {
        if (data[i].Consolidado !== '') {
          consolidado = data[i].Consolidado;
          break;
        }
      }

      for (let i = 0; i < data.length; i++) {
        if (data[i].CodAlmacenacen !== '') {
          almacen = data[i].CodAlmacen;
          break;
        }
      }
      for (let i = 0; i < data.length; i++) {
        if (data[i].Dire !== '') {
          direccion = data[i].Dire;
          break;
        }
      }
    }
    console.log("direccion:", direccion);
    setdireccion(direccion)
    setalmacen(almacen)
    setconsolidado(consolidado)

  }
  const handleDescargarPDF = () => {
    // Crear un enlace temporal
    const link = document.createElement('a');
    link.href = pdfSrc;
    // Nombre del archivo 
    link.download = 'R002_REPORTE_DEVOLUCION_DE_MERCANCIAS_POR_RECHAZOS.pdf'; 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    setText_fechaD(new Date())
    setselectChofer({
      Placa: '',
      Peso:'' ,
      Volumen:'',
      Sucursal: '',
      Code: '',
      Name:''
    })
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "REPORTE DEVOLUCION DE MERCANCIAS POR RECHAZOS DE CLIENTE_" + moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      // const response = await fetch(url0,opti_t);
      // const datadtos = await response.json();
      //console.log("--saas",datadtos);
      //setData(datadtos)
      if (dataReport.length !== 0) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "buffer" });//array
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    //console.log(sl_t_unid.desLinea);
    const fileType = "xlsx"
    const nameFileType = "REPORTE-DEVOLUCION DE MERCANCIAS POR RECHAZOS DE CLIENTE_" + moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });//array
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const columnsReport = useMemo(() => [
    // { header: 'Consolidado', accessorKey: 'Consolidado' },
    // { header: 'Documento', accessorKey: 'CodAlmacen' },
    { header: 'G.U.Negocio  ', accessorKey: 'CardName' },
    // { header: 'Direccion', accessorKey: 'Dire' },
    // { header: 'CardName', accessorKey: 'CardName' },
    { header: ' Cod. U.Negocio', accessorKey: 'ItmsGrpNam' },
    // { header: 'Articulo', accessorKey: 'Articulo' },
    { header: 'Cod. Articulo ', accessorKey: 'ItemCode' },
    { header: 'Nom. Articulo ', accessorKey: 'ItemName' },
    { header: 'Lote ', accessorKey: 'Lote' },
    { header: 'U.M', accessorKey: 'Unid1' },
    { header: 'Unidad V.M. ', accessorKey: 'Q1' },
    { header: 'U.m', accessorKey: 'InvntryUom' },
    { header: 'Unidad V.m.', accessorKey: 'Q2' },
    { header: 'DocDate', accessorKey: 'U_SYP_MDFN' },
  ], []);


  // componente 
  const SelectTipoMulti = ({ id, url, value, onChange, disabled }) => {
    const [select, setSelect] = useState([])
    useEffect(() => {
      const showData1 = async () => {
        const apig = url
        //const body = { txt_tipo: 'TE', };
        const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
        const response = await fetch(apig, opPe);
        const datadtos = await response.json();
        console.log(datadtos);
        setSelect(datadtos)
      }
      showData1()
    }, [url])
  
    return (
      <select id={id} className="form-select form-select-sm" value={value} onChange={onChange} disabled={disabled}>
        <option value='9999' >-- Seleccione --</option>
        {
          select?.map((item, index) => {
            return <option value={item?.code} key={index} >{item?.codeUser}</option>
          })
        }
      </select>
    )
  }

  return (
    <>
      <div className="row mt-2">
        <div className="col-lg-12">
          <div className="row mb-1">
            <div className="col-lg-1">
              <label className="form-label mb-0 " >Fecha:</label>
            </div>
            <div className="col-lg-2" style={{ zIndex: '3' }}>
              <DatePicker
                locale="es"
                wrapperClassName='input_date'
                className="form-control form-control-sm text-center"
                selected={text_fechaD}
                onChange={date => setText_fechaD(date)}
                dateFormat="yyyy-MM-dd"
              />
            </div>
            <div className="col-lg-1">
              <label className="form-label mb-0" >Sucursal :</label>
            </div>
            <div className="col-lg-2" >
              <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
            </div>
            <div className="col-lg-1">
              <label className="form-label mb-0" >Placa: </label>
            </div>
            <div className="col-lg-3" style={{ zIndex: '3' }}>
            {/* '/apiReporPlacasSucursal?vcodEmpresa='+cook_idEmp+'&cadenaBuscar='+inputValue+'&sucursal='+emp_sl_t_suc */}
            {/* <SelectTipoMulti id="slc_t_tga" url={`/apiAlmacenLisCodLiquidador?vcodEmpresa=${cook_idEmp}&sucursal=${emp_sl_t_suc}&cadenaBuscar=  `} value={conLiquidador} onChange={onChangeLiquidador}  /> */}
            {/* <SelectTipo id="slc_t_tga" url={`/apiConsulCON?vcodEmpresa=${cook_idEmp}&vcodSucursal=${emp_sl_t_suc}&vFecha=${moment(text_fechaD).format('YYYY-MM-DD')}`} value={conLiquidador} onChange={onChangeLiquidador}  /> */}
            {/* <SelectTipo id="slc_t_tga" url={'/apiReporPlacasSucursal?vcodEmpresa='+cook_idEmp+'&cadenaBuscar=&sucursal='+emp_sl_t_suc} value={conLiquidador} onChange={onChangeLiquidador}  /> */}
            {/* const url = `/apiAlmacenLisCodLiquidador?vcodEmpresa=${cook_idEmp}&cadenaBuscar=${cadena}`; */}
            {/* <SelectLiquidador setSelectFila={setselecLiquidador} fila={selecLiquidador} /> */}
            <AsyncSelect
                // isMulti
                styles={styles}
                placeholder="Busque por Cod/Desc"
                className="sele_pru from-control form-control-sm "
                value={selecChofer}
                cacheOptions
                defaultOptions={defaultOptions}
                getOptionLabel={e => e.Placa + '   -   ' + e.Name}
                getOptionValue={e => e.Placa}
                loadOptions={funVehiculo}
                onChange={setselectChofer}

                // onChange={(e) => {
                //   // handleSelectChange(e)
                //   setselectChofer(e)
                //   setTimeout(() => {
                //     $('.css-wsp0cs-MultiValueGeneric').each(function () {
                //       var textoCompleto = $(this).text();
                //       var textoCorto = textoCompleto.split('/')[0].trim();
                //       $(this).text(textoCorto);
                //     });
                //   }, 1000)
                // }}
              />
            
            </div>
          </div>

          <hr />
          {(dataReport.length != 0) && (
            <>

              <div className="row mb-1">
                <div className="col-1">
                  <label className=''> Consolidado </label>
                </div>
                <div className="col-3">
                  <label className='form-control form-control-sm'>{Rconsolidado}  </label>
                </div>
                <div className="col-1">
                  <label className=''> Almacen  </label>
                </div>
                <div className="col-2">
                  <label className='form-control form-control-sm'> {Ralmacen} </label>
                </div>
              </div>
              <div className="row">
                <div className="col-1">
                  <label className=''> Direccion  </label>
                </div>
                <div className="col-9">
                  <label className='form-control form-control-sm'>{Rdireccion} </label>
                </div>
              </div>
            </>



          )}
          <div className="row ">
            <div className="col-lg-12 text-end">

              <Tooltip title="Consultar información" placement="top">

                <button title="Consultar información" className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>

              </Tooltip>

              <Tooltip title="Exportar" placement="top">
                <div className="btn-group" role="group">
                  <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                    <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                    <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                    <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                  </ul>
                </div>
              </Tooltip>
              {dataReport && dataReport.length > 0 && (
                <AButton tp_tilde="Descargar PDF" id="btn3" tp_posit="top" bt_class="btn btn-sm  btn-label-youtube" bt_funct={handleDescargarPDF} ic_class="bx bx-xs bxs-file-pdf" vl_tpbtn={2} />
              )}
              <AButton tp_tilde="Visualizar Rep View" tp_posit="top" bt_class="btn btn-label-info btn-sm" bt_funct={hanOpenModal} ic_class="bx bxs-spreadsheet bx-ms" vl_tpbtn={2} />
              <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
            </div>
          </div>
        </div>

      </div>

      <div className="card-datatable table-responsive ">
        <MaterialReactTable
          columns={columnsReport}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{
            density: 'compact', showColumnFilters: false,
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 120, //make actions column wider
            },
          }}
          muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}


        />
      </div>
      <Modal
        open={openModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '100%', height: '100%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            className='btn btn-outline-dark btn-sm'
            onClick={hanClosModal}
          >
            <i className='fa fa-x'></i>
          </button>
          <div className="py-3" style={{ userSelect: 'none' }}>
            <RepViewAlmacen
              Rdireccion={Rdireccion}
              Ralmacen={Ralmacen}
              Rconsolidado={Rconsolidado}
              RResponsable={selecLiquidador.code}
              data={dataReport}

            />
          </div>
        </Box>
      </Modal>
    </>
  )
}
export const Report2aux = (prosp) => { //   
  const { dataTipo } = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');
  // variables 
  const [text_fechaD, setText_fechaD] = useState(new Date());

  const [emp_sl_t_suc, emp_setSl_t_suc] = useState('');
  const emp_changeSl_t_suc = (event) => {
    emp_setSl_t_suc(event.target.value);
  }

  const [dataReport, setdataReport] = useState([]);
  const [pending, setPending] = useState(false);
  const [selecChofer, setselectChofer] = useState('');

  const onClickListar = async () => {

    setdataReport([])
    setPending(true)
    const url_dataReport = `/apiAlmacenReporte?`;
    const body = {
      vTipoReport: dataTipo,
      vcodEmpresa: cook_idEmp,
      fecha: moment(text_fechaD).format('YYYYMMDD'),
      placa: selecChofer.Placa
    };

    console.log("body: ", body);

    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url_dataReport, opPe);
    const data = await response.json();
    console.log("data :", data);
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    setText_fechaD(new Date())
  }
  const onClickCSV = async () => {
  }
  const onClickExcel = async () => {
  }
  const columnsReport = useMemo(() => [
    { header: 'Codigo', accessorKey: 'SlpName' },
    { header: 'DocDate', accessorKey: 'DocDate' },
    { header: 'Documento', accessorKey: 'Documento' },
    { header: 'Nombre', accessorKey: 'CardName' },
    { header: 'Condicion', accessorKey: 'Condicion' },
    { header: 'Importe', accessorKey: 'Importe' },
    { header: ' Cobranza', accessorKey: 'Cobranza' },
    { header: 'Observacion', accessorKey: 'Observacion' },
    { header: 'Ciudad ', accessorKey: 'PrcName' },

  ], []);


  return (
    <>
      <div className="row mt-2">
        <div className="col-lg-12">
          <div className="row mb-1">
            <div className="col-lg-1">
              <label className="form-label mb-0 " >Fecha:</label>
            </div>
            <div className="col-lg-2" style={{ zIndex: '3' }}>
              <DatePicker
                locale="es"
                wrapperClassName='input_date'
                className="form-control form-control-sm text-center"
                selected={text_fechaD}
                onChange={date => setText_fechaD(date)}
                dateFormat="yyyy-MM-dd"
              />
            </div>
            <div className="col-lg-1">
              <label className="form-label mb-0" >Chofer :</label>
            </div>
            <div className="col-lg-5">
              {/* <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} /> */}
              <SelectChofer selectFila={setselectChofer} />
            </div>
          </div>

          <hr />
          <div className="row ">
            <div className="col-lg-12 text-end">

              <Tooltip title="Consultar información" placement="top">

                <button title="Consultar información" className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>

              </Tooltip>

              <Tooltip title="Exportar" placement="top">
                <div className="btn-group" role="group">
                  <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                    <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                    <a className="dropdown-item bx-ms" onClick={onClickCSV} href="">CSV</a>
                    <a className="dropdown-item bx-ms" onClick={onClickExcel} href="">EXCEL</a>
                  </ul>
                </div>
              </Tooltip>
              <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
            </div>
          </div>
        </div>

      </div>

      <div className="card-datatable table-responsive ">
        <MaterialReactTable
          columns={columnsReport}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{
            density: 'compact', showColumnFilters: false,
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 120, //make actions column wider
            },
          }}
          muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}


        />
      </div>
    </>
  )
}

export const Report3aux = (prosp) => { //   
  const { dataTipo } = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');
  // variables 
  const [text_fechaD, setText_fechaD] = useState(new Date());

  const [emp_sl_t_suc, emp_setSl_t_suc] = useState('');
  const emp_changeSl_t_suc = (event) => {
    emp_setSl_t_suc(event.target.value);
  }

  const [dataReport, setdataReport] = useState([]);
  const [pending, setPending] = useState(false);
  const [selecChofer, setselectChofer] = useState('');

  const onClickListar = async () => {

    setdataReport([])
    setPending(true)
    const url_dataReport = `/apiAlmacenReporte?`;
    const body = {
      vTipoReport: dataTipo,
      vcodEmpresa: cook_idEmp,
      fecha: moment(text_fechaD).format('YYYYMMDD'),
      placa: selecChofer.Placa
    };

    console.log("body: ", body);

    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url_dataReport, opPe);
    const data = await response.json();
    console.log("data :", data);
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    setText_fechaD(new Date())
  }
  const onClickCSV = async () => {
  }
  const onClickExcel = async () => {
  }
  const columnsReport = useMemo(() => [
    { header: 'Tipo', accessorKey: 'Tipo' },
    { header: 'Codigo chofer', accessorKey: 'SlpName' },
    { header: 'Documento', accessorKey: 'Documento' },
    { header: 'Nombre', accessorKey: 'CardName' },
    { header: 'DocDate', accessorKey: 'DocDate' },
    { header: 'FechaDoc', accessorKey: 'FechaDoc' },
    { header: ' DocTotal', accessorKey: 'DocTotal' },
    { header: 'Saldo', accessorKey: 'Saldo' },
    { header: 'SumApplied ', accessorKey: 'SumApplied' },
    { header: 'DocNum ', accessorKey: 'DocNum' },


  ], []);


  return (
    <>
      <div className="row mt-2">
        <div className="col-lg-12">
          <div className="row mb-1">
            <div className="col-lg-1">
              <label className="form-label mb-0 " >Fecha:</label>
            </div>
            <div className="col-lg-2" style={{ zIndex: '3' }}>
              <DatePicker
                locale="es"
                wrapperClassName='input_date'
                className="form-control form-control-sm text-center"
                selected={text_fechaD}
                onChange={date => setText_fechaD(date)}
                dateFormat="yyyy-MM-dd"
              />
            </div>
            <div className="col-lg-1">
              <label className="form-label mb-0" >Chofer :</label>
            </div>
            <div className="col-lg-5">
              {/* <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} /> */}
              <SelectChofer selectFila={setselectChofer} />
            </div>
          </div>

          <hr />
          <div className="row ">
            <div className="col-lg-12 text-end">

              <Tooltip title="Consultar información" placement="top">

                <button title="Consultar información" className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>

              </Tooltip>

              <Tooltip title="Exportar" placement="top">
                <div className="btn-group" role="group">
                  <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                    <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                    <a className="dropdown-item bx-ms" onClick={onClickCSV} href="">CSV</a>
                    <a className="dropdown-item bx-ms" onClick={onClickExcel} href="">EXCEL</a>
                  </ul>
                </div>
              </Tooltip>
              <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
            </div>
          </div>
        </div>

      </div>

      <div className="card-datatable table-responsive ">
        <MaterialReactTable
          columns={columnsReport}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{
            density: 'compact', showColumnFilters: false,
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 120, //make actions column wider
            },
          }}
          muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}


        />
      </div>
    </>
  )
}

export const Report4 = (prosp) => {
  const { dataTipo } = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [dataReport, setdataReport] = useState([]);
  const [pending, setPending] = useState(false);
  const [emp_sl_t_suc, emp_setSl_t_suc] = useState('');
  const [slcArticulo, setSlcArticulo] = useState([""]);
  const funDArticulos = useCallback(async (inputValue) => {
    const url = '/apiArticulos?vcodEmpresa=' + cook_idEmp + '&numRepor=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }, [])
  const handleSelectChange = (selectedOption) => { setSlcArticulo(selectedOption); };

  const emp_changeSl_t_suc = (event) => {
    emp_setSl_t_suc(event.target.value);
    $("#emp_slc_tem").focus();
  }
  /** Primer reporte */
  const columns = useMemo(() => [
    { header: 'CodProveedor', accessorKey: 'CodProveedor' },
    { header: 'NomProveedor', accessorKey: 'NomProveedor' },
    { header: 'CodUnNeg', accessorKey: 'CodUnNeg' },
    { header: 'NomUnNeg', accessorKey: 'NomUnNeg' },
    { header: 'Sucursal', accessorKey: 'Sucursal' },
    { header: 'Almacen', accessorKey: 'Almacen' },
    { header: 'Codigo', accessorKey: 'Codigo' },
    { header: 'Descripcion', accessorKey: 'Descripcion' },
    { header: 'Activo', accessorKey: 'Activo' },
    { header: 'GrupoArticulos', accessorKey: 'GrupoArticulos' },
    { header: 'UND_Inventarios', accessorKey: 'UND_Inventarios' },
    { header: 'StockSistema', accessorKey: 'StockSistema' },
    { header: 'Stock_FLEX', accessorKey: 'Stock_FLEX' },
    { header: 'Stock_PENDDS', accessorKey: 'Stock_PENDDS' },
    { header: 'Stock_VENESP', accessorKey: 'Stock_VENESP' },
    { header: 'Stock_PANNET', accessorKey: 'Stock_PANNET' },
    { header: 'StockFisicoTotal', accessorKey: 'StockFisicoTotal' },
    { header: 'StockFisicoCAJA', accessorKey: 'StockFisicoCAJA' },
    { header: 'Presentacion_CAJA', accessorKey: 'Presentacion_CAJA' },
    { header: 'StockFisicoUND', accessorKey: 'StockFisicoUND' },
    { header: 'Presentacion_UND', accessorKey: 'Presentacion_UND' },
    { header: 'Factor_CAJA', accessorKey: 'Factor_CAJA' },
    { header: 'CostoPromedio', accessorKey: 'CostoPromedio' },
    { header: 'ValorizadoPROMEDIO', accessorKey: 'ValorizadoPROMEDIO' },
    { header: 'CostoReal', accessorKey: 'CostoReal' },
    { header: 'ValorizadoREAL', accessorKey: 'ValorizadoREAL' }
  ], []);

  const onClickListar = async () => {
    let tipoReporte= 1;
    try {
      setdataReport([])
      setPending(true)
      const url_reporte = `/apiReportAuditoria?vcodEmpresa=${cook_idEmp}&numRepor=${tipoReporte}`;
      const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
      const response = await fetch(url_reporte, opPe);
      if (response.status != 200) {
        // Si el código de estado es 404 (no se encontraron clientes)
        notify_warning('Ocurrio un error en el proceso')
        return;
      }
      const data = await response.json();
      setdataReport(data)

    } catch (error) {
      console.log("Ocurrio un error:: ", error);


    } finally {
      setPending(false)
    }
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    emp_setSl_t_suc('')
    setSlcArticulo([])
  }
  
  const onClickExcel_de = async () => {
    const fileType = "xlsx"
    const nameFileType = "REPORTE_STOCK_" + moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });//array
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }

  const onClickExcel = async () => {
    let tipoReporte = 1;
    try {
      Swal.fire({
        title: 'Procesando...',
        html: 'Por favor esperar un momento, gracias.',
        didOpen: () => { Swal.showLoading() },
        customClass: { container: 'my-swal' },
        allowOutsideClick: false
      })
      // setdataReport([])
      setPending(true)
      const url_reporte = `/apiReportAuditoriaArchivo?vcodEmpresa=${cook_idEmp}&numRepor=${tipoReporte}`;
      const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
      const response = await fetch(url_reporte, opPe);
      if (response.status != 200) {
        // Si el código de estado es 404 (no se encontraron clientes)
        notify_warning('Ocurrio un error en el proceso')
        return;
      }
      // const data = await response.json();
      // setdataReport(data)
      // const response = await fetch("http://localhost:3000/downloadFile");
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Crear un enlace temporal para descargar
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "REPORTE_GENERAL_STOCK.xlsx"); // Nombre del archivo
      document.body.appendChild(link);
      link.click();
      link.remove();

    } catch (error) {
      console.log("Ocurrio un error:: ", error);
      notify_warning("Ocurrio un erro en el proceso")

    } finally {
      Swal.close()
      setPending(false)
    }
    // Codigo para que el front genere el excel

    // const fileType = "xlsx"
    // const nameFileType = "REPORTE_AUDITORIA_STOCK_" + moment(new Date()).format('YYYYMMDD HH:mm')
    // const showData = async () => {
    //   if (dataReport.length !== 0) {
    //     console.log("dataReport::", dataReport[0]);

    //     const ws = XLSX.utils.json_to_sheet(dataReport);
    //     // Cabecera del archivo 
    //     // const header = []; // Cambia esto según tus necesidades
    //     // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
    //     const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    //     const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });//array
    //     const data = new Blob([excelBuffer], { type: fileType });
    //     FileSaver.saveAs(data, `${nameFileType}.xlsx`);
    //   } else {
    //     notify_warning("No existen datos, verificar campos")
    //   }
    // }
    // showData();
  }
  return (
    <>
      <div className="row">
        <div className="col-lg-10">
          {/* <div className="row ">
              <div className="col-lg-2"><label className="form-label mb-0 " >Sucursal:</label></div>
              <div className="col-lg-2">
                <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
              </div>
              <div className="col-lg-2"><label className="form-label mb-0 " >Articulo:</label></div>
              <div className="col-lg-4 px-1">
                <AsyncSelect 
                  styles={styles}
                  placeholder="Busque por Cod./Desc."
                  name="form-field-name"
                  className="from-control form-control-sm my-swal border-0"  
                  value={slcArticulo}
                  cacheOptions
                  defaultOptions
                  getOptionLabel={e => e.Code +' - '+ e.Name}
                  getOptionValue={e => e.Code }
                  loadOptions={funDArticulos}
                  onChange={handleSelectChange}
                  maxMenuHeight={150}
                />
              </div>
            </div> */}
        </div>
        <div className="col-lg-2 text-end">

          <Tooltip title="Consultar información" placement="top">
            <>
              <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
              </button>
            </>
          </Tooltip>
          <Tooltip title="Exportar" placement="top">
            <div className="btn-group" role="group">
              <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
              </button>
              <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                {/* <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button> */}
                <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
              </ul>
            </div>
          </Tooltip>
          <AButton tp_tilde="Limpiar y consultar de nuevo" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>

      <div className="card-datatable table-responsive ">
        <MaterialReactTable
          columns={columns}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{
            density: 'compact', showColumnFilters: false,
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 120, //make actions column wider
            },
          }}
          muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}

        />
      </div>
    </>
  )
}

export const Report5 = (prosp) => {
  const { dataTipo } = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [dataReport, setdataReport] = useState([]);
  const [pending, setPending] = useState(false);
  const funDArticulos = useCallback(async (inputValue) => {
    const url = '/apiArticulos?vcodEmpresa=' + cook_idEmp + '&numRepor=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }, [])

  /** Primer reporte */
  const columns = useMemo(() => [
    { header: 'TipoConsolidado', accessorKey: 'TipoConsolidado' },
    { header: 'Sucursal', accessorKey: 'Sucursal' },
    { header: 'Almacen', accessorKey: 'Almacen' },
    { header: 'FechaEmision', accessorKey: 'FechaEmision' },
    { header: 'Linea', accessorKey: 'Linea' },
    { header: 'Codigo', accessorKey: 'Codigo' },
    { header: 'Descripcion', accessorKey: 'Descripcion' },
    { header: 'UnidadVenta', accessorKey: 'UnidadVenta' },
    { header: 'FactorUndVenta', accessorKey: 'FactorUndVenta' },
    { header: 'CantidadUndVenta', accessorKey: 'CantidadUndVenta' },
    { header: 'TipoDocumento', accessorKey: 'TipoDocumento' },
    { header: 'SerieCorrelativo', accessorKey: 'SerieCorrelativo' },
    { header: 'CodigoCliente', accessorKey: 'CodigoCliente' },
    { header: 'NombreCliente', accessorKey: 'NombreCliente' },
    { header: 'Vendedor', accessorKey: 'Vendedor' },
    { header: 'Ruta', accessorKey: 'Ruta' },
    { header: 'Comentario', accessorKey: 'Comentario' },
  ], []);

  const onClickListar = async () => {
    // Este neumro de Repor, es de la api De RepoAuditori
    let tipoReporte= 2;
    try {
      setdataReport([])
      setPending(true)
      const url_reporte = `/apiReportAuditoria?vcodEmpresa=${cook_idEmp}&numRepor=${tipoReporte}`;
      console.log(url_reporte);
      
      const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
      const response = await fetch(url_reporte, opPe);
      if (response.status != 200) {
        // Si el código de estado es 404 (no se encontraron clientes)
        notify_warning('Ocurrio un error en el proceso')
        return;
      }
      const data = await response.json();
      setdataReport(data)

    } catch (error) {
      console.log("Ocurrio un error:: ", error);
      notify_error('Ocurrio un error')

    } finally {
      setPending(false)
    }
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
  }
  
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "DOCUMENTOS PENDIENTES POR DESPACHO_" + moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });//array
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-10">
        </div>
        <div className="col-lg-2 text-end">

          <Tooltip title="Consultar información" placement="top">
            <>
              <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
              </button>
            </>
          </Tooltip>
          <Tooltip title="Exportar" placement="top">
            <div className="btn-group" role="group">
              <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
              </button>
              <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                {/* <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button> */}
                <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
              </ul>
            </div>
          </Tooltip>
          <AButton tp_tilde="Limpiar y consultar de nuevo" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>

      <div className="card-datatable table-responsive ">
        <MaterialReactTable
          columns={columns}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{
            density: 'compact', showColumnFilters: false,
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 120, //make actions column wider
            },
          }}
          muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}

        />
      </div>
    </>
  )
}

export const Report6 = (prosp) => { // 
  const { dataTipo } = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');
  // variables 
  const [text_fechaD, setText_fechaD] = useState(new Date());

  const [emp_sl_t_suc, emp_setSl_t_suc] = useState('');
  const emp_changeSl_t_suc = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    setnameSucur(selectedOption.text);

    emp_setSl_t_suc(event.target.value);
  }

  const [dataReport, setdataReport] = useState([]);
  const [pending, setPending] = useState(false);
  const [selecChofer, setselectChofer] = useState([]);

  const [Rdireccion, setdireccion] = useState('');
  const [Ralmacen, setalmacen] = useState('');
  const [Rconsolidado, setconsolidado] = useState('');
  const [nameSucur, setnameSucur] = useState('');
  const [detalleRepotPDF, setdetalleRepotPDF] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState([]);
  //  nuevo  agregado 
  const [pdfSrc, setPdfSrc] = useState('');

  const funVehiculo = useCallback( async (inputValue) => {
    const url ='/apiReporPlacasSucursal?vcodEmpresa='+cook_idEmp+'&cadenaBuscar='+inputValue+'&sucursal='+emp_sl_t_suc;
    const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
    const response = await fetch(url,opPe);
    const data = await response.json();
    return data;
  },[emp_sl_t_suc]) 

  useEffect(() => {
    setdataReport([])
    setselectChofer([])
    const loadDefaultOptions = async () => {
      const defaultData = await funVehiculo('');
      setDefaultOptions(defaultData);
    };
    loadDefaultOptions();
  }, [emp_sl_t_suc ]);

  function validarDatos(data) {

    let consolidado = "Varios";
    let almacen = "";
    let direccion = ""
    if (data.length && data.left != 0) {
      // Si hay datos, validamos los campos que se mostraran en la vista
      //
      if (selecChofer.length === 1) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].Consolidado !== '') {
            consolidado = data[i].U_AB_CODCON;
            break;
          }
        }
      }
      if (selecChofer.length === 0) {
        consolidado = 'Todos';
      }

      for (let i = 0; i < data.length; i++) {
        if (data[i].CodAlmacen !== '') {
          almacen = data[i].CodAlmacen;
          break;
        }
      }
      for (let i = 0; i < data.length; i++) {
        if (data[i].Dire !== '') {
          direccion = data[i].Dire;
          break;
        }
      }
    }
    setdireccion(direccion)
    setalmacen(almacen)
    setconsolidado(consolidado)
    return consolidado;
  }

  const handleDescargarPDF = () => {
    // Crear un enlace temporal
    const link = document.createElement('a');
    link.href = pdfSrc;
    // Nombre del archivo 
    link.download = 'R001_SALIDA_DE_PRODUCTOS_DE_ALMACEND_PRUEBA.pdf'; 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

  const onClickListar = async () => {
    setPending(true)
    setdataReport([])
    console.log("INICON");
    const url_dataReport = `/getSalidaTresUnidades?`;
    // cuando es multiple seleccion 
    let arrvPlacas = selecChofer.map(option => option.Placa)
    // cuando es solo una seleccion
    //let arrvPlacas = selecChofer?.Placa

    const body = {
      vTipoReport: 0,
      vcodEmpresa: cook_idEmp,
      fecha: moment(text_fechaD).format('YYYYMMDD'),
      fechaImpresion: moment().format('YYYY-MM-DD HH:mm:ss'),
      placa: arrvPlacas ? arrvPlacas : '',
      sucursal: emp_sl_t_suc,
      almacen: nameSucur? nameSucur:''
    };
    const opPe = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    };
    
    const response = await fetch(url_dataReport, opPe);
    if (response.status === 204 || response.status === 500) {
      setPending(false)  
      notify_warning("¡No se encontraron registros !")
      return
    } 
    const res = await response.json();
    const { pdf, data } = res;
    
    setPdfSrc(`data:application/pdf;base64,${pdf}`);
    setdataReport(data)
    setPending(false)
    const a = validarDatos(data)
    
    console.log("selecChofer::", selecChofer);
    
    // const detalle = {
    //   "sucursal": nameSucur,
    //   "fecha": text_fechaD,
    //   "consolidado": a
    // };
    // setdetalleRepotPDF({ ...detalle })

  }

  const onClickListarAnterior = async () => {

    console.log("selecChofer:: ", selecChofer);
    console.log("nameSucur:: ", nameSucur);

    // return 
    if (!emp_sl_t_suc || emp_setSl_t_suc === '9999') {
      notify_warning("¡Seleccione una sucursal!");
      return;
    }
    setdataReport([])
    setPending(true)
    const url_dataReport = `/apiAlmacenReporte?`;
    let arrvPlacas = selecChofer.map(option => option.Placa)
    const body = {
      vTipoReport: dataTipo,
      vcodEmpresa: cook_idEmp,
      fecha: moment(text_fechaD).format('YYYYMMDD'),
      placa: arrvPlacas ? arrvPlacas : '',
      sucursal: emp_sl_t_suc
    };

    console.log("body: ", body);

    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url_dataReport, opPe);
    const data = await response.json();
    // console.log("data :", data);
    if (data.length === 0) {
      notify_warning("¡No de encontraron registros !")
    }

    const a = validarDatos(data)
    // var masculinos = data.slice(1, 50);
    setdataReport(data)
    setPending(false)
    const detalle = {
      "sucursal": nameSucur,
      "fecha": text_fechaD,
      "consolidado": a
    };
    setdetalleRepotPDF({ ...detalle })
    console.log("setdetalleRepotPDF::", detalle);

  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    setselectChofer([])
    setText_fechaD(new Date())
    emp_setSl_t_suc('')
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "REPORTE-SALIDA DE PRODUCTOS DE ALMACEN_" + moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "buffer" });//array
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "REPORTE-SALIDA DE PRODUCTOS DE ALMACEN_" + moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });//array
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      } else {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const columnsReport = useMemo(() => [
    // { header: 'Consolidado', accessorKey: 'Consolidado' },
    { header: 'Documento', accessorKey: 'CardName' }, // filtrado
    { header: 'G.U.Negocio', accessorKey: 'ItmsGrpNam' }, // filtrado 
    { header: 'Codigo', accessorKey: 'ItemCode' },
    // { header: 'Alamacen', accessorKey: 'CodAlmacen' },
    // { header: 'Direccion', accessorKey: 'Dire' },

    { header: 'Articulo', accessorKey: 'Articulo' },
    // { header: 'Producto', accessorKey: 'ItemName' },
    { header: 'Lote', accessorKey: 'Lote' },
    { header: 'Unidad V. M.', accessorKey: 'Unid1' },
    { header: 'Cantidad M.', accessorKey: 'Q1' },
    { header: 'Unidad V. m.', accessorKey: 'InvntryUom' },
    { header: 'Cantidad m.', accessorKey: 'Q2' },
    // { header: 'n', accessorKey: 'U_SYP_MDFN' },


  ], []);

  const [openModal, setOpenModal] = useState(false);
  const hanOpenModal = () => { setOpenModal(true); }
  const hanClosModal = () => { setOpenModal(false); }
  return (
    <>
      <div className="row mt-2">
        <div className="col-lg-12">
          <div className="row m-2">
          
            <div className="alert m-3 row alert-primary mb-0 h6" role="alert">
              {/* <span className="col-3 alert-icon me-0 rounded-circle px-0"><i className="icon-base bx bx-info-circle icon-18px" /></span> */}
              <div className="col text-wrap ps-3 pe-0"><i className="icon-base bx bx-info-circle icon-18px" /> Este reporte se encuentra en fase de pruebas (QA). Los datos mostrados no son definitivos y pueden no ser correctos. Estamos trabajando para validar la información antes de su versión final.</div>
      
                {/* <span>
                Este reporte se encuentra en fase de pruebas (QA). Los datos mostrados no son definitivos y
                pueden no ser correctos. Estamos trabajando para validar la información antes de su versión final.
                </span> */}
              
            
            </div>

          </div>

          <div className="row mb-1">
            
            <div className="col-lg-1">
              <label className="form-label mb-0 " >Fecha:</label>
            </div>
            <div className="col-lg-2" style={{ zIndex: '3' }}>
              <DatePicker
                locale="es"
                wrapperClassName='input_date'
                className="form-control form-control-sm text-center"
                selected={text_fechaD}
                onChange={date => setText_fechaD(date)}
                dateFormat="yyyy-MM-dd"
              />
            </div>
            <div className="col-lg-1">
              <label className="form-label mb-0" >Sucursal :</label>
            </div>
            <div className="col-lg-2" >
              <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
            </div>
            <div className="col-lg-1">
              <label className="form-label mb-0" >Chofer :</label>
            </div>
            <div className="col-lg-4" style={{ zIndex: '4' }}>
              {/* <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} /> */}
              {/* <SelectChofer setSelectFila={setselectChofer} fila={selecChofer} />
               */}
              <AsyncSelect
                isMulti
                //styles={styles}
                placeholder="Busque por Cod/Desc"
                className="sele_pru from-control form-control-sm "
                value={selecChofer}
                cacheOptions
                defaultOptions={defaultOptions}
                getOptionLabel={e => e.Placa + '   /   ' + e.Name}
                getOptionValue={e => e.Placa}
                loadOptions={funVehiculo}
                onChange={(e) => {
                  // handleSelectChange(e)
                  setselectChofer(e)
                  setTimeout(() => {
                    $('.css-wsp0cs-MultiValueGeneric').each(function () {
                      var textoCompleto = $(this).text();
                      var textoCorto = textoCompleto.split('/')[0].trim();
                      $(this).text(textoCorto);
                    });
                  }, 1000)
                }}
              />
            </div>
          </div>

          <hr />

          {(dataReport.length != 0) && (
            <>

              <div className="row mb-1">
                <div className="col-1">
                  <label className=''> Consolidado </label>
                </div>
                <div className="col-3">
                  <label className='form-control form-control-sm'>{Rconsolidado}  </label>
                </div>
                <div className="col-1">
                  <label className=''> Almacen  </label>
                </div>
                <div className="col-2">
                  {/* <label className='form-control form-control-sm'> {Ralmacen} </label> */}
                  <label className='form-control form-control-sm'> {nameSucur} </label>
                  
                </div>
              </div>
              <div className="row">
                <div className="col-1">
                  <label className=''> Direccion  </label>
                </div>
                <div className="col-9">
                  <label className='form-control form-control-sm'>{Rdireccion} </label>
                </div>
              </div>
            </>



          )}

          <div className="row ">
            <div className="col-lg-12 text-end">
              <Tooltip title="Consultar información" placement="top">

                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </Tooltip>
              {dataReport && dataReport.length > 0 && (
                <>
                  <Tooltip title="Exportar" placement="top">
                    <div className="btn-group" role="group">
                      <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-label-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                        <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                        <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                        <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                      </ul>
                    </div>
                  </Tooltip>
                  <AButton tp_tilde="Visualizar Rep View" tp_posit="top" bt_class="btn btn-label-info btn-sm" bt_funct={hanOpenModal} ic_class="bx bxs-spreadsheet bx-ms" vl_tpbtn={2} />
                  {/* {dataReport && dataReport.length > 0 && (
                <PDFDownloadLink className="btn btn-sm  btn-label-youtube" document={<PdfAlmacenT001 datallegada={dataReport} detalleRepotPDF={detalleRepotPDF} />} fileName="R001_SALIDA_DE_PRODUCTOS_DE_ALMACEN.pdf">
                  {({ blob, url, loading, error }) =>
                    loading ? 'Cargando PDF...' : <i className="bx bx-xs bxs-file-pdf" ></i>
                  }
                </PDFDownloadLink>
              )}  */}


                  <AButton tp_tilde="Descargar PDF" id="btn3" tp_posit="top" bt_class="btn btn-sm  btn-label-youtube" bt_funct={handleDescargarPDF} ic_class="bx bx-xs bxs-file-pdf" vl_tpbtn={2} />
                </>
              )}
              <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="top" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
            </div>
          </div>
        </div>

      </div>

      <div className="card-datatable table-responsive ">
        <MaterialReactTable
          columns={columnsReport}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{
            density: 'compact',
            showColumnFilters: false,
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
            // grouping: ['Documento']
            grouping: ['CardName', 'ItmsGrpNam']
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 120, //make actions column wider
            },
          }}
          muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}


        />
      </div>


      <Modal
        open={openModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '100%', height: '100%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            className='btn btn-outline-dark btn-sm'
            onClick={hanClosModal}
          >
            <i className='fa fa-x'></i>
          </button>
          <div className="py-3" style={{ userSelect: 'none' }}>
            <RepViewSalidaAlamacen
              Rdireccion={Rdireccion}
              Ralmacen={nameSucur}
              Rconsolidado={Rconsolidado}
              Chofer={selecChofer.map(option => option.Name).join(', ')}
              data={dataReport}

            />
          </div>
        </Box>
      </Modal>
    </>
  )
}


const AlmacenReportes = () => {
  //const cookies = new Cookies();
  //const cook_idEmp = cookies.get('dtEmp_id');
  const [slTipoReport, setSlTipoReport] = useState('');

  const changeSlTipoReport = (event) => {
    setSlTipoReport(event.target.value);
  }

  return (
    <div>
      <h6 className="text-muted"> Almacen</h6>
      <div className="card card-action">
        <div className="card-body ">
          <div className="row mb-2">
            <div className="col-lg-10">
              <div className="row ">
                <div className="col-lg-2"><label className="form-label mb-0 " >Tipo Reporte:</label></div>
                <div className="col-lg-10">
                  <select id="slc_t_con" className="form-select form-select-sm" value={slTipoReport} onChange={changeSlTipoReport} >
                    <option value={"9999"} >--Seleccione--</option>
                    <option value={"1"} > R001 :  SALIDA DE PRODUCTOS DE ALMACEN</option>
                    <option value={"2"} > R002 :  DEVOLUCION DE MERCANCIAS POR DOCUMENTOS</option>
                    <option value={"3"} > R003 :  GUIA DE UNIDADES PROGRAMADAS</option>
                    <option value={"4"} > R004 :  REPORTE GENERAL DE STOCKS</option>
                    <option value={"5"} > R005 :  DOCUMENTOS PENDIENTES POR DESPACHO</option>
                    <option value={"6"} > R001.2 :  SALIDA DE PRODUCTOS DE ALMACEN - TRES UNIDADES </option>
                    {/* <option value={"4"} > R002 :  DEVOLUCION DE MERCANCIAS POR RECHAZOS DE CLIENTE</option> */}
                  </select>
                </div>
              </div>
            </div>
          </div>
          {
            (slTipoReport === '1') && (
              <Report1
                dataTipo={slTipoReport}
              />
            )
          }
          {
            (slTipoReport === '2') && (
              <Report2
                dataTipo={slTipoReport}
              />
            )
          }
          {
            (slTipoReport === '3') && (
              <Report3
                dataTipo={slTipoReport}
              />
            )
          }
           {
            (slTipoReport === '4') && (
              <Report4
                dataTipo={slTipoReport}
              />
            )
          }
          {
            (slTipoReport === '5') && (
              <Report5
                dataTipo={slTipoReport}
              />
            )
          }
           {
            (slTipoReport === '6') && (
              <Report6
                dataTipo={slTipoReport}
              />
            )
          }

        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default AlmacenReportes

